/** Microsoft icon. */
export const MICROSOFT_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
  <title>MS-SymbolLockup</title>
  <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
  <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
  <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
  <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
</svg>`;

/** Google icon. */
export const GOOGLE_ICON = `
<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <g transform="translate(27.009 -39.239)">
  <path d="m-3.264 51.509c0-0.79-0.07-1.54-0.19-2.27h-11.3v4.51h6.47c-0.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82z" fill="#4285F4"/>
  <path d="m-14.754 63.239c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08 0.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09c1.97 3.92 6.02 6.62 10.71 6.62z" fill="#34A853"/>
  <path d="m-21.484 53.529c-0.25-0.72-0.38-1.49-0.38-2.29s0.14-1.57 0.38-2.29v-3.09h-3.98c-0.82 1.62-1.29 3.44-1.29 5.38s0.47 3.76 1.29 5.38l3.98-3.09z" fill="#FBBC05"/>
  <path d="m-14.754 43.989c1.77 0 3.35 0.61 4.6 1.8l3.42-3.42c-2.07-1.94-4.78-3.13-8.02-3.13-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c0.95-2.85 3.6-4.96 6.73-4.96z" fill="#EA4335"/>
  </g>
</svg>
`;
