import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';
import { Observable, from, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AuthenticationProviderConfigurations } from 'models';

import { AppConfigQuery } from '../../app-config';
import { redirectUri } from '../models/redirect-uri';

/** Supported providers. */
export enum OpenIdProviders {
  /** Okta. */
  Okta = 'okta',
  /** OneLogin. */
  OneLogin = 'onelogin',
  /** Google. */
  Google = 'google',
}

/** Provider service. */
@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private authenticationProviders: AuthenticationProviderConfigurations;
  constructor(
    private logger: NGXLogger,
    private appConfigQuery: AppConfigQuery,
    private oauthService: OAuthService
  ) {
    this.appConfigQuery.authenticationProviders$.subscribe(
      (providers) => (this.authenticationProviders = providers)
    );
  }

  /**
   * Handles the provider code in URL and returns a JWT token.
   *
   * @param provider Provider.
   * @returns An observable JWT token.
   */
  handleRedirect(provider: OpenIdProviders): Observable<string> {
    this.configureForProvider(provider);
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      switchMap((isSuccess) => {
        this.logger.debug(
          `Load discovery document for ${provider} provider and try login emitted`,
          isSuccess
        );
        if (!isSuccess) {
          throwError(() => new Error('Token retrieval was not successful.'));
        }

        return this.getJwtToken();
      })
    );
  }

  /**
   * Starts the login flow.
   *
   * Redirects to the provider login if not authenticated and goes directly to GSW login if they are already authenticated.
   *
   * @param provider Provider.
   * @returns An observable of whether the user is logged in already.
   */
  login(provider: OpenIdProviders): Observable<boolean> {
    this.configureForProvider(provider);
    return from(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      tap((alreadyLoggedIn) => {
        if (alreadyLoggedIn) {
          this.oauthService.initCodeFlow();
        }
      })
    );
  }

  private configureForProvider(provider: OpenIdProviders): void {
    const config = this.getAuthConfig(provider);
    this.oauthService.configure(config);
  }

  private getAuthConfig(provider: OpenIdProviders): AuthConfig {
    const authConfig: AuthConfig = {
      issuer: this.authenticationProviders[provider].orgUrl,
      redirectUri,
      clientId: this.authenticationProviders[provider].clientId,
      responseType: 'code',
      scope: 'openid profile email',
      showDebugInformation: true,
    };

    if (provider === 'google') {
      // This is required due to google's implementation, see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/using-an-id-provider-that-fails-discovery-document-validation.html.
      authConfig.strictDiscoveryDocumentValidation = false;
    }

    return authConfig;
  }

  private getJwtToken(): Observable<string> {
    return of(this.oauthService.getIdToken());
  }
}
