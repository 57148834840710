import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { assertExists } from 'common';
import {
  SearchOptions,
  SearchPrompt,
  SearchProvider,
  createApiSearchPromptString,
} from 'models';
import { SEARCH_PROVIDER } from 'src/app/common/tokens';
import { ArchiveGridData } from 'src/app/models';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

import { ArchiveResultCount } from './archive-result-count';

/**  */
@UntilDestroy()
@Component({
  selector: 'app-search-result-archive-selector',
  templateUrl: './search-result-archive-selector.component.html',
  styleUrls: ['./search-result-archive-selector.component.scss'],
})
export class SearchResultArchiveSelectorComponent implements OnInit {
  /** Active archive id. */
  archive = this.archivesQuery.active;
  /** Archive result counts. */
  archiveResultCounts: ArchiveResultCount[] = [];
  /** Tracks if the result set is single, multiple, or no archives. */
  archiveSets: 'single' | 'multi' | 'none';
  /** Active archive result count. */
  currentArchiveCount: number;
  /** Active database ID. */
  databaseId = this.databasesQuery.activeId;
  /** Observable containing if currently viewed on handset. */
  isHandset$ = this.layout.isHandset$;
  /** Whether the archive counts are currently being loaded. */
  isLoading = true;
  /** Active search. */
  search = this.searchesQuery.active;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  private activeGridData: ArchiveGridData;
  private searchPrompts: SearchPrompt[];

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private appQuery: ApplicationQuery,
    private archivesQuery: ArchivesQuery,
    private databasesQuery: DatabasesQuery,
    private searchesQuery: SearchesQuery,
    private layout: LayoutService,
    private gridStatesService: GridSettingsService,
    @Inject(SEARCH_PROVIDER) private searchProvider: SearchProvider
  ) {}

  ngOnInit(): void {
    this.listenForSearchParamChanges();
  }

  /**
   * Event handler for selecting a new archive.
   *
   * @param archiveId Archive ID.
   */
  onClickSelectArchive(archiveId: number): void {
    this.activeGridData.currentPage = 1;
    this.router.navigate(
      ['db', this.databaseId, 'archive', archiveId, 'search', this.search.id],
      {
        queryParamsHandling: 'preserve',
      }
    );
  }

  /**
   * Reloads the search counts.
   */
  reloadSearchCounts(): void {
    this.logger.debug('Reloading search counts.');
    this.loadSearchCounts();
  }

  private getArchiveResultCount$(
    targetArchiveId: number,
    searchOptions: SearchOptions
  ): Observable<ArchiveResultCount> {
    searchOptions.targetArchiveId = targetArchiveId;
    return this.searchProvider
      .runForCount(this.databaseId, this.search, searchOptions)
      .pipe(
        map((count) => ({
          archiveId: targetArchiveId,
          archiveName: this.archivesQuery.getArchive(targetArchiveId).name,
          count,
        }))
      );
  }

  private getFilterArchiveResultCounts(
    archiveResultCounts: ArchiveResultCount[]
  ): ArchiveResultCount[] {
    this.logger.debug(
      'Multi-Archive search ran with hide archives with no results setting enabled.'
    );
    // Get a list of archives with results.
    const filteredArchiveResultCounts = archiveResultCounts.filter(
      (x) => x.count > 0
    );
    // If no archives had results return just the archive that owned the search.
    if (filteredArchiveResultCounts.length === 0) {
      const activeArchiveResultCount = archiveResultCounts.find(
        (c) => c.archiveId === this.archive.id
      );
      assertExists(activeArchiveResultCount);
      return [activeArchiveResultCount];
    }

    this.logger.debug(
      'Archives with results are: ',
      filteredArchiveResultCounts
    );
    this.logger.debug('Checking if the current archive has results...');
    // Navigate to the first archive with results if the current archive does not have results.
    if (
      !filteredArchiveResultCounts.some((x) => x.archiveId === this.archive.id)
    ) {
      this.logger.debug(
        'Current archive has no results. Finding the first archive in the search with results...'
      );
      // Get the first archive in the search list with results.
      for (const archiveId of this.search.archives) {
        if (
          filteredArchiveResultCounts.some((x) => x.archiveId === archiveId)
        ) {
          this.logger.debug(
            'Archive with results found. Navigating...',
            archiveId
          );
          //Navigate to that archive and stop current execution.
          this.onClickSelectArchive(archiveId);
          return [];
        }
      }
    }

    return filteredArchiveResultCounts;
  }

  private listenForSearchParamChanges(): void {
    this.searchesQuery.searchRouteParams$
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId, searchId, searchPrompts]) =>
        this.onSearchParamsChange(
          databaseId ?? 0,
          archiveId ?? 0,
          searchId ?? 0,
          searchPrompts
        )
      );
  }

  private loadSearchCounts(): void {
    this.isLoading = true;
    const searchOptions = {
      page: 1,
      countOnly: true,
      recordsPerPage: 0,
      searchCriteria: this.searchPrompts
        ? createApiSearchPromptString(this.searchPrompts)
        : '',
      sort: '',
      tabId: 0,
      targetArchiveId: 0,
    };
    const countsArray = this.search.archives.map((searchArchiveId) =>
      this.getArchiveResultCount$(searchArchiveId, searchOptions)
    );

    const counts$ = combineLatest(countsArray);
    combineLatest([
      counts$,
      this.appQuery.hideArchivesWithNoResults$.pipe(untilDestroyed(this)),
      this.appQuery.sortResultsArchivesByResultsCount$.pipe(
        untilDestroyed(this)
      ),
    ]).subscribe(
      ([
        archiveResultCounts,
        hideArchivesWithNoResults,
        sortResultsArchivesByResultsCount,
      ]) => {
        if (this.search.archives.length > 1 && hideArchivesWithNoResults) {
          const filteredArchiveResultCounts =
            this.getFilterArchiveResultCounts(archiveResultCounts);
          this.logger.debug(
            'Setting archive result counts.',
            filteredArchiveResultCounts
          );
          archiveResultCounts = filteredArchiveResultCounts;
        }

        if (sortResultsArchivesByResultsCount) {
          this.logger.debug(
            'Sort results archives by result is enabled. Sorting the archive result counts.'
          );

          archiveResultCounts = archiveResultCounts.sort(
            (a, b) => b.count - a.count
          );
        }
        // Set member to filtered and sorted result counts.
        this.archiveResultCounts = archiveResultCounts;
        // Set member state for multiple archives before we remove "current archive".
        switch (archiveResultCounts.length) {
          case 0:
            this.archiveSets = 'none';
            break;
          case 1:
            this.archiveSets = 'single';
            break;
          default:
            this.archiveSets = 'multi';
            break;
        }
        // Get the active archive index.
        const currentArchiveIndex = archiveResultCounts.findIndex(
          (rc) => rc.archiveId === this.archive.id
        );

        // If the archive isn't found then go to the first one in the list.
        if (currentArchiveIndex === -1) {
          if (this.archiveResultCounts.length === 0) {
            // I don't think this can happen but just log it in case.
            this.logger.error(
              'Unable to load archive result counts because no archives are accessible.'
            );
            return;
          }
          const firstArchiveCount = this.archiveResultCounts[0];
          this.onClickSelectArchive(firstArchiveCount.archiveId);
          return;
        }

        // Get the current archive count.
        this.currentArchiveCount =
          currentArchiveIndex > -1
            ? archiveResultCounts[currentArchiveIndex].count
            : 0;
        // Remove the active archive from the available selections if there are multiple archives.
        if (archiveResultCounts.length > 1) {
          archiveResultCounts.splice(currentArchiveIndex, 1);
        }
        this.logger.debug('Current archive count.', this.currentArchiveCount);
        this.isLoading = false;
      }
    );
  }

  private onSearchParamsChange(
    databaseId: number,
    archiveId: number,
    searchId: number,
    searchPrompts: SearchPrompt[]
  ): void {
    this.databaseId = databaseId;
    this.archive = this.archivesQuery.getArchive(archiveId);
    this.search = this.searchesQuery.getSearch(searchId);
    this.searchPrompts = searchPrompts;
    this.archiveResultCounts = [];
    this.activeGridData = this.gridStatesService.getOrCreateArchiveGridData(
      this.databasesQuery.activeId,
      this.archive.id
    );
    if (databaseId && archiveId && searchId) {
      this.loadSearchCounts();
    }
  }
}
