import { Claims } from './claims';
import { Database } from './database';

/**
 * An authenticated user.
 */
export class User implements Claims {
  /**
   * Base64 authentication string.
   *
   * @todo This should probably be replaced with a getter if this remains a class.
   */
  authData: string;
  /** List of database ID to which the user is an administrator. */
  dbAdminOf: number[] = [];
  /**
   * Domain/authorization server.
   *
   * @todo The need for this should be evaluated.
   */
  domain: string;
  /** Is the user an admin of all databases. */
  isAdminAll: boolean;
  /** Is the user an admin of any database. */
  isAdminAny: boolean;
  /** Is the user an externally provisioned user. */
  isExternalProviderUser: boolean;
  /** Is the user an internal user. */
  isInternalUser: boolean;
  /** Is the user read only. */
  isReadOnly: boolean;
  /** Is the user an administrator of the tenant. */
  isTenantAdmin: boolean;
  /** JWT Token for provider user. */
  jwtToken = '';
  /** Username. */
  name: string;
  /** Hashed password. */
  password: string;
  /** Name of the authentication provider. */
  provider = '';
  /**
   * API authentication token.
   */
  token: string;

  constructor(params = {} as Partial<User>) {
    Object.assign(this, params);
  }

  /**
   * Check if the user admin rights on a database.
   *
   * @param database Database to check for.
   * @returns `true` if the user has admin rights.
   */
  isAdminOf(database: Database) {
    return this.isAdminAll || this.dbAdminOf.includes(database.id);
  }
}

/**
 * A list of `User` entities.
 */
export declare type Users = User[];
