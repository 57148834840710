<!-- A hidden div is created to set the position of appearance of the menu-->
<div
  class="context-menu-trigger"
  [ngStyle]="menuRootStyle"
  [matMenuTriggerFor]="actionMenu"
></div>
<mat-menu #actionMenu="matMenu" (closed)="onMenuClosed()">
  <button mat-menu-item (click)="onClickOpenDocuments()" *ngIf="!hideOpen">
    <span transloco="OPEN_DOCUMENTS"></span>
  </button>
  <button
    *ngIf="!hideOpenFullViewer"
    mat-menu-item
    (click)="onClickOpenDocuments(true)"
  >
    <span transloco="OPEN_IN_FULL_VIEWER"></span>
  </button>
  <mat-divider *ngIf="!hideOpen || !hideOpenFullViewer"></mat-divider>
  <button mat-menu-item [disabled]="exportDisabled" (click)="onClickExport()">
    <span transloco="EXPORT"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="exportDisabled"
    (click)="onClickEnhancedExport()"
  >
    <span transloco="ENHANCED_EXPORT"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="showDownloadAsCsv"
    [disabled]="downloadAsCsvDisabled"
    [matMenuTriggerFor]="downloadCsvMenu"
  >
    <span transloco="DOWNLOAD_AS_CSV"></span>
  </button>
  <button
    mat-menu-item="actionMenu"
    [mat-menu-trigger-for]="emailAnnotationsMenu"
    *ngIf="gseIsLaunchAvailable$ | async"
    [disabled]="emailDisabled"
  >
    <span transloco="EMAIL"></span>
  </button>
  <button
    mat-menu-item
    [disabled]="printDisabled"
    [matMenuTriggerFor]="printMenu"
  >
    <span transloco="PRINT"></span>
  </button>
  <button
    mat-menu-item
    (click)="onClickLaunchDocument()"
    *ngIf="gseIsLaunchAvailable$ | async"
    [disabled]="launchDisabled"
  >
    <span transloco="LAUNCH"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="gseIsQuickbooksAvailable$ | async"
    (click)="onClickSendToQuickbooks()"
  >
    <span transloco="SEND_TO_QUICKBOOKS"></span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item [disabled]="copyDisabled" (click)="onClickCopy()">
    <span transloco="COPY"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="!hideMultiple"
    [disabled]="mergeDisabled"
    (click)="onClickMerge()"
  >
    <span transloco="MERGE"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasAdvancedLinks$ | async"
    [matMenuTriggerFor]="advancedLinksMenu"
  >
    <span transloco="ADVANCED_LINKS"></span>
  </button>
  <button mat-menu-item [disabled]="appendDisabled" (click)="onClickAppend()">
    <span transloco="INSERT_FROM_INBOX"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="hasFavoriteSearches$ | async"
    [disabled]="appendDisabled"
    (click)="onClickInsertFromSearch()"
  >
    <span transloco="INSERT_FROM_SEARCH"></span>
  </button>
  <button mat-menu-item [disabled]="moveDisabled" (click)="onClickMove()">
    <span transloco="MOVE"></span>
  </button>
  <button mat-menu-item [disabled]="deleteDisabled" (click)="onClickDelete()">
    <span transloco="DELETE"></span>
  </button>
</mat-menu>

<mat-menu #downloadCsvMenu="matMenu">
  <button mat-menu-item (click)="onClickDownloadCsv(false)">
    <span transloco="DEFAULT_CONFIGURATION"></span>
  </button>
  <button mat-menu-item (click)="onClickDownloadCsv(true)">
    <span transloco="ADVANCED_CONFIGURATION"></span>
  </button>
</mat-menu>

<mat-menu #emailAnnotationsMenu="matMenu">
  <button
    mat-menu-item
    [mat-menu-trigger-for]="emailFieldsMenu"
    [matMenuTriggerData]="{ includeAnnotations: 'true' }"
  >
    <span transloco="ANNOTATIONS"></span>
  </button>
  <button
    mat-menu-item
    *ngIf="showLaunchEmailNoAnnotations"
    [mat-menu-trigger-for]="emailFieldsMenu"
    [matMenuTriggerData]="{ includeAnnotations: 'false' }"
  >
    <span transloco="NO_ANNOTATIONS"></span>
  </button>
</mat-menu>

<mat-menu #emailFieldsMenu="matMenu">
  <ng-template matMenuContent let-includeAnnotations="includeAnnotations">
    <button
      *ngFor="let field of archiveFields"
      mat-menu-item
      (click)="onClickEmail(includeAnnotations, field.id)"
    >
      {{ field.name }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #printMenu="matMenu">
  <button mat-menu-item (click)="onClickPrintDocuments(true)">
    <span transloco="PRINT_WITH_ANNOTATIONS"></span>
  </button>
  <button mat-menu-item (click)="onClickPrintDocuments(false)">
    <span transloco="PRINT_WITHOUT_ANNOTATIONS"></span>
  </button>
</mat-menu>

<mat-menu #advancedLinksMenu="matMenu">
  <ng-template matMenuContent>
    <div *ngFor="let advancedLink of advancedLinks$ | async">
      <button mat-menu-item (click)="onClickAdvancedLink(advancedLink)">
        {{ advancedLink.name }}
      </button>
    </div>
  </ng-template>
</mat-menu>
