import { NgxUploaderModule } from '@angular-ex/uploader';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkMenuModule } from '@angular/cdk/menu';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MAT_CARD_CONFIG, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule,
} from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { persistState } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { TranslocoModule } from '@jsverse/transloco';
import { HotkeysModule } from '@ngneat/hotkeys';
import { AgGridModule } from 'ag-grid-angular';
import { ResizableModule } from 'angular-resizable-element';
import { MatIconButtonSizesModule } from 'mat-icon-button-sizes';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';

import {
  GSE_API_CONFIG,
  GseApiModule,
  GseApiService,
  GseLaunchService,
  GseQuickbooksService,
  GseScanService,
} from 'gse-api';
import { ModelsModule } from 'models';
import {
  SQUARE9_API_CONFIG,
  Square9ApiAdvancedLinkService,
  Square9ApiArchiveService,
  Square9ApiAuthenticationService,
  Square9ApiCSVImportService,
  Square9ApiConfig,
  Square9ApiDatabaseService,
  Square9ApiDocumentService,
  Square9ApiDocumentUpdateService,
  Square9ApiExportService,
  Square9ApiFieldService,
  Square9ApiHistoryService,
  Square9ApiInboxService,
  Square9ApiKeyfreeService,
  Square9ApiListService,
  Square9ApiMetadataService,
  Square9ApiModule,
  Square9ApiPrintService,
  Square9ApiSearchService,
  Square9ApiSessionService,
  Square9ApiShareService,
  Square9ApiTableFieldService,
  Square9ApiTaskSearchService,
  Square9ApiThumbnailService,
  Square9ApiUserActionsService,
  Square9ApiUserProfileService,
  Square9apiAuthorizationService,
} from 'square9api';

import { environment } from '../environments/environment';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { AppRoutingModule } from './app-routing.module';
import { DateAgoPipe } from './common/date-ago.pipe';
import { FilterNamePipe } from './common/filter-name.pipe';
import { MarkdownPipe } from './common/markdown.pipe';
import { OrderByPipe } from './common/order-by.pipe';
import { ReplacePipe } from './common/replace-pipe';
import {
  ADVANCED_LINK_PROVIDER,
  API_METADATA_PROVIDER,
  ARCHIVE_PROVIDER,
  AUTHENTICATION_PROVIDER,
  AUTHORIZATION_PROVIDER,
  CSV_IMPORT_PROVIDER,
  DATABASE_PROVIDER,
  DOCUMENT_HISTORY_PROVIDER,
  DOCUMENT_PROVIDER,
  DOCUMENT_UPDATE_PROVIDER,
  EXPORT_PROVIDER,
  FIELD_PROVIDER,
  GSE_PROVIDER,
  INBOX_PROVIDER,
  KEYFREE_PROVIDER,
  LAUNCH_DOCUMENT_PROVIDER,
  LIST_PROVIDER,
  PRINT_PROVIDER,
  QUICKBOOKS_PROVIDER,
  SCAN_PROVIDER,
  SEARCH_PROVIDER,
  SHARE_DOCUMENT_PROVIDER,
  TABLE_FIELD_PROVIDER,
  TASK_SEARCH_PROVIDER,
  THUMBNAIL_PROVIDER,
  USER_ACTIONS_PROVIDER,
  USER_PROFILE_PROVIDER,
  VIEWER_SESSION_PROVIDER,
} from './common/tokens';
import { UserWithoutDomainPipe } from './common/user-without-domain.pipe';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { AddFavoriteSearchDialogComponent } from './components/add-favorite-search-dialog/add-favorite-search-dialog.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { AppComponent } from './components/app.component';
import { ArchiveActionsMenuComponent } from './components/archive-actions-menu/archive-actions-menu.component';
import { ArchiveActionsToolbarComponent } from './components/archive-actions-toolbar/archive-actions-toolbar.component';
import { ArchivePanelComponent } from './components/archive-panel/archive-panel.component';
import { ArchiveViewComponent } from './components/archive-view/archive-view.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CopyLinkButtonComponent } from './components/copy-link-button/copy-link-button.component';
import { CSVImportDialogComponent } from './components/csvimport-dialog/csvimport-dialog.component';
import { CSVImportJobManagerButtonComponent } from './components/csvimport-job-manager-button/csvimport-job-manager-button.component';
import { CSVImportJobManagerDialogComponent } from './components/csvimport-job-manager-dialog/csvimport-job-manager-dialog.component';
import { CustomLinksMenuComponent } from './components/custom-links-menu/custom-links-menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DatabaseMenuComponent } from './components/database-menu/database-menu.component';
import { DatabaseSelectComponent } from './components/database-select/database-select.component';
import { DestinationSelectDialogComponent } from './components/destination-select-dialog/destination-select-dialog.component';
import { DirectAccessComponent } from './components/direct-access/direct-access.component';
import { DocumentHistoryComponent } from './components/document-history/document-history.component';
import { HistoryFilterPipe } from './components/document-history/history-filter.pipe';
import { DocumentViewSidebarComponent } from './components/document-view-sidebar/document-view-sidebar.component';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { DragAndDropListComponent } from './components/drag-and-drop-list/drag-and-drop-list.component';
import { DxcMenuComponent } from './components/dxc-menu/dxc-menu.component';
import { DXCMultipleMatchSelectionDialogComponent } from './components/dxc-multiple-match-selection-dialog/dxc-multiple-match-selection-dialog.component';
import { EnhancedExportDialogComponent } from './components/enhanced-export-dialog/enhanced-export-dialog.component';
import { ExportConfigDialogComponent } from './components/export-config-dialog/export-config-dialog.component';
import { FavoriteSearchesPanelComponent } from './components/favorite-searches-panel/favorite-searches-panel.component';
import { CharacterFieldComponent } from './components/field-components/character-field/character-field.component';
import { DateFieldComponent } from './components/field-components/date-field/date-field.component';
import { DecimalFieldComponent } from './components/field-components/decimal-field/decimal-field.component';
import { DropdownFieldComponent } from './components/field-components/dropdown-field/dropdown-field.component';
import { MultiValueFieldMenuComponent } from './components/field-components/multi-value-field-menu/multi-value-field-menu.component';
import { MultiValueFieldComponent } from './components/field-components/multi-value-field/multi-value-field.component';
import { MultiValueHotkeysDirective } from './components/field-components/multi-value-hotkeys.directive';
import { NumberFieldComponent } from './components/field-components/number-field/number-field.component';
import { TableFieldButtonComponent } from './components/field-components/table-field-button/table-field-button.component';
import { TypeaheadFieldComponent } from './components/field-components/typeahead-field/typeahead-field.component';
import { FieldComponent } from './components/field/field.component';
import { BadgeCellRendererComponent } from './components/grid-cell-components/badge-cell-renderer/badge-cell-renderer.component';
import { CharacterCellEditorComponent } from './components/grid-cell-components/character-cell-editor/character-cell-editor.component';
import { CheckboxCellRendererComponent } from './components/grid-cell-components/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DateTimeCellEditorComponent } from './components/grid-cell-components/date-time-cell-editor/date-time-cell-editor.component';
import { DateTimeCellRendererComponent } from './components/grid-cell-components/date-time-cell-renderer/date-time-cell-renderer.component';
import { DecimalCellEditorComponent } from './components/grid-cell-components/decimal-cell-editor/decimal-cell-editor.component';
import { DecimalCellRendererComponent } from './components/grid-cell-components/decimal-cell-renderer/decimal-cell-renderer.component';
import { DeleteRowCellRendererComponent } from './components/grid-cell-components/delete-row-cell-renderer/delete-row-cell-renderer.component';
import { DropdownCellEditorComponent } from './components/grid-cell-components/dropdown-cell-editor/dropdown-cell-editor.component';
import { DropdownTypeaheadComponent } from './components/grid-cell-components/dropdown-typeahead/dropdown-typeahead.component';
import { DropdownComponent } from './components/grid-cell-components/dropdown/dropdown.component';
import { MultiValueCellRendererComponent } from './components/grid-cell-components/multi-value-cell-renderer/multi-value-cell-renderer.component';
import { MultiValueEditorDialogComponent } from './components/grid-cell-components/multi-value-editor-dialog/multi-value-editor-dialog.component';
import { NumericCellEditorComponent } from './components/grid-cell-components/numeric-cell-editor/numeric-cell-editor.component';
import { TableFieldCellRendererComponent } from './components/grid-cell-components/table-field-cell-renderer/table-field-cell-renderer.component';
import { ThumbnailPreviewRendererComponent } from './components/grid-cell-components/thumbnail-preview-renderer/thumbnail-preview-renderer.component';
import { ThumbnailPreviewTooltipComponent } from './components/grid-cell-components/thumbnail-preview-tooltip/thumbnail-preview-tooltip.component';
import { TypeaheadCellEditorComponent } from './components/grid-cell-components/typeahead-cell-editor/typeahead-cell-editor.component';
import { GridSettingsComponent } from './components/grid-settings/grid-settings.component';
import { GSEConfigurationDialogComponent } from './components/gseconfiguration-dialog/gseconfiguration-dialog.component';
import { GSEInstallDialogComponent } from './components/gseinstall-dialog/gseinstall-dialog.component';
import { ImportViewerUnavailableComponent } from './components/import-viewer-unavailable/import-viewer-unavailable.component';
import { InboxActionsMenuComponent } from './components/inbox-actions-menu/inbox-actions-menu.component';
import { InboxActionsToolbarComponent } from './components/inbox-actions-toolbar/inbox-actions-toolbar.component';
import { InboxResultPaginatorComponent } from './components/inbox-result-paginator/inbox-result-paginator.component';
import { InboxViewComponent } from './components/inbox-view/inbox-view.component';
import { InboxesPanelComponent } from './components/inboxes-panel/inboxes-panel.component';
import { IndexerComponent } from './components/indexer/indexer.component';
import { InsertFromInboxDialogComponent } from './components/insert-from-inbox-dialog/insert-from-inbox-dialog.component';
import { InsertFromSearchDialogComponent } from './components/insert-from-search-dialog/insert-from-search-dialog.component';
import { KfiEditComponent } from './components/kfi-edit/kfi-edit.component';
import { LeftSidebarToggleButtonComponent } from './components/left-sidebar-toggle-button/left-sidebar-toggle-button.component';
import { LockedDocumentMenuComponent } from './components/locked-document-menu/locked-document-menu.component';
import { LoginViewComponent } from './components/login-view/login-view.component';
import { LogoSvgComponent } from './components/logo-svg/logo-svg.component';
import { MentionFormFieldComponent } from './components/mention-form-field/mention-form-field.component';
import { NameSvgComponent } from './components/name-svg/name-svg.component';
import { NavigationArchiveMenuComponent } from './components/navigation-archive-menu/navigation-archive-menu.component';
import { NavigationArchiveComponent } from './components/navigation-archive/navigation-archive.component';
import { NavigationExpansionButtonComponent } from './components/navigation-expansion-button/navigation-expansion-button.component';
import { NavigationFavoriteSearchComponent } from './components/navigation-favorite-search/navigation-favorite-search.component';
import { NavigationInboxMenuComponent } from './components/navigation-inbox-menu/navigation-inbox-menu.component';
import { NavigationInboxComponent } from './components/navigation-inbox/navigation-inbox.component';
import { NavigationTaskSearchComponent } from './components/navigation-task-search/navigation-task-search.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProgressDialogComponent } from './components/progress-dialog/progress-dialog.component';
import { RelatedSearchesMenuComponent } from './components/related-searches-menu/related-searches-menu.component';
import { RevisionsMenuComponent } from './components/revisions-menu/revisions-menu.component';
import { RightSidebarToggleButtonComponent } from './components/right-sidebar-toggle-button/right-sidebar-toggle-button.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { ScanDialogComponent } from './components/scan-dialog/scan-dialog.component';
import { SearchDashboardComponent } from './components/search-dashboard/search-dashboard.component';
import { SearchPromptComponent } from './components/search-prompt/search-prompt.component';
import { SearchResultArchiveSelectorComponent } from './components/search-result-archive-selector/search-result-archive-selector.component';
import { SearchResultPaginatorComponent } from './components/search-result-paginator/search-result-paginator.component';
import { SelectionCellRendererComponent } from './components/selection-cell-renderer/selection-cell-renderer.component';
import { SharePromptComponent } from './components/share-prompt/share-prompt.component';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { StateToolbarComponent } from './components/state-toolbar/state-toolbar.component';
import { TabViewComponent } from './components/tab-view/tab-view.component';
import { TableFieldGridComponent } from './components/table-field-grid/table-field-grid.component';
import { TaskActionMenuComponent } from './components/task-action-menu/task-action-menu.component';
import { TaskActionsToolbarComponent } from './components/task-actions-toolbar/task-actions-toolbar.component';
import { TaskSearchArchiveSelectorComponent } from './components/task-search-archive-selector/task-search-archive-selector.component';
import { TaskSearchPanelComponent } from './components/task-search-panel/task-search-panel.component';
import { TaskViewComponent } from './components/task-view/task-view.component';
import { UserActionsPanelComponent } from './components/user-actions-panel/user-actions-panel.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserSettingsDialogComponent } from './components/user-settings-dialog/user-settings-dialog.component';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { gseApiConfigFactory } from './helpers/gse-api-config.factory';
import { instanceProviderFactory } from './helpers/instance-provider-config.factory';
import { AppConfigQuery } from './modules/app-config';
import { AppConfigModule } from './modules/app-config/app-config.module';
import { CommandPaletteModule } from './modules/command-palette/command-palette.module';
import { INSTANCE_PROVIDER, LOGIN_PROVIDER } from './modules/login';
import { LoginModule } from './modules/login/login.module';
import { PdfViewerModule } from './modules/pdf-viewer/pdf-viewer.module';
import { AuthenticationService } from './services/authentication.service';
import { ApplicationQuery } from './state/application/application.query';
import { ApplicationService } from './state/application/application.service';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LogoSvgComponent,
    NameSvgComponent,
    AdminMenuComponent,
    DatabaseMenuComponent,
    ArchiveViewComponent,
    InboxViewComponent,
    TaskViewComponent,
    UserSettingsDialogComponent,
    DatabaseSelectComponent,
    NavigationArchiveComponent,
    ArchivePanelComponent,
    LoginViewComponent,
    DashboardComponent,
    InboxesPanelComponent,
    NavigationInboxComponent,
    ScanDialogComponent,
    UserMenuComponent,
    ShortcutsComponent,
    TaskSearchPanelComponent,
    NavigationTaskSearchComponent,
    DocumentHistoryComponent,
    RightSidebarComponent,
    HistoryFilterPipe,
    MarkdownPipe,
    UserWithoutDomainPipe,
    ArchiveActionsToolbarComponent,
    DestinationSelectDialogComponent,
    ExportConfigDialogComponent,
    DateAgoPipe,
    ReplacePipe,
    GridSettingsComponent,
    SearchPromptComponent,
    InsertFromInboxDialogComponent,
    BadgeCellRendererComponent,
    ConfirmationDialogComponent,
    AddFavoriteSearchDialogComponent,
    SharePromptComponent,
    FavoriteSearchesPanelComponent,
    NavigationFavoriteSearchComponent,
    NumericCellEditorComponent,
    DecimalCellEditorComponent,
    DateTimeCellEditorComponent,
    CharacterCellEditorComponent,
    DateTimeCellRendererComponent,
    DecimalCellRendererComponent,
    DropdownCellEditorComponent,
    DropdownComponent,
    DropdownTypeaheadComponent,
    TypeaheadCellEditorComponent,
    TableFieldCellRendererComponent,
    TableFieldGridComponent,
    DirectAccessComponent,
    KfiEditComponent,
    ProgressDialogComponent,
    CSVImportDialogComponent,
    SelectionCellRendererComponent,
    CheckboxCellRendererComponent,
    CSVImportJobManagerButtonComponent,
    CSVImportJobManagerDialogComponent,
    NavigationExpansionButtonComponent,
    SearchResultArchiveSelectorComponent,
    ThumbnailPreviewRendererComponent,
    ThumbnailPreviewTooltipComponent,
    EnhancedExportDialogComponent,
    MentionFormFieldComponent,
    DragAndDropListComponent,
    InboxActionsToolbarComponent,
    TabViewComponent,
    SearchDashboardComponent,
    FilterNamePipe,
    TaskActionsToolbarComponent,
    LeftSidebarToggleButtonComponent,
    TaskSearchArchiveSelectorComponent,
    RightSidebarToggleButtonComponent,
    LeftSidebarToggleButtonComponent,
    MultiValueCellRendererComponent,
    MultiValueEditorDialogComponent,
    DeleteRowCellRendererComponent,
    CustomLinksMenuComponent,
    OrderByPipe,
    ArchiveActionsMenuComponent,
    InboxActionsMenuComponent,
    TaskActionMenuComponent,
    NavigationArchiveMenuComponent,
    NavigationInboxMenuComponent,
    DocumentViewComponent,
    CopyLinkButtonComponent,
    GSEInstallDialogComponent,
    GSEConfigurationDialogComponent,
    IndexerComponent,
    FieldComponent,
    CharacterFieldComponent,
    DateFieldComponent,
    DropdownFieldComponent,
    NumberFieldComponent,
    DecimalFieldComponent,
    TypeaheadFieldComponent,
    MultiValueFieldComponent,
    DocumentViewSidebarComponent,
    TableFieldButtonComponent,
    MultiValueFieldMenuComponent,
    MultiValueHotkeysDirective,
    InsertFromSearchDialogComponent,
    SearchResultPaginatorComponent,
    InboxResultPaginatorComponent,
    StateToolbarComponent,
    AboutDialogComponent,
    LockedDocumentMenuComponent,
    DxcMenuComponent,
    DXCMultipleMatchSelectionDialogComponent,
    UserActionsPanelComponent,
    RelatedSearchesMenuComponent,
    ImportViewerUnavailableComponent,
    RevisionsMenuComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatRippleModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatGridListModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatBadgeModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTableModule,
    MatSelectModule,
    MatSelectModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatIconButtonSizesModule,
    MatChipsModule,
    MatTreeModule,
    ResizableModule,
    ScrollingModule,
    HotkeysModule,
    NgxUploaderModule,
    NgxMatFileInputModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    MatPasswordStrengthModule.forRoot(),
    DragDropModule,
    TranslocoModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      includeTitleDuplicates: true,
      countDuplicates: true,
      newestOnTop: false,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      // level: environment.production
      //   ? NgxLoggerLevel.INFO
      //   : NgxLoggerLevel.DEBUG,
      enableSourceMaps: !environment.production,
      timestampFormat: '[mm.SSS]',
    }),
    AgGridModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ModelsModule,
    Square9ApiModule,
    GseApiModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    CommandPaletteModule,
    LoginModule.forRoot(),
    AppConfigModule,
    PdfViewerModule,
    CdkMenuModule,
    TranslocoRootModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: SQUARE9_API_CONFIG,
      useFactory: (appConfigQuery: AppConfigQuery): Square9ApiConfig => ({
        apiUrl$: appConfigQuery.apiUrl$,
      }),
      deps: [AppConfigQuery],
    },
    { provide: DATABASE_PROVIDER, useClass: Square9ApiDatabaseService },
    { provide: ARCHIVE_PROVIDER, useClass: Square9ApiArchiveService },
    { provide: SEARCH_PROVIDER, useClass: Square9ApiSearchService },
    { provide: FIELD_PROVIDER, useClass: Square9ApiFieldService },
    { provide: TABLE_FIELD_PROVIDER, useClass: Square9ApiTableFieldService },
    { provide: INBOX_PROVIDER, useClass: Square9ApiInboxService },
    { provide: VIEWER_SESSION_PROVIDER, useClass: Square9ApiSessionService },
    { provide: TASK_SEARCH_PROVIDER, useClass: Square9ApiTaskSearchService },
    { provide: DOCUMENT_HISTORY_PROVIDER, useClass: Square9ApiHistoryService },
    { provide: GSE_PROVIDER, useClass: GseApiService },
    { provide: LAUNCH_DOCUMENT_PROVIDER, useClass: GseLaunchService },
    { provide: SCAN_PROVIDER, useClass: GseScanService },
    { provide: QUICKBOOKS_PROVIDER, useClass: GseQuickbooksService },
    {
      provide: ADVANCED_LINK_PROVIDER,
      useClass: Square9ApiAdvancedLinkService,
    },
    {
      provide: KEYFREE_PROVIDER,
      useClass: Square9ApiKeyfreeService,
    },
    {
      provide: SHARE_DOCUMENT_PROVIDER,
      useClass: Square9ApiShareService,
    },
    {
      provide: PRINT_PROVIDER,
      useClass: Square9ApiPrintService,
    },
    {
      provide: AUTHENTICATION_PROVIDER,
      useClass: Square9ApiAuthenticationService,
    },
    {
      provide: AUTHORIZATION_PROVIDER,
      useClass: Square9apiAuthorizationService,
    },
    { provide: LOGIN_PROVIDER, useClass: AuthenticationService },
    {
      provide: DOCUMENT_UPDATE_PROVIDER,
      useClass: Square9ApiDocumentUpdateService,
    },
    {
      provide: THUMBNAIL_PROVIDER,
      useClass: Square9ApiThumbnailService,
    },
    {
      provide: DOCUMENT_PROVIDER,
      useClass: Square9ApiDocumentService,
    },
    {
      provide: LIST_PROVIDER,
      useClass: Square9ApiListService,
    },
    {
      provide: EXPORT_PROVIDER,
      useClass: Square9ApiExportService,
    },
    {
      provide: USER_PROFILE_PROVIDER,
      useClass: Square9ApiUserProfileService,
    },
    {
      provide: GSE_API_CONFIG,
      useFactory: gseApiConfigFactory,
      deps: [ApplicationQuery],
    },
    {
      provide: INSTANCE_PROVIDER,
      useFactory: instanceProviderFactory,
      deps: [ApplicationQuery, ApplicationService],
    },
    {
      provide: CSV_IMPORT_PROVIDER,
      useClass: Square9ApiCSVImportService,
    },
    {
      provide: USER_ACTIONS_PROVIDER,
      useClass: Square9ApiUserActionsService,
    },
    {
      provide: API_METADATA_PROVIDER,
      useClass: Square9ApiMetadataService,
    },
    {
      provide: 'persistStorage',
      useValue: persistState(),
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { showDelay: 1000 },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'raised',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
