import { Inject, Injectable } from '@angular/core';
import { ThumbnailProvider } from 'models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 API thumbnail service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiThumbnailService implements ThumbnailProvider {
  private basePath: string;

  constructor(@Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = `${apiUrl}`));
  }

  /** @inheritdoc */
  getArchiveUrl(
    databaseId: number,
    archiveId: number,
    documentId: number,
    token: string,
    secureId: string,
    page: number,
    height: number,
    width: number
  ): string {
    const url =
      this.basePath +
      `/dbs/${databaseId}` +
      `/archives/${archiveId}` +
      `/documents/${documentId}` +
      `/thumb?token=${token}` +
      `&secureid=${secureId}` +
      `&page=${page}` +
      `&height=${height}` +
      `&width=${width}` +
      '&__ext=.jpg';

    return url;
  }

  /** @inheritdoc */
  getInboxUrl(
    inboxId: number,
    filename: string,
    token: string,
    height: number,
    width: number,
    page: number
  ): string {
    return (
      this.basePath +
      `/inboxes/${inboxId}` +
      `?filename=${filename}` +
      `&token=${token}` +
      `&height=${height}` +
      `&width=${width}` +
      `&page=${page}` +
      '&__ext=.jpg'
    );
  }
}
