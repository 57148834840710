import { assert } from 'common';
import { ArchiveRequestedDocument } from './archive-requested-document';
import { ArchiveRevisionRequestedDocument } from './archive-revision-requested-document';

/**
 * Generic requested document map.
 *
 * This is a generic map that can be used for any type of requested document, in
 * practice it is best to use one of the derived classes that constructs the map
 * for supported types.
 *
 * @see SupportedRequestedDocumentMap - Any supported type of requested document map.
 *
 * @param {T} T - The type of the values in the map.
 */

export class DocumentRequestMap<T> extends Map<number, T> {
  constructor(documents?: T[] | string) {
    super();
    if (typeof documents === 'undefined') return;

    var array =
      typeof documents === 'string' ? documents.split(',') : documents;

    for (let index = 0; index < array.length; index++) {
      const value = array[index];
      // Infer type from data and set to map.
      if (typeof value === 'string') {
        this.addParsedValue(value, index);
      } else {
        super.set(index, value);
      }
    }
  }

  /**
   * Parses the value and adds it to the map.
   *
   * Supports "docId|secureId", number, and string.
   *
   * @param {string} value - The value.
   * @param {number} index - The index of the value to parse and add.
   */
  private addParsedValue(value: string, index: number) {
    switch (this.inferTypeFromStringValue(value)) {
      case ParsableDataTypes.Number:
        super.set(index, Number(value) as T);
        break;
      case ParsableDataTypes.ArchiveRequest:
        const [docId, secureId] = value.split('|');
        super.set(index, {
          docId: Number(docId),
          secureId: secureId,
        } as T);
        break;
      case ParsableDataTypes.ArchiveRevisionRequest:
        const [parentDocId, parentSecureId, versionNumberString] =
          value.split('|');
        super.set(index, {
          docId: Number(parentDocId),
          secureId: parentSecureId,
          versionNumber: Number(versionNumberString),
        } as T);
        break;
      // Default, string.
      default:
        super.set(index, value as T);
        break;
    }
  }

  /**
   * Converts the values of the Map to a comma-separated string for use in route.
   *
   * @return {string} The comma-separated string representation of the Map values.
   */
  toString(): string {
    return [...super.values()].join(',');
  }

  /**
   * Returns the index of the first occurrence of a specified value in the array
   * created from the values of the superclass.
   *
   * @param {T} value - The value to locate in the array.
   * @return {number} The index of the first occurrence of the specified value in the array,
   * or -1 if not found.
   */
  indexOf(value: T): number {
    // If an object of type ArchiveRequestedDocument is passed in, we need to check if it matches by value.
    if (value instanceof ArchiveRequestedDocument) {
      for (let [index, doc] of super.entries()) {
        assert(doc instanceof ArchiveRequestedDocument);
        if (doc.docId === value.docId && doc.secureId === value.secureId)
          return index;
      }
      return -1; // Not in map.
    }
    return [...super.values()].indexOf(value);
  }

  /**
   * Deletes the specified value from the Map. If the value is an array,
   * all occurrences of the values in the array will be deleted.
   *
   * @param {T} value - The value or values to delete from the Map.
   * @return {void}
   */
  deleteValue(value: T): void {
    const targetValues = Array.isArray(value) ? value : [value];
    for (let value of targetValues) {
      for (let [key, existingValue] of super.entries()) {
        if (existingValue === value) {
          super.delete(key);
        }
      }
    }
  }

  /**
   * Infers the type of a string value.
   *
   * @param {string} value - The string value to infer the type of.
   * @return {string} The inferred type of the string value. Possible values are 'number', 'docId.secureId', or 'string'.
   */
  private inferTypeFromStringValue(value: string): ParsableDataTypes {
    assert(typeof value === 'string');
    // Numbers
    if (Number(value).toString() === value) return ParsableDataTypes.Number;
    // ArchiveRequests {docId: number; secureId: string} as 'docId.secureId'.
    if (ArchiveRequestedDocument.looksLike(value))
      return ParsableDataTypes.ArchiveRequest;
    if (ArchiveRevisionRequestedDocument.looksLike(value))
      return ParsableDataTypes.ArchiveRevisionRequest;
    // Default, string.
    return ParsableDataTypes.String;
  }
}

enum ParsableDataTypes {
  String = 'string',
  Number = 'number',
  ArchiveRequest = 'docId|secureId',
  ArchiveRevisionRequest = 'docId|secureId|versionNumber',
}
