import { Injectable } from '@angular/core';
import { satisfies } from 'semver';

import { ApplicationQuery } from '../state/application/application.query';

/** Feature version range. */
type FeatureVersionRange = string;

/** Supported Features Service. */
@Injectable({
  providedIn: 'root',
})
export class SupportedFeaturesService {
  /** Internal viewer imports range. */
  readonly internalViewerImports: FeatureVersionRange = '>=6.3.191';

  constructor(private appQuery: ApplicationQuery) {}

  /**
   * Determines whether a feature is supported in the current API version.
   *
   * @param feature Feature.
   * @returns True if the current version supports the feature.
   */
  versionSupports(feature: FeatureVersionRange): boolean {
    return satisfies(this.appQuery.apiVersion, feature);
  }
}
