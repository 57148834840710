import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { GseApiConfig } from 'gse-api';
import { User } from 'models';
import { ThemeMode, UserUiSettings } from 'src/app/models';
import { environment } from 'src/environments/environment';

import { FavoriteDatabaseSearches } from './favorite-searches.service';
import { OpenDocumentSource } from './open-document-source';

/**
 * Application state.
 */
export interface ApplicationState extends UserUiSettings {
  /** Version of the connected API. */
  apiVersion: string;
  /** Whether the archive navigation section is expanded. */
  archiveNavSectionExpanded: boolean;
  /** Whether the favorited searches navigation section is expanded. */
  favoriteSearchesExpanded: boolean;
  /** Dictionary of favorited searches. */
  favoritedSearches: { [databaseId: string]: FavoriteDatabaseSearches };
  /** GlobalSearch Extensions configuration. */
  gseApiConfig: GseApiConfig;
  /** Whether the inbox navigation section is expanded. */
  inboxNavSectionExpanded: boolean;
  /** Whether the indexer sidebar is open. */
  indexerSidebarOpen: boolean;
  /**
   * Base instance URL.
   *
   * @example https://myserver
   */
  instanceUrl: string;
  /** Whether the left sidebar navigation is toggled. */
  leftSidebarNavHidden: boolean;
  /** System notifications enabled. */
  notifications: boolean;
  /** Open document. */
  openDocument?: OpenDocumentSource;
  /** PDF URL to be displayed in preview sidebar. */
  pdfPreviewUrl: string;
  /** Whether the right sidebar is shown. */
  rightSidebarOpen: boolean;
  /** Whether the task search section is expanded. */
  taskSearchSectionExpanded: boolean;
  /** Logged in User. Undefined when not logged in. */
  user?: User;
}

/**
 * Creates a default user UI settings object.
 *
 * @returns Default user UI settings.
 */
export const createDefaultUserUiSettings = (): UserUiSettings => {
  // Use browsers preference for dark/light to decide our default value.
  const browserPreferesDark =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  return {
    alwaysOpenNewTab: false,
    archiveHistoryResultsPerPage: 50,
    archiveResultsPerPage: 50,
    currentLanguage: 'English',
    deleteInboxDocumentOnInsert: false,
    deleteSearchDocumentsOnInsert: false,
    dxcAppendToMultivalue: false,
    dxcSearchSystemFields: true,
    doubleClickOpen: true,
    enabledTasksList: [],
    hideArchivesWithNoResults: true,
    keepBaseSearchDocumentOnInsert: true,
    keyfreeUseOcr: true,
    persistArchiveImportData: false,
    refreshResultsWhenClosingDocumentTabs: false,
    showDomainInHistoryResults: false,
    showFavoriteSearches: true,
    showGlobalActionTasks: true,
    showVersionsArchive: false,
    sortResultsArchivesByResultsCount: false,
    themeMode: browserPreferesDark ? ThemeMode.dark : ThemeMode.light,
    thumbnailHeight: 120,
    thumbnailWidth: 120,
    usePreviousSearchCriteriaInRelatedSearch: false,
    viewerAutoSave: false,
    viewerGoToFirstPageOnLoad: false,
    viewerUseInternal: true,
    viewerUseInternalForImport: true,
  };
};

/**
 * Create the initial application state.
 *
 * @returns Default application state.
 */
export const createInitialState = (): ApplicationState => {
  return {
    ...createDefaultUserUiSettings(),
    apiVersion: '',
    archiveNavSectionExpanded: true,
    favoriteSearchesExpanded: true,
    favoritedSearches: {},
    gseApiConfig: {
      apiUrl: environment.gseUrlDefault,
    },
    inboxNavSectionExpanded: true,
    indexerSidebarOpen: true,
    instanceUrl: '',
    leftSidebarNavHidden: true,
    notifications: false,
    rightSidebarOpen: false,
    pdfPreviewUrl: '',
    user: undefined,
    taskSearchSectionExpanded: true,
  };
};

/**
 * Applications store.
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application' })
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super(createInitialState());
  }
}
