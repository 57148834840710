import { Inject, Injectable } from '@angular/core';
import { arrayUpdate, setLoading } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { Inbox, InboxFile, InboxProvider } from 'models';
import { NO_ACTIVE_ID } from 'src/app/common/constants';
import { INBOX_PROVIDER } from 'src/app/common/tokens';
import { toNumberOrUndefined } from 'src/app/common/utility';
import { AppConfigQuery } from 'src/app/modules/app-config';

import { ApplicationQuery } from '../application/application.query';

import { InboxesStore } from './inboxes.store';

/**
 * Inbox state service.
 */
@Injectable({ providedIn: 'root' })
export class InboxesService {
  /** Direct reference to the API provider. */
  api = this.inboxProvider;

  constructor(
    private logger: NGXLogger,
    private inboxesStore: InboxesStore,
    private routerQuery: RouterQuery,
    private appQuery: ApplicationQuery,
    private appConfigQuery: AppConfigQuery,
    @Inject(INBOX_PROVIDER) private inboxProvider: InboxProvider
  ) {
    this.routerQuery
      .selectParams('inboxId')
      .pipe(map(toNumberOrUndefined), distinctUntilChanged())
      .subscribe((inboxId) => {
        this.logger.debug(
          typeof inboxId === 'undefined'
            ? 'No inbox active.'
            : `Inbox ${inboxId} set as active.`
        );
        this.inboxesStore.setActive(inboxId ?? NO_ACTIVE_ID);
        if (inboxId !== NO_ACTIVE_ID)
          this.getById(inboxId as number).subscribe();
      });

    this.appQuery.userLoggedIn$.subscribe(() => this.get());
  }

  /**
   * Adds an inbox to the store.
   *
   * @param inbox Inbox to be added to the store.
   */
  add(inbox: Inbox) {
    this.inboxesStore.add(inbox);
  }

  /**
   * Refresh all inboxes.
   */
  get() {
    this.inboxProvider
      .getAll()
      .pipe(
        setLoading(this.inboxesStore),
        map((inboxes) => {
          if (this.appConfigQuery.sortArchivesTasksAndInboxesAlphabetically) {
            // Sort inboxes alphabetically.
            inboxes = inboxes.sort((a, b) => {
              const aName = a.name.toLowerCase();
              const bName = b.name.toLowerCase();
              return aName < bName ? -1 : aName > bName ? 1 : 0;
            });
          }

          return inboxes;
        })
      )
      .subscribe((inboxes) => {
        this.logger.debug('Refreshing inboxes from server.');
        this.inboxesStore.set(inboxes);
      });
  }

  /**
   * Gets an inbox by ID and updates it in the store.
   *
   * @param id Inbox ID.
   * @returns An Observable inbox.
   */
  getById(id: number): Observable<Inbox> {
    if (!id) {
      return EMPTY;
    }
    return this.inboxProvider.get(id).pipe(
      setLoading(this.inboxesStore),
      tap((inbox) => {
        this.logger.debug('Inbox store will be refreshed.', inbox);
        this.update(id, inbox);
      })
    );
  }

  /**
   * Imports a file from the cache into an inbox.
   *
   * @param id Inbox ID.
   * @param cacheFilename Filename of the document as it exists in the cache.
   * @param desiredFilename Desired filename for the document in the inbox.
   * @returns An observable of the imported filename.
   */
  importFile(
    id: number,
    cacheFilename: string,
    desiredFilename: string
  ): Observable<string> {
    return this.inboxProvider.importFile(id, cacheFilename, desiredFilename);
  }

  /**
   * Remove an inbox from the store.
   *
   * @param id Inbox ID.
   */
  remove(id: number) {
    this.inboxesStore.remove(id);
  }

  /**
   * Rename an document.
   *
   * @param id Inbox ID.
   * @param file File object to modifed. Must have a newName.
   * @returns A transfer result.
   */
  renameDocument(id: number, file: InboxFile): Observable<InboxFile> {
    return this.inboxProvider.renameDocuments(id, [file]).pipe(
      map((files) => files[0]),
      tap((inboxFile) => {
        this.inboxesStore.update(id, ({ files }) => ({
          files: arrayUpdate(
            files,
            (f: InboxFile) =>
              f.filename === file.filename && f.fileType === file.fileType,
            {
              filename: inboxFile.newName,
            }
          ),
        }));
      })
    );
  }

  /**
   * Update Inbox store data.
   *
   * @param id Inbox ID.
   * @param inbox Data to update.
   */
  update(id: number, inbox: Partial<Inbox>) {
    this.inboxesStore.update(id, inbox);
  }
}
