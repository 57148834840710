import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { filterNilValue } from '@datorama/akita';
import { TranslocoService } from '@jsverse/transloco';
import { HotkeysService } from '@ngneat/hotkeys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Crypto from 'crypto-es';
import { NGXLogger } from 'ngx-logger';
import { finalize, map, mergeMap, of } from 'rxjs';

import { GSEProvider, UserFriendlyError } from 'models';
import { GUEST_USERNAME } from 'src/app/common/constants';
import { GSE_PROVIDER } from 'src/app/common/tokens';
import { isEmail } from 'src/app/common/utility';
import { ThemeMode } from 'src/app/models';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { CommandService } from 'src/app/modules/command-palette';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GSEDialogService } from 'src/app/services/gsedialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { DatabasesStore } from 'src/app/state/databases/databases.store';

import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { UserSettingsDialogComponent } from '../user-settings-dialog/user-settings-dialog.component';

/**
 * User menu for navigation bar.
 */
@UntilDestroy()
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  /** Dark mode enabled. */
  darkMode: boolean;
  /** Observable that determines if the dark mode toggle should be disabled. */
  disableDarkModeToggle$ = this.appQuery.themeMode$.pipe(
    map((themeMode) => themeMode === ThemeMode.system)
  );
  /** Observable of the username for display. */
  displayUsername$ = this.appQuery.user$.pipe(
    filterNilValue(),
    mergeMap((user) =>
      user.name === GUEST_USERNAME
        ? this.translate.selectTranslate('GUEST')
        : of(user.name)
    )
  );
  /** Is user an admin of all databases? */
  isAdminAll: boolean;
  /** Is GlobalSearch Extensions currently connected? */
  isGseConnected$ = this.gse.isConnected$;
  /** Is GlobalSearch Extensions currently being installed? */
  isGseInstalling: boolean;
  /** Whether the current session is running windows. */
  isWindows = this.platform.isWindows;
  /** System notifications enabled. */
  notifications: boolean;
  /** Observable active user. */
  user$ = this.appQuery.user$;

  private unregisterCommands: Array<() => void> = [];

  constructor(
    private auth: AuthenticationService,
    private database: DatabasesStore,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private notify: NotificationService,
    private hotkeys: HotkeysService,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private commandService: CommandService,
    private translate: TranslocoService,
    private databasesQuery: DatabasesQuery,
    private appConfigQuery: AppConfigQuery,
    private platform: PlatformService,
    private gseDialog: GSEDialogService,
    @Inject(GSE_PROVIDER) private gse: GSEProvider
  ) {}

  /**
   * User avatar URL.
   *
   * @returns URL source string.
   */
  get avatarUrl(): string {
    const username = this.auth.user.name;
    const email = username;
    return `https://www.gravatar.com/avatar/${this.gravatarHash(
      email
    )}?r=g&s=144&d=identicon`;
  }

  /**
   * Is the user a guest?
   *
   * @returns True if the user is a guest.
   * */
  get isGuest() {
    return this.auth.isGuest;
  }

  /**
   * Should the Extensions menu items be displayed?
   *
   * @returns True if the Extensions menu items should be displayed.
   */
  get showExtensionsItems(): boolean {
    return this.isWindows && !this.isGuest;
  }

  /**
   * True if authenticated username is an email address.
   *
   * @returns True if the current user's username is an email address.
   */
  get userHasEmail(): boolean {
    return isEmail(this.auth.user.name);
  }

  /**
   * Install GlobalSearch Extensions.
   *
   * Initiates installation if unavailable.
   */
  installExtensions() {
    this.gseDialog.openInstall();
  }

  /**
   * Logout.
   */
  logout() {
    this.dialog.closeAll();
    this.auth.logout().subscribe((success) => {
      if (success) {
        this.database.setActive(null);
        this.notify.info('Logged out.');
      }
    });
  }

  ngOnDestroy(): void {
    for (const unregisterCommand of this.unregisterCommands) {
      unregisterCommand();
    }
  }

  ngOnInit() {
    // Dark mode observation.
    this.appQuery.darkMode$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.darkMode = state;
    });

    // User observation.
    this.appQuery.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.isAdminAll = user?.isAdminAll ?? false;
    });

    // Notification setting observation.
    this.appQuery.notifications$
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.notifications = state;
      });

    // Register hotkey and pallet commands.
    this.registerHotkeysAndCommands();
  }

  /**
   * Event handler for setting darkmode from slide toggle.
   *
   * @param event Slide toggle changed event.
   */
  onDarkModeChange(event: MatSlideToggleChange) {
    const on = event.checked;
    this.logger.debug(`${on ? 'En' : 'Dis'}abled dark mode.`);
    this.appService.setDarkMode(on);
  }

  /**
   * Event handler for setting notifcations enable from slide toggle.
   *
   * @param event Slide toggle changed event.
   */
  onNotificationsChange(event: MatSlideToggleChange) {
    const on = event.checked;
    this.logger.debug(`${on ? 'En' : 'Dis'}abled notifcations.`);
    this.appService.setNotifications(on);
    if (on) {
      const dismissInfo = this.notify.info('SYSTEM_NOTIFICATIONS_ENABLED');
      setTimeout(dismissInfo, 2000);
    }
  }

  /**
   * Show the about dialog.
   */
  showAbout() {
    this.dialog.open(AboutDialogComponent);
  }

  /**
   * Show GlobalSearch Extensions.
   */
  showExtensions() {
    // Syncing GSE before "show" is disabled, since it can push a broken URL if
    // the user is targeting a different instance etc.
    //this.syncGseSettings();

    // Open the GSE UI.
    this.gse.open().subscribe();
  }

  /**
   * Display controls to allow user to edit their profile/account as possible.
   *
   * @todo Display profile for edit.
   * @throws Not implemented.
   */
  showProfile() {
    this.notify.error('Profile edit has not been implemented.');
    throw new Error('Method not implemented.');
  }

  /**
   * Display "User Settings" dialog.
   */
  showSettings() {
    this.logger.debug('Opening User Settings dialog.');
    this.dialog
      .open(UserSettingsDialogComponent)
      .afterClosed()
      .subscribe((saved) => {
        this.logger.debug(saved ? 'Settings saved.' : 'Settings cancelled.');
        if (saved) {
          this.notify.success('Settings Saved');
        }
      });
  }

  /**
   * Create a hash from email for gravatar.
   *
   * @param email Address to hash.
   * @returns Hash string.
   */
  private gravatarHash(email: string): string {
    return Crypto.MD5(email.trim().toLowerCase()).toString();
  }

  /**
   * Register hotkeys and palette commands.
   */
  private registerHotkeysAndCommands() {
    // Settings
    this.hotkeys
      .addShortcut({
        keys: 'control.u',
        group: 'User',
        description: 'Show settings dialog',
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.logger.debug('Hotkey for user settings pressed.');
        this.showSettings();
      });
    this.unregisterCommands.push(
      this.commandService.register(
        'User Settings',
        'Show user settings dialog',
        () => this.showSettings(),
        'User'
      )
    );

    // Logout
    this.hotkeys
      .addShortcut({
        keys: 'control.l',
        group: 'User',
        description: 'Logout',
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.logger.debug('Hotkey for logout pressed.');
        this.logout();
      });

    this.unregisterCommands.push(
      this.commandService.register(
        'Logout',
        'Logout',
        () => this.logout(),
        'User'
      ),
      this.commandService.register(
        'Toggle Dark Mode',
        'Toggle the Dark Mode settings.',
        () => this.appService.setDarkMode(!this.darkMode),
        'User'
      )
    );
  }

  /**
   * Update the API URL configured in GSE.
   *
   * This is documented as how a user can "fix" their GSE installation not
   * connecting to the API.
   */
  private syncGseSettings() {
    const dismissSyncStartNotification = this.notify.info(
      'GSE_SYNCING_IN_BACKGROUND'
    );
    // GSE version of URL is not suffixed with '/api/'.
    const gseFriendlyUrl = this.appConfigQuery.appConfig.apiUrl
      .trim()
      .replace(/\/api\/?$/i, '');
    // Sync API url to GSE settings.
    this.gse
      .syncExtensions(gseFriendlyUrl)
      .pipe(finalize(() => dismissSyncStartNotification()))
      .subscribe({
        error: (error: UserFriendlyError) => {
          // Display notification to user.
          this.notify.error(error);
        },
      });
  }
}
