import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { User } from 'models';

import { LOGIN_PROVIDER, LoginProvider } from '../../models/login-provider';
import { MsalHelperService } from '../../services/msal-helper.service';
import {
  OpenIdProviders,
  ProviderService,
} from '../../services/provider.service';
import { RedirectService } from '../../services/redirect.service';

/** Redirect component to handle provider redirects. */
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  private msalService = this.msalHelperService.msalService;
  constructor(
    private logger: NGXLogger,
    private msalHelperService: MsalHelperService,
    @Inject(LOGIN_PROVIDER)
    private loginProvider: LoginProvider,
    private redirectService: RedirectService,
    private providerService: ProviderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listenForToken();
  }

  private handleErrors(provider: string, error: any): Observable<never> {
    this.logger.error(`Error logging in with ${provider}.`, error);
    this.router.navigate(['/login'], {
      queryParams: {
        error: btoa(
          'An error occurred while attemping to log in with provider.'
        ),
      },
    });
    return throwError(() => error);
  }

  private listenForToken(): void {
    const provider = window.sessionStorage.getItem('login.provider') ?? '';
    this.logger.debug('Provider loaded from session storage.', provider);
    if (!provider) {
      this.handleErrors(
        provider,
        'No provider was available in session storage under the key login.provider'
      );
      return;
    }
    const token$: Observable<string> =
      provider === 'azure'
        ? this.msalService.handleRedirectObservable().pipe(
            filter((result) => !!result),
            catchError((error) => this.handleErrors(provider, error)),
            map((result) => result.idToken)
          )
        : this.providerService
            .handleRedirect(provider as OpenIdProviders)
            .pipe(catchError((error) => this.handleErrors(provider, error)));

    token$.subscribe((token) => {
      this.loginWithToken(token, provider).subscribe({
        error: (error) => {
          this.handleErrors(provider, error);
        },
      });
    });
  }

  private loginWithToken(jwtToken: string, provider: string): Observable<User> {
    return this.loginProvider.tokenLogin(jwtToken, provider).pipe(
      catchError((error) => {
        this.logger.warn('Token login attempt failed.', error);
        // this.error = error;
        return throwError(() => error);
      }),
      tap(() => this.onLoginSuccess())
    );
  }

  private onLoginSuccess(): void {
    this.logger.debug('Login complete, redirecting.');
    const redirectUrl = window.sessionStorage.getItem('redirect.uri') ?? '';
    window.sessionStorage.removeItem('redirect.uri');
    window.sessionStorage.removeItem('login.provider');
    if (redirectUrl === '') {
      this.logger.warn(
        'Redirect URL loaded from session was empty, no redirect will take place.'
      );
    }
    this.redirectService.redirect(redirectUrl);
  }
}
