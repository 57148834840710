<mat-sidenav-container class="sidenav-container">
  <!-- Left side bar -->
  <mat-sidenav
    #drawer
    class="sidenav mat-elevation-z3"
    fixedInViewport
    [attr.role]="(isHandsetOrHidden$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandsetOrHidden$ | async) ? 'over' : 'side'"
    [opened]="isOpened$ | async"
  >
    <ng-container *ngIf="!isGuest">
      <mat-toolbar class="mat-primary mat-elevation-z2">
        <app-logo-svg></app-logo-svg>
        <app-name-svg></app-name-svg>
      </mat-toolbar>
      <div
        class="scrolling-sidenav-container"
        *ngIf="database$ | async as database"
      >
        <mat-nav-list>
          <!-- Tasks -->
          <app-task-search-panel></app-task-search-panel>
          <!-- Favorites -->
          <app-favorite-searches-panel
            [database]="database"
          ></app-favorite-searches-panel>
          <!-- Inboxes -->
          <app-inboxes-panel></app-inboxes-panel>
          <!-- Archives -->
          <app-archive-panel [database]="database"></app-archive-panel>
        </mat-nav-list>
      </div>
    </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Top Navigation Bar -->
    <mat-toolbar id="topNavigationBar" color="primary" class="mat-elevation-z2">
      <ng-container
        *ngIf="isGuest; then guestAppIcon; else standardAppIcon"
      ></ng-container>
      <ng-template #guestAppIcon>
        <div class="guest-logo">
          <app-logo-svg logo-size="40"></app-logo-svg>
          <app-name-svg
            *ngIf="
              (isBrandingHidden$ | async) === false &&
              (compactLayout$ | async) === false
            "
          ></app-name-svg>
        </div>
      </ng-template>
      <ng-template #standardAppIcon>
        <button
          aria-label="Toggle Left Sidebar"
          mat-icon-button
          class="logo-button"
          (click)="drawer.toggle()"
          *ngIf="shouldShowCompactLogoBtn$ | async"
        >
          <app-logo-svg logo-size="40"></app-logo-svg>
        </button>
      </ng-template>
      <!-- Home Button  -->
      <ng-container *ngIf="database$ | async as database">
        <button
          class="home-button"
          [ngClass]="{ 'home-button-guest': isGuest }"
          type="button"
          mat-icon-button
          matTooltip="{{ 'RETURN_TO_DATABASE_DASHBOARD' | transloco }}"
          matTooltipShowDelay="1000"
          matTooltipPosition="right"
          [routerLink]="['db', database.id]"
          [routerLinkActiveOptions]="{
            paths: 'exact',
            matrixParams: 'exact',
            queryParams: 'ignored',
            fragment: 'exact',
          }"
          routerLinkActive="home-button-db-route-active"
        >
          <mat-icon>home</mat-icon>
        </button>
      </ng-container>
      <span class="spacer"></span>
      <app-csvimport-job-manager-button></app-csvimport-job-manager-button>
      <!-- Admin Menu -->
      <app-admin-menu *ngIf="isAdminAny"></app-admin-menu>
      <app-custom-links-menu></app-custom-links-menu>
      <!-- Database Select -->
      <app-database-menu *ngIf="isLoggedIn"></app-database-menu>
      <button
        mat-icon-button
        aria-label="Submit Feedback"
        matTooltip="{{ 'SUBMIT_FEEDBACK_TOOLTIP' | transloco }}"
        matTooltipShowDelay="1000"
        (click)="onSubmitFeedback()"
      >
        <mat-icon>rate_review</mat-icon>
      </button>
      <!-- Help -->
      <button
        aria-label="Show help"
        type="button"
        mat-icon-button
        [matTooltip]="'HELP' | transloco"
        (click)="showHelp()"
      >
        <mat-icon>help</mat-icon>
      </button>
      <!-- User Menu -->
      <app-user-menu *ngIf="isLoggedIn"></app-user-menu>
    </mat-toolbar>
    <!-- Main Content Area -- Filled by router -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
