import { BreakpointObserver } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  finalize,
  first,
  forkJoin,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import * as Tesseract from 'tesseract.js';
import { v4 as uuid } from 'uuid';

import { assert, assertExists, assertTypeByKey } from 'common';
import {
  Archive,
  ArchiveImportFile,
  DXCSource,
  DocumentAnnotations,
  DocumentProvider,
  DocumentUpdateProvider,
  Field,
  FieldDataType,
  ImportedArchiveDocument,
  InboxFile,
  InboxSession,
  PdfOptions,
  Search,
  SearchOptions,
  SearchPrompt,
  SearchResult,
  SearchResults,
  TextLayer,
  TextLayers,
  UserFriendlyError,
  createApiSearchPromptString,
  createInboxSessionDocumentFromInboxFile,
  filterReadOnlyPermissions,
} from 'models';
import {
  CURRENT_DOCUMENT_REVISION,
  INTERNAL_VIEWER_CLIENT_SUPPORTED_FILE_TYPES,
} from 'src/app/common/constants';
import {
  DOCUMENT_PROVIDER,
  DOCUMENT_UPDATE_PROVIDER,
} from 'src/app/common/tokens';
import {
  ActionsMenu,
  ArchiveImportRequestedDocument,
  ArchiveImportRequestedDocumentMap,
  ArchiveRequestedDocument,
  ArchiveRequestedDocumentMap,
  ArchiveRevisionRequestedDocument,
  ArchiveRevisionRequestedDocumentMap,
  ArchiveSearchRequestedDocument,
  ArchiveSearchRequestedDocumentMap,
  DirtyComponent,
  DocumentUpdateSession,
  InboxDocumentOpenRequest,
  InboxRequestedDocument,
  InboxRequestedDocumentMap,
  SearchResultDocumentOpenRequest,
  SupportedRequestedDocumentMap,
} from 'src/app/models';
import { PdfViewerComponent } from 'src/app/modules/pdf-viewer';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { KeyfreeIndexingService } from 'src/app/services/keyfree-indexing.service';
import { KfiEditDialogService } from 'src/app/services/kfi-edit-dialog.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProgressDialogService } from 'src/app/services/progress-dialog.service';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchiveSessionCacheQuery } from 'src/app/state/archive-document-cache/archive-document-cache.query';
import { ArchiveDocumentCacheService } from 'src/app/state/archive-document-cache/archive-document.cache.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { ArchivesService } from 'src/app/state/archives/archives.service';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { InboxesQuery } from 'src/app/state/inboxes/inboxes.query';
import { InboxesService } from 'src/app/state/inboxes/inboxes.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';
import { SearchesService } from 'src/app/state/searches/searches.service';
import { TaskSearchesService } from 'src/app/state/task-searches/task-searches.service';

import { UiService } from 'src/app/services/ui.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import { DocumentViewSidebarComponent } from '../document-view-sidebar/document-view-sidebar.component';
import { IndexerField } from '../indexer/indexer.component';
import { TableFieldGridComponent } from '../table-field-grid/table-field-grid.component';
import { UserActionExecutedEvent } from '../user-actions-panel/user-actions-panel.component';

/** Document View. */
@UntilDestroy()
@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent
  implements OnInit, DirtyComponent, OnDestroy
{
  /** Actions menu. */
  @ViewChild('actionMenuComponent')
  actionMenuComponent: ActionsMenu;
  /** PDF Viewer reference. */
  @ViewChild(PdfViewerComponent)
  pdfviewer: PdfViewerComponent;
  /** Document view sidebar reference. */
  @ViewChild('rightSidebar')
  rightSidebar: DocumentViewSidebarComponent;
  /** Table Field Grid reference. */
  @ViewChild('tableFieldGrid')
  tableFieldGrid?: TableFieldGridComponent;

  /** Annotation Canvas. */
  annotationCanvas: HTMLCanvasElement;
  /** Annotation Context. */
  annotationContext: CanvasRenderingContext2D;
  /** Archive Id. */
  archiveId: number;
  /** Canvas Element. */
  canvas: HTMLCanvasElement;
  /** Currently selected field. */
  currentField: Field;
  /** Current hovered text layer. */
  currentTextLayer: TextLayer;
  /** Database Id. */
  databaseId: number;
  /** @inheritdoc */
  dirtyFn?: (() => Observable<boolean>) | undefined;
  /**
   * Observable document result.
   *
   * Watches changes in routing to update the loaded document.
   */
  document$ = combineLatest([
    this.searchesQuery.searchRouteParams$,
    this.routerQuery.selectParams([
      'inboxId',
      'documentId',
      'viewIndex',
      'taskId',
      'sessionId',
    ]),
    this.routerQuery.selectData('isImport'),
    this.routerQuery.selectQueryParams('targetArchiveId'),
  ]).pipe(
    untilDestroyed(this),
    debounceTime(1),
    tap(() => {
      // Reset state.
      this.isUnsupportedDocument = false;
      this.logger.info('Updating loading document based on routing.');
    }),
    switchMap(
      ([
        [databaseId, archiveId, searchId, searchPrompts],
        [inboxId, documentId, viewIndex, taskId, sessionId],
        isImport,
        targetArchiveId,
      ]) => {
        this.isImport = !!isImport;
        this.logger.debug('Import mode: ', this.isImport);
        // All require.
        assertExists(databaseId, 'Database Id is required.');

        // Document id here could be undefined if using an archive session.
        // That code path already updates the requested documents itself.
        if (documentId) {
          // Requested documents is set in ngOnInit on initial load of this component but does not update it when the route changes.
          // We need to ensure that the value is always updated.
          this.requestedDocuments =
            this.archiveId > 0 && !this.isImport
              ? documentId.split(',').map(Number)
              : documentId.split(',');
        }

        if (typeof inboxId !== 'undefined') {
          // Inboxes.
          if (targetArchiveId) {
            this.inboxTargetArchiveId = Number(targetArchiveId);
            this.importUniqueId = uuid();
            this.tableFieldUIService.clear();
            this.listenToTableFieldEvent();
          }
          return this.getSearchResultForInboxRoute(
            databaseId,
            inboxId,
            documentId,
            viewIndex
          );
        } else if (typeof searchId !== 'undefined') {
          // Archives.
          assertExists(archiveId, 'Archive Id is required.');
          // TODO Is this the right place to put this?
          this.listenToTableFieldEvent();
          return this.getSearchResultForArchiveRoute(
            archiveId,
            databaseId,
            searchId,
            searchPrompts,
            documentId,
            viewIndex
          );
        } else if (typeof taskId !== 'undefined') {
          assertExists(archiveId, 'Archive Id is required.');
          this.listenToTableFieldEvent();
          return this.getSearchResultForTaskSearchRoute(
            databaseId,
            archiveId,
            documentId,
            viewIndex
          );
        } else if (this.isImport) {
          this.importUniqueId = uuid();
          this.listenToTableFieldEvent();
          return this.getArchiveImportFile(viewIndex, documentId);
        } else if (
          typeof archiveId !== 'undefined' &&
          typeof sessionId !== 'undefined'
        ) {
          this.logger.debug('Checking if archive view mode can be used.');
          this.listenToTableFieldEvent();
          return this.getSearchResultFromArchiveSession(
            sessionId,
            viewIndex,
            databaseId,
            archiveId
          );
        } else if (typeof archiveId !== 'undefined') {
          if (ArchiveRevisionRequestedDocument.looksLike(documentId)) {
            this.viewingRevision = true;
            this.listenToTableFieldEvent();
            return this.getSearchResultForArchiveRevision(
              databaseId,
              archiveId,
              documentId
            );
          } else if (ArchiveRequestedDocument.looksLike(documentId)) {
            return this.getSearchResultFromArchiveDocument(
              databaseId,
              archiveId,
              documentId
            );
          } else {
            throw new TypeError(
              'Archive requested document route not yet supported.'
            );
          }
        } else {
          throw new TypeError('Inbox Id or Search Id are required.');
        }
      }
    ),
    catchError(() => this.handleMissingDocument()),
    filterNilValue(),
    shareReplay(1)
  );
  /** Active drag. */
  dragActive = false;
  /** Drag text. */
  dragText = '';
  /** Force OCR. */
  forceOcr = false;
  /** Unique Id for imports. */
  importUniqueId = '';
  /** Archive Id where inbox document should be indexed. */
  inboxTargetArchiveId = 0;
  /** Observable of whether the indexer sidebar should be open. */
  indexerSidebarOpen$ = this.auth.isGuest
    ? of(false) // Never show the sidebar if the user is a guest.
    : this.appQuery.indexerSidebarOpen$;
  /** Is the document currently loading. */
  isDocumentLoading = false;
  /** If the user is guest. */
  isGuest = this.auth.isGuest;
  /** Whether the viewer is in import mode. */
  isImport = false;
  /** Is mouse down. */
  isMouseDown = false;
  /** Document search is loading. */
  isSearchLoading = true;
  /** Is shift keydown. */
  isShiftDown = false;
  /** If the document format is unsupported. */
  isUnsupportedDocument = false;
  /** Is KFI Active. */
  kfiActive = false;
  /** Track the kfi hover text. */
  kfiHoverText = '';
  /** Object for tracking kfi rectangle. */
  kfiRectangle = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0,
  };
  /** Rectangle div element. */
  kfiRectangleDiv: HTMLDivElement;
  /** Track the kfi hover text when shift is held. */
  kfiShiftHoverText = '';
  /** Last focused table field. */
  lastFocusedTableField = 0;
  /** Last page line focused. */
  lastLine = 0;
  /** Current page number. */
  pageNumber = 1;
  /** PDF options. */
  pdfOptions?: PdfOptions;
  /** Track redraw interval. */
  redrawInterval: NodeJS.Timeout;
  /** Regular Expression highlight canvas. */
  regexHighlightCanvas: HTMLCanvasElement;
  /** Regular Expression highlight context. */
  regexHighlightContext: CanvasRenderingContext2D;
  /** Version control archive. */
  revisionArchive: Archive;
  /** Whether a save is in progress. */
  saveInProgress = false;
  /** Whether to show the table field grid. */
  showTableFieldGrid = false;
  /** Table Field Tracking. */
  tableFieldIds: number[];
  /** Text Layers. */
  textLayers: TextLayers = [];
  /** If a compact layout should be used. */
  useCompactLayout = false;
  /** Use local ocr. */
  useLocalOcr = false;
  /** Observable of whether to always load the first page on viewer load. */
  viewerGoToFirstPageOnLoad$ = this.appQuery.viewerGoToFirstPageOnLoad$;
  /** Is a revision being viewed. */
  viewingRevision = false;

  private archiveCacheSessionId: string;
  private autoSave = false;
  private document: SearchResult | InboxFile | ArchiveImportFile;
  private inboxId: number;
  /** KFI page change subject used to debounce OCR triggers when page changes occur. */
  private kfiPageChangeSubject = new Subject<number>();
  /** KFI page change subscription used to unsubscribe when KFI is toggled off. */
  private kfiPageChangeSubscription: Subscription;
  /** Store a list of document Id or filenames (archive or inbox). */
  private requestedDocuments: SupportedRequestedDocumentMap;
  private scaleChangeSubscription: Subscription;
  private searchId: number;
  private sessionId: number;
  private taskId: string;
  private viewIndex = 0;

  constructor(
    private searchesQuery: SearchesQuery,
    private searchesService: SearchesService,
    private searchUIService: SearchUIService,
    private inboxesService: InboxesService,
    private inboxesQuery: InboxesQuery,
    private routerQuery: RouterQuery,
    private archiveCacheQuery: ArchiveSessionCacheQuery,
    private archivesQuery: ArchivesQuery,
    private logger: NGXLogger,
    @Inject(DOCUMENT_PROVIDER) private documentProvider: DocumentProvider,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider,
    private auth: AuthenticationService,
    private appQuery: ApplicationQuery,
    private databasesQuery: DatabasesQuery,
    private application: ApplicationService,
    private viewerService: ViewerService,
    private archiveService: ArchivesService,
    private archiveCacheService: ArchiveDocumentCacheService,
    private router: Router,
    private translate: TranslocoService,
    private layout: LayoutService,
    private notify: NotificationService,
    private tableFieldUIService: TableFieldUIService,
    private ui: UiService,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorReference: ChangeDetectorRef,
    private taskSearchesService: TaskSearchesService,
    private progressDialogService: ProgressDialogService,
    private kfiEditDialogService: KfiEditDialogService,
    private keyfreeService: KeyfreeIndexingService,
    private dialog: MatDialog,
    private overlayContainer: OverlayContainer
  ) {
    // These events must be bound so that 'this' in them is this class.
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
  }

  /**
   * Returns the active revision number for the current document.
   *
   * @return {number} The active revision number. Returns 0 if the display mode is not 'archiveRevision'.
   */
  get activeRevisionNumber(): number {
    if (this.displayMode !== 'archiveRevision') {
      return 0;
    }

    return (
      this.requestedDocuments.get(
        this.viewIndex
      ) as ArchiveRevisionRequestedDocument
    ).versionNumber;
  }

  /**
   * Should the action menu button be disabled.
   *
   * @returns True if the action menu button should be disabled.
   */
  get disableActionMenuButton(): boolean {
    return this.kfiActive || this.displayMode === 'archiveSession';
  }

  /**
   * Gets whether the save button should be disabled.
   *
   * @returns True if the save button should be disabled.
   */
  get disableSave(): boolean {
    return (
      this.saveInProgress ||
      (this.rightSidebar?.indexer?.disableSave && !this.tableFieldGrid?.isDirty)
    );
  }

  /**
   * Get the current display mode based on loaded state.
   *
   * @returns Display mode string.
   */
  get displayMode():
    | 'archive'
    | 'archiveSession'
    | 'inbox'
    | 'inboxIndexing'
    | 'task'
    | 'archiveImport'
    | 'archiveRevision' {
    if (this.taskId) return 'task';
    if (this.isImport) return 'archiveImport';
    if (this.sessionId) return 'archiveSession';
    if (typeof this.inboxId !== 'undefined' && !Number.isNaN(this.inboxId)) {
      return this.inboxTargetArchiveId > 0 ? 'inboxIndexing' : 'inbox';
    }
    if (typeof this.archiveId !== 'undefined')
      return this.viewingRevision ? 'archiveRevision' : 'archive';
    throw new Error(
      'Loaded state does not meet criteria for any handled display mode.'
    );
  }

  /**
   * Get the document as ArchiveImportFile.
   *
   * @returns ArchiveImportFile.
   */
  get documentAsArchiveImportFile(): ArchiveImportFile {
    assertTypeByKey<ArchiveImportFile>(
      this.document,
      'filename',
      'string',
      'This method requires the document to be an ArchiveImportFile.'
    );

    return this.document;
  }

  /**
   * Get the document as InboxFile.
   *
   * @returns InboxFile.
   */
  get documentAsInboxFile(): InboxFile {
    assertTypeByKey<InboxFile>(
      this.document,
      'filename',
      'string',
      'This method requires the document be an InboxFile.'
    );
    return this.document;
  }

  /**
   * Get the document as SearchResult.
   *
   * @returns SearchResult.
   * @throws {AssertionError} if the document is not a SearchResult.
   */
  get documentAsSearchResult(): SearchResult {
    assertTypeByKey<SearchResult>(
      this.document,
      'secureId',
      'string',
      'This method requires the document be a SearchResult.'
    );
    return this.document;
  }

  /**
   * Get the current and total document numbers.
   *
   * @returns Object with properties for current and total number.
   */
  get documentOfDocuments() {
    if (typeof this.viewIndex !== 'number' || !this.requestedDocuments?.size) {
      // Short circuit to undefined if required values for view are not available.
      return;
    }
    return {
      current: this.viewIndex + 1,
      total: this.requestedDocuments.size,
    };
  }

  /**
   * Gets the document's permissions.
   *
   * @returns A permissions object.
   */
  get documentPermissions() {
    switch (this.displayMode) {
      case 'archive':
      case 'archiveSession':
      case 'task':
        return this.documentAsSearchResult.permissions;
      case 'archiveImport':
        return this.archivesQuery.active.permissions;
      case 'archiveRevision':
        if (this.activeRevisionNumber === CURRENT_DOCUMENT_REVISION) {
          return this.documentAsSearchResult.permissions;
        }
        return filterReadOnlyPermissions(
          this.documentAsSearchResult.permissions
        );
      case 'inbox':
        return this.inboxesQuery.active.permissions;
      case 'inboxIndexing':
        return this.archivesQuery.getArchive(this.inboxTargetArchiveId)
          .permissions;
      default:
        throw new Error('Unsupported display mode.');
    }
  }

  /** @inheritdoc */
  get isDirty(): boolean {
    return (
      (typeof this.rightSidebar !== 'undefined' && this.rightSidebar.isDirty) ||
      (typeof this.tableFieldGrid !== 'undefined' &&
        this.tableFieldGrid.isDirty)
    );
  }

  /**
   * Is the user disallowed from downloading the document.
   *
   * @returns True when download is not allowed.
   */
  get isDownloadDisabled() {
    // todo this method of preventing type errors might need some work.
    if (!this.document.hasOwnProperty('permissions')) {
      return true;
    }
    assertTypeByKey<SearchResult>(
      this.document,
      'secureId',
      'string',
      'This method requires the document be a SearchResult.'
    );
    return !this.document.permissions.exportDocuments;
  }

  /**
   * Is the user disallowed from using KFI.
   *
   * @returns True if the user can use KFI.
   */
  get isKfiDisabled(): boolean {
    return (
      this.isDocumentLoading ||
      !this.appQuery.indexerSidebarOpen ||
      !this.documentPermissions.modifyData
    );
  }

  /**
   * Is there more than one document open?
   *
   * @returns True if there are more than one document requested for open.
   */
  get isMultipleDocuments() {
    return !!this.requestedDocuments && this.requestedDocuments.size > 1;
  }

  /**
   * Is a next open document available.
   *
   * @returns True if there is a higher index document available.
   */
  get isNextAvailable() {
    return (
      typeof this.viewIndex === 'number' &&
      !!this.requestedDocuments &&
      this.viewIndex <= this.requestedDocuments.size - 2
    );
  }

  /**
   * Is a previous open document available.
   *
   * @returns True if there is a lower index document available.
   */
  get isPreviousAvailable() {
    return typeof this.viewIndex === 'number' && this.viewIndex > 0;
  }

  /**
   * Is there more than one document open?
   *
   * @returns True if there are more than one document open.
   */
  get multipleDocumentsOpen(): boolean {
    return this.requestedDocuments.size > 1;
  }

  /**
   * Parent document ID for a revision controlled document.
   *
   * This is the document ID of the version of the document that exists
   * in the version controlled archive **not** a revision in the `Versions` archive.
   *
   * @returns The document secure ID.
   */
  get revisionParentDocumentId() {
    if (
      this.displayMode === 'archive' ||
      this.displayMode === 'archiveSession' ||
      this.displayMode === 'task'
    ) {
      // We are looking at a document that isn't a previous revision so return the document id from the url.
      return this.requestedDocuments.get(
        this.viewIndex
      ) as ArchiveSearchRequestedDocument;
    } else if (this.displayMode === 'archiveRevision') {
      // We are looking at a previous version of the document so we need to get the id from the requested document object.
      return (
        this.requestedDocuments.get(
          this.viewIndex
        ) as ArchiveRevisionRequestedDocument
      ).docId;
    } else {
      throw new Error(
        'Getting the revision parent secure id is not supported for this display mode.'
      );
    }
  }

  /**
   * Parent document secure ID for a revision controlled document.
   *
   * This is the secure ID of the version of the document that exists
   * in the version controlled archive **not** a revision in the `Versions` archive.
   *
   * @returns The document secure ID.
   */
  get revisionParentSecureId() {
    if (
      this.displayMode === 'archive' ||
      this.displayMode === 'archiveSession' ||
      this.displayMode === 'task'
    ) {
      // We are looking at a document that isn't a previous revision so just return the secure id.
      return this.documentAsSearchResult.secureId;
    } else if (this.displayMode === 'archiveRevision') {
      // We are looking at a previous version of the document so we need to get the secure id from the requested document object.
      return (
        this.requestedDocuments.get(
          this.viewIndex
        ) as ArchiveRevisionRequestedDocument
      ).secureId;
    } else {
      throw new Error(
        'Getting the revision parent secure id is not supported for this display mode.'
      );
    }
  }

  /**
   * Should the locked document menu be shown.
   *
   * @returns True if the locked document menu should be shown.
   */
  get shouldShowLockedDocumentMenu(): boolean {
    return (
      this.displayMode === 'archive' ||
      this.displayMode === 'task' ||
      this.displayMode === 'archiveSession'
    );
  }

  /**
   * Should the navigation toggle be shown.
   *
   * @returns If the control should be shown.
   */
  get shouldShowNavigationToggle() {
    return !this.isGuest && !this.useCompactLayout;
  }

  /**
   * Should PDF preview be shown during import.
   *
   * @returns True if the preview should be shown.
   */
  get showArchiveImportPreview(): boolean {
    assert(
      this.displayMode === 'archiveImport',
      'The display mode must be ArchiveImport.'
    );

    return INTERNAL_VIEWER_CLIENT_SUPPORTED_FILE_TYPES.some((extension) =>
      (
        this.requestedDocuments.get(
          this.viewIndex
        ) as ArchiveImportRequestedDocument
      )
        .toLowerCase()
        .endsWith(extension)
    );
  }

  /**
   * Should the DXC menu component load.
   *
   * The component itself handles hiding if there are no sources.
   *
   * @returns True if the DXC menu should load.
   */
  get showDXCMenu() {
    return (
      this.displayMode === 'archive' ||
      this.displayMode === 'archiveSession' ||
      this.displayMode === 'archiveImport' ||
      this.displayMode === 'task' ||
      this.displayMode === 'inboxIndexing'
    );
  }

  /**
   * Should the save button be displayed.
   *
   * @returns True if the save button should be displayed.
   */
  get showSave(): boolean {
    return (
      this.displayMode !== 'inbox' &&
      !this.rightSidebar?.indexer?.indexerForm.disabled
    );
  }

  /**
   * Should the save all button be displayed.
   *
   * @returns True if the save all button should be displayed.
   */
  get showSaveAll(): boolean {
    return this.displayMode === 'archiveImport' && this.isMultipleDocuments;
  }

  /**
   * Should the toggle indexer button be displayed.
   *
   * @returns True if the toggle indexer button should be displayed.
   */
  get showToggleIndexer(): boolean {
    return this.displayMode !== 'inbox';
  }

  /**
   * Style attached to the viewer container element.
   *
   * Height will adjust for the presence of table field controls.
   *
   * @returns CSS rule for th esize of the viewer container.
   */
  get viewerContainerStyle() {
    // Adjust for (2) toolbars.
    const heightAdjustment = this.breakpointObserver.isMatched(
      '(min-width: 600px)'
    )
      ? '128px'
      : '120px';
    const height = `calc(${
      this.showTableFieldGrid ? 'calc(50vh + 12px)' : '100vh'
    } - ${heightAdjustment})`;

    return {
      height,
    };
  }

  /**
   * Kfi mouse click event handler.
   *
   * @param event - Mouse Event.
   */
  kfiContextMenu = (event: MouseEvent) => {
    // Prevent the default context menu from showing up on right click.
    event.preventDefault();
  };

  /**
   * Kfi mouse movement event.
   *
   * @param event - Mouse Event.
   */
  kfiMouseMove = (event: MouseEvent) => {
    if (!this.canvas) return;
    const rect = this.canvas.getBoundingClientRect(),
      x = event.clientX - rect.left,
      y = event.clientY - rect.top;

    this.isShiftDown = event.shiftKey;

    if (this.kfiRectangleDiv && this.isMouseDown && this.dragActive) {
      this.initializeKfiRectangleDiv(x, y);
    }

    const context = this.canvas.getContext('2d') as CanvasRenderingContext2D;

    let layerIndex = 0,
      textLayer: TextLayer;
    while ((textLayer = this.textLayers[layerIndex])) {
      // Add a single rect to path:
      context.beginPath();
      context.rect(textLayer.x, textLayer.y, textLayer.width, textLayer.height);

      if (
        context.isPointInPath(x, y) &&
        !textLayer.highlighted &&
        !this.dragActive
      ) {
        textLayer.highlighted = true;
        this.currentTextLayer = textLayer;
        this.updateHoverTextLocation(x, y);
        textLayer.selected = true;
        if (this.isMouseDown || this.isShiftDown) {
          this.updateHoverText(textLayer.text, textLayer.line);
        }
      } else if (
        !event.shiftKey &&
        !context.isPointInPath(x, y) &&
        !this.isMouseDown &&
        !this.dragActive
      ) {
        textLayer.highlighted = false;
      } else if (
        event.shiftKey &&
        !context.isPointInPath(x, y) &&
        !textLayer.multiSelected &&
        !this.dragActive
      ) {
        this.kfiShiftHoverText = '';
        textLayer.highlighted = false;
      } else if (
        !textLayer.dragSelect &&
        this.dragActive &&
        this.doesKfiDragHighlight(textLayer)
      ) {
        textLayer.dragSelect = true;
        this.updateHoverTextLocation(x, y);
      } else if (
        textLayer.dragSelect &&
        !this.doesKfiDragHighlight(textLayer)
      ) {
        textLayer.dragSelect = false;
        textLayer.highlighted = false;
      }
      layerIndex++;
    }
  };

  ngOnDestroy(): void {
    this.addOrRemoveKfiOverlayClass('remove');
    if (this.kfiActive) {
      this.onKfiToggle();
    }

    // Unregister component from the UI.
    this.ui.unregister(this);
  }

  ngOnInit(): void {
    this.listenForUserSettings();
    // Collect the requested document routing information.
    this.routerQuery
      .selectParams([
        'dbId',
        'archiveId',
        'searchId',
        'inboxId',
        'taskId',
        'documentId',
        'sessionId',
        'viewIndex',
      ])
      .pipe(
        untilDestroyed(this),
        first(),
        map(
          ([
            databaseId,
            archiveId,
            searchId,
            inboxId,
            taskId,
            documentId,
            sessionId,
            viewIndex,
          ]) => {
            this.databaseId = Number(databaseId);
            this.archiveId = Number(archiveId);
            this.searchId = Number(searchId);
            this.inboxId = Number(inboxId);
            this.taskId = taskId;
            this.sessionId = sessionId;
            // TODO decide if this needs to be observable instead
            this.isImport = this.routerQuery.getData('isImport') ?? false;
            if (typeof sessionId !== 'undefined') {
              // get the initial set of documents
              const requestedDocuments = this.archiveCacheQuery
                .getSession(sessionId)
                .documents.map((d) => d.id);
              this.requestedDocuments = new ArchiveSearchRequestedDocumentMap(
                requestedDocuments
              );
            } else {
              this.requestedDocuments =
                this.archiveId > 0 && !this.isImport
                  ? documentId.split(',').map(Number)
                  : documentId.split(',');
            }

            this.viewIndex = Number(viewIndex ?? 0);
          }
        )
      )
      .subscribe();
    // Subscribe to compact view state.
    this.layout.useCompactLayout$
      .pipe(untilDestroyed(this))
      .subscribe((useCompactLayout) => {
        this.useCompactLayout = useCompactLayout;
      });

    this.listenForAutoSave();

    // Register as active UI component.
    this.ui.register(this);
  }

  /**
   * Handler for click of open actions menu.
   *
   * @param $event Event.
   */
  onClickActionMenu($event: any) {
    this.actionMenuComponent.openMenu($event, [this.document as any]);
  }

  /** Handler for click of back button. */
  onClickBack() {
    assert(
      this.isPreviousAvailable,
      'Requested view index can not be less than 0.'
    );
    if (this.autoSave) {
      this.autoSave$().subscribe((success) => {
        if (!success) {
          return;
        }

        if (
          this.displayMode === 'archiveImport' ||
          this.displayMode === 'inboxIndexing'
        ) {
          // remove the document that was just imported
          this.requestedDocuments.delete(this.viewIndex);
        } else {
          // otherwise increment the index to move to the next doc
          this.viewIndex--;
        }

        this.navigateToDocument().subscribe((navigationSuccessful) => {
          if (!navigationSuccessful) {
            this.viewIndex--;
          }
        });
      });
    } else {
      this.viewIndex--;
      this.navigateToDocument().subscribe((navigationSuccessful) => {
        if (!navigationSuccessful) {
          this.viewIndex++;
        }
      });
    }
  }

  /**
   * Handler for the cancel indexing button click event.
   */
  onClickCancelIndexing(): void {
    if (this.displayMode !== 'inboxIndexing') {
      this.logger.error('Cancel indexing can only be used on inbox indexing.');
      return;
    }
    this.logger.debug('Cancel indexing clicked.');
    this.tableFieldUIService.clear();
    from(
      this.router.navigate(
        [
          'db',
          this.databaseId,
          'inbox',
          this.inboxId,
          'document',
          this.requestedDocuments.toString(),
          'view',
          this.viewIndex,
        ],
        {
          queryParams: { targetArchiveId: undefined },
          queryParamsHandling: 'merge',
        }
      )
    ).subscribe((routedSuccessfully) => {
      if (routedSuccessfully) {
        this.inboxTargetArchiveId = 0;
      }
    });
  }

  /** Handler for click of download. */
  onClickDownloadDocument() {
    assertTypeByKey<SearchResult>(
      this.document,
      'secureId',
      'string',
      'This action requires the document be a SearchResult.'
    );
    const downloadFileUrl = this.documentProvider.getArchiveDownloadUrl(
      this.databaseId,
      this.archiveId,
      this.document.id,
      this.auth.user.token,
      this.document.secureId
    );
    window.open(downloadFileUrl, '_blank');
  }

  /** Handler for click of forward button. */
  onClickForward() {
    assert(
      this.isNextAvailable,
      'Requested view index exceeds the size of the list of open documents.'
    );
    if (this.autoSave) {
      this.autoSave$().subscribe((success) => {
        if (!success) {
          return;
        }
        if (
          this.displayMode === 'archiveImport' ||
          this.displayMode === 'inboxIndexing'
        ) {
          // remove the document that was just imported
          this.requestedDocuments.delete(this.viewIndex);
        } else {
          // otherwise increment the index to move to the next doc
          this.viewIndex++;
        }
        this.navigateToDocument().subscribe((navigationSuccessful) => {
          if (!navigationSuccessful) {
            this.viewIndex--;
          }
        });
      });
    } else {
      this.viewIndex++;
      this.navigateToDocument().subscribe((navigationSuccessful) => {
        if (!navigationSuccessful) {
          this.viewIndex--;
        }
      });
    }
  }

  /** Handler for click return to search. */
  onClickReturnToSearch() {
    const navigate = (): Observable<boolean> => {
      return this.routerQuery
        .selectParams(['dbId', 'archiveId', 'searchId', 'inboxId', 'taskId'])
        .pipe(
          first(),
          switchMap(([databaseId, archiveId, searchId, inboxId, taskId]) => {
            if (taskId) {
              return from(
                this.router.navigate(
                  ['db', databaseId, 'archive', archiveId, 'task', taskId],
                  {
                    queryParamsHandling: 'merge',
                  }
                )
              );
            } else if (inboxId) {
              // Return to inbox.
              return from(
                this.router.navigate(['db', databaseId, 'inbox', inboxId], {
                  queryParams: {
                    targetArchiveId: undefined, // ensure param is removed to avoid odd behavior on opening another document
                  },
                  queryParamsHandling: 'merge',
                })
              );
            } else if (
              this.displayMode === 'archiveImport' ||
              this.displayMode === 'archiveSession' ||
              this.displayMode === 'archiveRevision'
            ) {
              return from(
                this.router.navigate(['db', databaseId, 'archive', archiveId], {
                  queryParamsHandling: 'merge',
                })
              );
            } else {
              // Return to search.
              const searchPrompts = this.searchesQuery.currentSearchPrompts;
              return this.searchUIService.redirectToSearch$(
                databaseId,
                archiveId,
                searchId,
                searchPrompts,
                'merge'
              );
            }
          })
        );
    };
    if (this.autoSave) {
      this.autoSave$().subscribe((success) => {
        if (!success) {
          return;
        }

        navigate().subscribe({
          next: (navigationSuccessful) => {
            if (navigationSuccessful) {
              this.tableFieldUIService.clear();
            }
          },
        });
      });
    } else {
      if (!this.tableFieldGrid?.isDirty) {
        this.tableFieldUIService.clear();
      }
      navigate().subscribe({
        next: (navigationSuccessful) => {
          if (navigationSuccessful) {
            this.tableFieldUIService.clear();
          }
        },
      });
    }
  }

  /**
   * Handler for the save button click event.
   */
  onClickSave(): void {
    if (this.displayMode === 'inbox') {
      this.logger.error('Saving inbox document changes are not yet supported.');
      return;
    }

    if (this.rightSidebar.indexer.indexerForm.invalid) {
      this.logger.warn('Indexer form has errors.');
      return;
    }

    // We're dealing with an archive document if we get here.
    this.saveInProgress = true;

    const save$ = this.createSave$().pipe(
      finalize(() => {
        this.saveInProgress = false;
      })
    );
    save$.subscribe({
      next: ({ documentId, documentSecureId }) => {
        // this.notify.success('Field data updated successfully.');

        if (this.displayMode === 'inboxIndexing') {
          // Re-fetch inbox to update files in state cache.
          this.inboxesService
            .getById(this.inboxId)
            .pipe(first())
            .subscribe(() => {
              this.notify.success('INBOX_INDEX_SUCCESSFUL');
              const filenamesToRemove = [
                this.requestedDocuments.get(
                  this.viewIndex
                ) as InboxRequestedDocument,
              ];
              this.viewIndex = Math.min(
                this.viewIndex,
                this.requestedDocuments.size - 2
              );
              this.onRefreshSearch({
                filenames: filenamesToRemove,
              });
            });
        }

        if (this.displayMode === 'archiveImport') {
          this.notify.success('DOCUMENT_IMPORTED_SUCCESSFULLY');
          const isNewSession = !this.archiveCacheSessionId;

          if (isNewSession) this.archiveCacheSessionId = uuid();
          this.archiveCacheService.createOrUpdateSession(
            this.archiveCacheSessionId,
            [
              {
                id: documentId,
                secureId: documentSecureId,
                archiveId: this.archiveId,
              },
            ]
          );

          const openDocuments = [...this.requestedDocuments.values()];
          openDocuments.splice(
            openDocuments.indexOf(this.documentAsArchiveImportFile.filename),
            1
          );

          if (isNewSession) {
            const url = this.router.serializeUrl(
              this.router.createUrlTree([
                'db',
                this.databaseId,
                'archive',
                this.archiveId,
                'document',
                'session',
                this.archiveCacheSessionId,
              ])
            );

            window.open(url, '_blank');
          }

          // Go back if there are no more documents.
          if (openDocuments.length === 0) {
            this.onClickReturnToSearch();
            return;
          }

          this.router.navigate(
            [
              'db',
              this.databaseId,
              'archive',
              this.archiveId,
              'import',
              // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
              openDocuments.join(),
            ],
            { queryParamsHandling: 'merge' }
          );
        }
      },
      error: (error: UserFriendlyError) => {
        this.notify.error(error);
      },
    });
  }

  /** Handler for the save all button click event. */
  onClickSaveAll(): void {
    if (this.displayMode !== 'archiveImport') {
      this.logger.error('Save all is only available for archive imports.');
      return;
    }

    if (this.rightSidebar.indexer.indexerForm.invalid) {
      this.logger.warn('Indexer form has errors.');
      return;
    }

    this.logger.debug('Imporing all documents.', this.requestedDocuments);
    this.saveInProgress = true;

    const imports$ = (
      [...this.requestedDocuments.values()] as ArchiveImportRequestedDocument[]
    ).map((filename) => {
      assert(typeof filename === 'string', 'Filename must be a string.');
      const updateSession = new DocumentUpdateSession(
        false,
        false,
        this.rightSidebar?.indexer?.isDirty ?? false,
        this.tableFieldGrid?.isDirty ?? false
      );
      return this.archiveService.api
        .import(this.databaseId, this.archiveId, [filename], updateSession)
        .pipe(
          map((importDocuments) => importDocuments[0]),
          switchMap((importedDocument) => {
            assertExists(
              importedDocument,
              'Document object with id and secure id must be provided to save.'
            );

            const saveChain$ = this.createSaveChain(
              importedDocument.id,
              importedDocument.secureId,
              updateSession
            );

            return saveChain$.pipe(
              switchMap(() =>
                of({
                  documentId: importedDocument.id,
                  documentSecureId: importedDocument.secureId,
                })
              )
            );
          })
        );
    });

    combineLatest(imports$)
      .pipe(finalize(() => (this.saveInProgress = false)))
      .subscribe({
        next: (documents) => {
          this.notify.success('DOCUMENT_IMPORTED_SUCCESSFULLY');
          const archiveCacheSessionId = uuid();

          this.archiveCacheService.createOrUpdateSession(
            archiveCacheSessionId,
            documents.map((document) => ({
              id: document.documentId,
              secureId: document.documentSecureId,
              archiveId: this.archiveId,
            }))
          );
          this.router.navigate(
            [
              'db',
              this.databaseId,
              'archive',
              this.archiveId,
              'document',
              'session',
              archiveCacheSessionId,
            ],
            {
              replaceUrl: true,
            }
          );
        },
        error: (error: UserFriendlyError) => {
          this.notify.error(error);
        },
      });
  }

  /** Handler for click of toggle indexer. */
  onClickToggleIndexer() {
    this.application.toggleIndexerSidebarOpen();
    if (this.kfiActive) {
      this.onKfiToggle();
      this.changeDetectorReference.detectChanges();
    }
  }

  /**
   * Handler for the on DXC source selected event.
   *
   * @param source DXC Source.
   */
  onDXCMatchSelected(source: DXCSource): void {
    assertExists(this.rightSidebar, 'Right sidebar must exist.');
    assertExists(this.rightSidebar.indexer, 'Indexer must exist.');
    this.rightSidebar.indexer.runDataXChange(source);
  }

  /**
   * Handler for the document loading change event.
   *
   * @param documentIsLoading Whether the document is currently loading.
   */
  onDocumentLoadingChange(documentIsLoading: boolean) {
    if (this.kfiActive && this.isDocumentLoading && !documentIsLoading) {
      this.updateKfi().subscribe();
    }

    if (
      this.displayMode === 'archive' &&
      this.documentAsSearchResult.contentSearch.hits > 0 &&
      this.isDocumentLoading &&
      !documentIsLoading
    ) {
      this.logger.debug(
        'Document is no longer loading and content search terms are present.'
      );
      this.pdfviewer.findInDocument(
        this.documentAsSearchResult.contentSearch.terms[0], // TODO: do we support multiple terms in the s9 viewer somehow?
        false,
        true,
        true
      );
    }
    this.isDocumentLoading = documentIsLoading;
    // Trigger change detection so that KFI button is correctly re-enabled.
    this.changeDetectorReference.detectChanges();
  }

  /**
   * Handler for the indexer field focused event.
   *
   * @param field Field that was focused.
   */
  onIndexerFieldFocused(field: Field) {
    // Stop grid editting if there is currently a table field.
    this.tableFieldGrid?.grid.api.stopEditing();
    if (this.kfiActive) {
      this.currentField = field;
      this.highlightByRegularExpression(field);
    }
  }

  /**
   * Keyfree indexing on click.
   *
   * @param forceOcr - Force toggle on ocr.
   */
  onKfiToggle(forceOcr?: boolean): void {
    if (forceOcr && !this.forceOcr && !this.useLocalOcr) {
      this.forceOcr = true;
      this.removeAnnotationCanvases();
      this.removeRegExHighlightCanvases();
      this.listenForZoomChange();
      this.updateKfi(true).subscribe(() => {
        this.setFieldFocus(true);
      });
      return;
    }
    this.forceOcr = false;
    this.kfiActive = !this.kfiActive;
    if (this.kfiActive) {
      this.listenForZoomChange();
      this.addOrRemoveKfiOverlayClass('add');
      this.kfiPageChangeSubscription = this.kfiPageChangeSubject
        .pipe(untilDestroyed(this), debounceTime(1500))
        .subscribe(() => {
          // Update keyfree indexing on page change.
          this.removeAnnotationCanvases();
          this.removeRegExHighlightCanvases();
          this.updateKfi(this.forceOcr).subscribe(() => {
            const field = this.setFieldFocus(false);
            this.highlightByRegularExpression(field);
          });
        });
      this.updateKfi().subscribe(() => {
        this.setFieldFocus(true);
        this.redrawInterval = setInterval(this.redrawKfi, 30);
      });
    } else {
      this.addOrRemoveKfiOverlayClass('remove');
      // Ensure the scale change subscription is unsubscribed if it exists.
      this.scaleChangeSubscription?.unsubscribe();
      this.kfiPageChangeSubscription.unsubscribe();
      this.rightSidebar.indexer.lastFocusedField = undefined;
      this.hideHoverText();
      clearInterval(this.redrawInterval);
      this.removeAnnotationCanvases();
      this.deleteAnnotationCanvas();
      this.removeRegExHighlightCanvases();
      const pageElement = this.pdfviewer.pageElement;
      const hoverText = pageElement.querySelector(
        '#kfi_hover_text'
      ) as HTMLElement;
      if (!hoverText) return;
      hoverText.remove();
      for (const element of this.pdfviewer.pageElement.querySelectorAll(
        '.kfi-rectangle'
      )) {
        element.remove();
      }
    }
    this.logger.debug('Toggling Keyfree indexing.');
  }

  /** Hander for failure to load document. */
  onLoadFailure() {
    this.notify.warning('DOCUMENT_NOT_FOUND_SHOW_SEARCH_RESULTS');
    // Remove the current document and refresh.
    const restrictViewIndex = () => {
      this.viewIndex = Math.min(
        this.viewIndex,
        this.requestedDocuments.size - 2
      );
    };
    if (this.displayMode === 'archive') {
      const idsToRemove = [
        this.requestedDocuments.get(
          this.viewIndex
        ) as ArchiveSearchRequestedDocument,
      ];
      restrictViewIndex();
      this.onRefreshSearch({
        ids: idsToRemove,
      });
    } else {
      const filenamesToRemove = [
        this.requestedDocuments.get(this.viewIndex) as InboxRequestedDocument,
      ];
      restrictViewIndex();
      this.onRefreshSearch({
        filenames: filenamesToRemove,
      });
    }
    // todo we will need to handle when the requestedDocuments.documents is a map.
  }

  /**
   * Handler for open documents event, ONLY for opening documents in the external viewer.
   *
   * @param openRequest Request to open documents.
   */
  onOpenInboxDocuments(openRequest?: InboxDocumentOpenRequest): void {
    this.logger.debug(
      'Opening selected documents in external viewer.',
      openRequest
    );
    assertExists(openRequest?.inboxFiles);
    // Use an external viewer session.
    const session: InboxSession = {
      database: this.databasesQuery.activeId,
      documents: openRequest?.inboxFiles.map((inboxFile) =>
        createInboxSessionDocumentFromInboxFile(
          inboxFile,
          this.inboxesQuery.activeId
        )
      ),
    };

    this.viewerService.createInboxSession(session).subscribe({
      next: (sessionId) => {
        this.viewerService
          .openViewerSession(sessionId, this.appQuery.alwaysOpenNewTab)
          .subscribe(() => {
            this.logger.debug('Viewer closed.');
            this.onRefreshSearch({});
          });
      },
      error: (error: UserFriendlyError) => {
        error.i18n = 'ERROR_CREATE_SESSION_MSG';
        this.notify.error(error);
      },
    });
  }

  /**
   * Handler for open documents event, ONLY for opening documents in the external viewer.
   *
   * @param openRequest Request to open documents.
   */
  onOpenSearchResults(openRequest?: SearchResultDocumentOpenRequest): void {
    assertExists(openRequest?.searchResults);
    this.searchesQuery.searchRouteParams$
      .pipe(first())
      .subscribe(([_databaseId, archiveId, searchId]) => {
        assertExists(archiveId);
        assertExists(searchId);

        this.createOpenDocumentSession(
          archiveId,
          searchId,
          openRequest?.searchResults
        );
      });
  }

  /**
   * Handler for open task view documents event, ONLY for opening documents in the external viewer.
   *
   * @param openRequest Request to open documents.
   */
  onOpenTaskViewDocuments(openRequest?: SearchResultDocumentOpenRequest): void {
    assertExists(openRequest?.searchResults);
    this.viewerService
      .openSelectedArchiveDocuments(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        0,
        [],
        openRequest.searchResults,
        this.appQuery.alwaysOpenNewTab
      )
      .subscribe({
        next: () => {
          this.logger.debug('Viewer closed.');
          this.onRefreshSearch({ forceReload: true });
        },
        error: (error: UserFriendlyError) => {
          error.i18n = 'ERROR_CREATE_SESSION_MSG';
          this.notify.error(error);
        },
      });
  }

  /**
   * Handler for the page change event.
   *
   *@param pageNumber New page number.
   */
  onPageChange(pageNumber: number): void {
    this.pageNumber = pageNumber;
    // Change detection in this event context isn't automatically running for some reason
    // so we need to force it here.
    this.changeDetectorReference.detectChanges();
    this.kfiPageChangeSubject.next(pageNumber);
  }

  /**
   * Handler for refresh search.
   *
   * @param event Event.
   * @param event.ids Document Ids deleted.
   * @param event.filenames Files deleted.
   * @param event.forceReload Reload will be forced for the same route.
   */
  onRefreshSearch(event: {
    /** Removed document filenames. */ filenames?: string[];
    /** Force reload. */ forceReload?: boolean;
    /** Removed document Ids. */ ids?: number[];
  }) {
    // If the document is no longer here after an action (move/delete),
    // remove it from the session, or return to search results.
    if (event?.ids?.length) {
      // Remove documents by Id.
      this.logger.debug('Removing Ids: ', event.ids);
      // Remove any Ids that were targetted.
      for (let id of event.ids) this.requestedDocuments.deleteValue(id);
      // If there are no documents left, just return to the search.
      if (this.requestedDocuments.size === 0) {
        this.logger.debug('No remaining documents, return to search.');
        this.onClickReturnToSearch();
        return;
      }
    }
    if (event?.filenames?.length) {
      // Ensure there are no encoded filenames in the event.
      event.filenames = event.filenames.map((f) => decodeURIComponent(f));
      // Remove documents by filename.
      this.logger.debug('Removing filenames: ', event.filenames);
      // Remove any filenames that were targetted.
      for (let filename of event.filenames)
        this.requestedDocuments.deleteValue(filename);

      // If there are no documents left, just return to the search.
      if (this.requestedDocuments.size === 0) {
        this.logger.debug('No remaining documents, return to search.');
        this.onClickReturnToSearch();
        return;
      }
    }

    // Update selection, ensure we don't select past the end of the list.
    this.viewIndex = Math.min(this.requestedDocuments.size - 1, this.viewIndex);
    // Reload the route.
    this.navigateToDocument(!!event?.forceReload).subscribe();
  }

  /**
   * Handler for related search selected event.
   *
   * @param search Selected search.
   */
  onRelatedSearchSelected(search: Search): void {
    this.logger.debug('Related search selected: ', search);
    assertExists(this.rightSidebar.indexer, 'The indexer must exist.');
    this.searchesQuery.searchPromptParams$
      .pipe(take(1))
      .subscribe((querySearchPrompts) => {
        const prompts = search.parameters.map((parameter) => {
          const searchPrompt = {
            id: parameter.id,
            prompt: parameter.prompt,
            value: '',
          };

          // If the setting is on just use the values from the url.
          if (this.appQuery.usePreviousSearchCriteriaInRelatedSearch) {
            // Check if the prompt is in the url search prompts
            const queryPrompt = querySearchPrompts.find(
              (prompt) => prompt.id === parameter.id
            );
            if (queryPrompt) {
              searchPrompt.value = queryPrompt.value;
            }

            return searchPrompt;
          }

          // The usePreviousSearchCriteriaInRelatedSearch setting is off if we get here.

          // check if the field is in the indexer.
          const indexerField = this.rightSidebar.indexer.indexerFields.find(
            (field) => field.id === parameter.fieldId
          );
          if (indexerField) {
            // Grab the first mv field form control or the single field form control.
            const formControl = indexerField.multiValue
              ? this.rightSidebar.indexer.getMultiValueFormArray(
                  parameter.fieldId
                ).controls[0]
              : this.rightSidebar.indexer.indexerForm.get(
                  `${parameter.fieldId}`
                );
            assertExists(
              formControl,
              'A form control for the field must exist.'
            );
            searchPrompt.value = formControl.value ?? '';
          }

          return searchPrompt;
        });

        this.searchUIService.redirectToSearch(
          this.databaseId,
          this.archiveId,
          search.id,
          prompts
        );
      });
  }

  /**
   * Handler for the user action executed event.
   *
   * @param event User action event.
   */
  onUserActionExecuted(event: UserActionExecutedEvent): void {
    this.logger.debug(
      'User action executed. The document will be closed.',
      event
    );

    let shouldContinue$: Observable<boolean> = of(true);

    if (this.isDirty) {
      if (this.appQuery.viewerAutoSave) {
        // Save the document changes
        this.saveInProgress = true;

        const save$ = this.createSave$().pipe(
          finalize(() => {
            this.saveInProgress = false;
          })
        );
        shouldContinue$ = save$.pipe(
          catchError(() => of(false)), // Do not continue if save failed.
          map(() => true)
        );
      } else {
        // Confirm changes.
        const confirmDialogData: ConfirmationDialogData = {
          cancelActionText: 'NO',
          confirmActionText: 'YES',
          contents: 'CONFIRM_DISCARD_CHANGES_MSG',
          title: 'DISCARD_CHANGES',
        };
        shouldContinue$ = this.dialog
          .open(ConfirmationDialogComponent, {
            data: confirmDialogData,
          })
          .afterClosed()
          .pipe(map((result) => !!result));
      }
    }

    shouldContinue$ = shouldContinue$.pipe(
      switchMap((shouldContinue) => {
        if (!shouldContinue) {
          throw new Error('User action execution was cancelled by the user.');
        }
        return event.runAction().pipe(
          catchError(() => of(false)),
          map(() => true)
        );
      })
    );

    shouldContinue$.subscribe((shouldContinue) => {
      if (!shouldContinue) {
        this.logger.warn('GA action execution was cancelled by the user.');
        return;
      }

      if (this.isDirty) {
        // If we get here it means auto save wasn't on and that the user agreed to discard changes.
        // Force the indexer to appear clean to prevent a second discard prompt from the dirty guard.
        this.rightSidebar?.indexer?.indexerForm.markAsPristine();
        // Force any table field to appear clearn to prevent a second discard prompt form the dirty guard.
        this.tableFieldGrid?.forceSetGridDirty(false);
      }

      // Remove the current document from the open documents since the action was executed on it.
      this.requestedDocuments.delete(this.viewIndex);

      // Go back if there are no more documents.
      if (this.requestedDocuments.size === 0) {
        this.onClickReturnToSearch();
        return;
      }

      // Otherwise set the new set of open documents and reload.
      this.navigateToDocument().subscribe();
    });
  }

  private addOrRemoveKfiOverlayClass(classChange: 'add' | 'remove'): void {
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    switch (classChange) {
      case 'add':
        overlayContainerElement.classList.add('kfi-active');
        break;
      case 'remove':
        overlayContainerElement.classList.remove('kfi-active');
        break;
    }
  }

  private autoSave$(): Observable<boolean> {
    if (this.displayMode === 'inbox') {
      this.logger.warn('Saving inbox document changes are not yet supported.');
    }
    if (!this.isDirty) {
      this.logger.debug(
        'Dirty function was called but the component is not dirty.'
      );
      // The component isn't dirty so there is no need to run the dirty function.
      return of(true);
    }

    if (this.rightSidebar.indexer.indexerForm.invalid) {
      this.logger.warn('Indexer form has errors.');
      return of(false);
    }

    const save$ = this.createSave$();
    return save$.pipe(
      map(() => {
        this.notify.success('SAVE_SUCCESS');
        return true;
      }),
      catchError((error: UserFriendlyError) => {
        this.notify.error(error);
        return of(false);
      })
    );
  }

  private clearRegExHighlights() {
    for (const textLayer of this.textLayers) {
      textLayer.regExHighlighted = false;
    }
    this.regexHighlightContext.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
  }

  /**
   * Create the canvas in to draw kfi annotations on.
   *
   */
  private createAnnotationCanvas() {
    const pageElement = this.pdfviewer.pageElement;
    this.annotationCanvas = document.createElement('canvas');
    this.annotationCanvas.id = 'anno_page_' + this.pageNumber;
    this.canvas = pageElement.querySelector(
      '.canvasWrapper [role="presentation"]'
    ) as HTMLCanvasElement;
    if (!this.canvas) return;
    this.annotationCanvas.height = this.canvas.clientHeight || 0;
    this.annotationCanvas.width = this.canvas.clientWidth || 0;
    this.annotationCanvas.style.position = 'absolute';
    this.annotationCanvas.style.top = '0';
    this.annotationCanvas.style.left = '0';
    this.annotationCanvas.style.zIndex = '100';
    pageElement.append(this.annotationCanvas);
    this.annotationContext = this.annotationCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D;
    this.annotationCanvas.addEventListener('mouseup', this.mouseUp);
    this.annotationCanvas.addEventListener('mousedown', this.mouseDown);
    this.annotationCanvas.addEventListener('mousemove', this.kfiMouseMove);
    this.annotationCanvas.addEventListener('contextmenu', this.kfiContextMenu);
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  }

  /**
   * Creates the html for the floating div that shows the user the text and selected field.
   *
   * @returns - Hovertext element.
   */
  private createHoverText(): HTMLDivElement {
    const pageElement = this.pdfviewer.pageElement;
    const hoverText = document.createElement('div');
    hoverText.id = 'kfi_hover_text';
    hoverText.style.borderRadius = '5px';
    hoverText.style.boxShadow = '0px 3px 5px lightgray';
    //Create plus icon for shift key press
    const plusDiv = document.createElement('div');
    plusDiv.id = 'kfi_hover_plus';
    plusDiv.style.width = '18px';
    plusDiv.style.height = '18px';
    plusDiv.style.borderRadius = '50%';
    plusDiv.style.backgroundColor = 'rgb(96, 125, 139)';
    plusDiv.style.float = 'left';
    plusDiv.style.position = 'absolute';
    plusDiv.style.top = '-9px';
    plusDiv.style.left = '-9px';
    plusDiv.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#FFFFFF"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>';
    plusDiv.hidden = true;
    hoverText.append(plusDiv);
    //Create header within hovertext div for field name
    const header = document.createElement('div');
    header.id = 'hover_header';
    header.style.color = 'white';
    header.style.minWidth = '100px';
    header.style.backgroundColor = '#607d8b';
    header.style.padding = '5px';
    header.style.borderRadius = '5px 5px 0 0';
    hoverText.append(header);
    //Create body withing hovertext div for field value(s)
    const body = document.createElement('div');
    body.id = 'hover_content';
    body.style.padding = '5px';
    body.style.whiteSpace = 'pre-line';
    hoverText.append(body);
    pageElement.prepend(hoverText);
    return hoverText;
  }

  private createKfiDragRectangle(event: MouseEvent) {
    const rect = this.annotationCanvas.getBoundingClientRect(),
      x = event.clientX - rect.left,
      y = event.clientY - rect.top;
    const context = this.annotationCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D;
    if (
      !context.isPointInPath(x, y) &&
      !this.textLayers.some((layer) => layer.highlighted)
    ) {
      this.dragActive = true;
      this.kfiRectangle.startX = x;
      this.kfiRectangle.startY = y;
      this.kfiRectangleDiv = document.createElement('div') as HTMLDivElement;
      this.kfiRectangleDiv.className = 'kfi-rectangle';
      this.kfiRectangleDiv.style.left = x + 'px';
      this.kfiRectangleDiv.style.top = y + 'px';
      this.kfiRectangleDiv.style.border = '2px solid rgb(0,0,255, .2)';
      this.kfiRectangleDiv.style.position = 'absolute';
      this.pdfviewer.pageElement.append(this.kfiRectangleDiv);
    }
  }

  private createOpenDocumentSession(
    archiveId: number,
    searchId: number,
    searchResults: SearchResults
  ): void {
    this.viewerService
      .openSelectedArchiveDocuments(
        this.databasesQuery.activeId,
        archiveId,
        searchId,
        this.searchesQuery.currentSearchPrompts,
        searchResults,
        this.appQuery.alwaysOpenNewTab
      )
      .subscribe({
        next: () => {
          this.logger.debug('Viewer closed.');
          this.onRefreshSearch({ forceReload: true });
        },
        error: (error: UserFriendlyError) => {
          error.i18n = 'ERROR_CREATE_SESSION_MSG';
          this.notify.error(error);
        },
      });
  }

  /**
   * Create the elements needed for regular expression highlights.
   */
  private createRegexHighlightCanvas() {
    if (!this.canvas) return;
    this.regexHighlightCanvas = document.createElement('canvas');
    this.regexHighlightCanvas.id = 'regex_highlight_page_' + this.pageNumber;
    this.regexHighlightCanvas.height = this.canvas.clientHeight || 0;
    this.regexHighlightCanvas.width = this.canvas.clientWidth || 0;
    this.regexHighlightCanvas.style.position = 'absolute';
    this.regexHighlightCanvas.style.top = '0';
    this.regexHighlightCanvas.style.left = '0';
    this.regexHighlightCanvas.style.zIndex = '99';
    this.pdfviewer.pageElement.append(this.regexHighlightCanvas);
    this.regexHighlightContext = this.regexHighlightCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D;
  }

  private createSave$(): Observable<{
    /** Document id. */
    documentId: number;
    /** Document secureId. */
    documentSecureId: string;
  }> {
    let import$: Observable<ImportedArchiveDocument>;
    const updateSession = new DocumentUpdateSession(
      false,
      false,
      this.rightSidebar?.indexer?.isDirty ?? false,
      this.tableFieldGrid?.isDirty ?? false
    );

    if (this.displayMode === 'inboxIndexing') {
      import$ = this.inboxesService.api.indexToArchive(
        this.inboxId,
        `${this.documentAsInboxFile.filename}${this.documentAsInboxFile.fileType}`,
        this.databaseId,
        this.inboxTargetArchiveId,
        updateSession
      );
    } else if (this.displayMode === 'archiveImport') {
      import$ = this.archiveService.api
        .import(
          this.databaseId,
          this.archiveId,
          [this.documentAsArchiveImportFile.filename],
          updateSession
        )
        .pipe(map((importedDocuments) => importedDocuments[0]));
    } else {
      import$ = of<ImportedArchiveDocument>({
        id: this.documentAsSearchResult.id,
        secureId: this.documentAsSearchResult.secureId,
      });
    }

    const save$ = import$.pipe(
      switchMap((document) => {
        assertExists(
          document,
          'Document object with id and secure id must be provided to save.'
        );

        const saveChain$ = this.createSaveChain(
          document.id,
          document.secureId,
          updateSession
        );

        return saveChain$.pipe(
          switchMap(() =>
            of({ documentId: document.id, documentSecureId: document.secureId })
          )
        );
      })
    );

    return save$;
  }

  /**
   * Creates an observable that contains all update calls to be run in order.
   *
   * @param documentId Document id.
   * @param secureId Document secure id.
   * @param session Document Update Session.
   * @returns An observable that runs each update sequentially.
   */
  private createSaveChain(
    documentId: number,
    secureId: string,
    session: DocumentUpdateSession
  ) {
    const saveChain: Observable<void>[] = [];
    if (this.rightSidebar?.indexer?.isDirty) {
      const fieldValues = this.rightSidebar.indexer.getFieldValuesForSave();
      const fieldUpdate$ = this.documentUpdateProvider
        .updateFieldData(
          this.databaseId,
          this.displayMode === 'inboxIndexing'
            ? this.inboxTargetArchiveId
            : this.archiveId,
          documentId,
          secureId,
          fieldValues,
          session
        )
        .pipe(
          catchError((error: UserFriendlyError) => {
            this.logger.error(
              'An error occurred while attempting to save index data.',
              error
            );
            error.i18n = 'FAILED_TO_SAVE_INDEX_DATA';
            return throwError(() => error);
          }),
          tap(() => {
            this.logger.debug('Field data updated successfully.');
            if (
              this.displayMode === 'archiveImport' ||
              this.displayMode === 'inboxIndexing'
            ) {
              this.rightSidebar.indexer.indexerForm.reset();
            } else {
              this.rightSidebar.indexer.indexerForm.markAsPristine();
            }
          })
        );

      saveChain.push(fieldUpdate$);
    }

    if (this.tableFieldGrid?.isDirty) {
      const tableUpdate$ = this.tableFieldGrid
        .saveTableData(documentId, secureId, session)
        .pipe(
          catchError((error: UserFriendlyError) => {
            error.i18n = 'FAILED_TO_SAVE_TABLE_DATA';
            return throwError(() => error);
          }),
          tap(() => {
            if (
              this.displayMode === 'archiveImport' ||
              this.displayMode === 'inboxIndexing'
            ) {
              this.tableFieldGrid?.clearData(false);
            }
          })
        );
      saveChain.push(tableUpdate$);
    }

    // This will create an observable that will run each observable in the chain, and emit when all finish.
    // If an observable errors then the chain will stop.
    // If an observable never completes then this observable will also never complete.
    return forkJoin(saveChain);
  }

  private createTextLayers(forceOcr?: boolean) {
    this.textLayers = [];
    if (this.useLocalOcr || forceOcr) {
      const lastFocusedField = this.rightSidebar.indexer.lastFocusedField;
      // Tesseract
      this.progressDialogService.openProgressDialog(
        this.translate.translate('LOADING_KEYFREE_OCR_PAGE', {
          pageNumber: this.pageNumber,
        })
      );
      const pageElement = this.pdfviewer.pageElement.querySelector(
        'canvas'
      ) as HTMLCanvasElement;
      if (!pageElement) return EMPTY;
      return this.keyfreeService.getWords(pageElement).pipe(
        map((page: Tesseract.Page) => {
          let lineCount = 0;
          let lineText = '';
          for (const word of page.words) {
            if (word.line.text !== lineText) {
              lineText = word.line.text;
              lineCount++;
            }
            this.textLayers.push({
              height: (word.bbox.y1 - word.bbox.y0) / window.devicePixelRatio,
              width: (word.bbox.x1 - word.bbox.x0) / window.devicePixelRatio,
              text: word.text,
              words: word.line.text.split(' '),
              multiSelected: false,
              line: lineCount,
              x: word.bbox.x0 / window.devicePixelRatio,
              y: word.bbox.y0 / window.devicePixelRatio,
              regExHighlighted: false,
              dragSelect: false,
              highlighted: false,
              selected: false,
            });
          }
          this.progressDialogService.closeProgressDialog();
          this.rightSidebar.indexer.lastFocusedField = lastFocusedField;
          return page;
        })
      );
    } else {
      // Existing Pdf text layer
      const words = this.pdfviewer.getPageWords(
        this.pageNumber,
        this.annotationCanvas.width
      );
      let lineIndex = 0,
        previousWordHeight = 0,
        previousWordTop = 0;
      return words.pipe(
        map((results) => results.sort((a: any, b: any) => a.y - b.y)),
        // Calculate line numbers
        map((words: any) =>
          words.map((word: any) => {
            const wordIsAlmostSameHeight =
              Math.abs(word.height - previousWordHeight) <
              0.1 * previousWordHeight;

            // Check if the word is adjacent to the previous word.
            const wordIsAdjacentWithinPreviousHeight =
              word.y <= previousWordTop + previousWordHeight;

            // If the words are not roughly the same line height, or not adjacent to the previous word, start a new line.
            if (!wordIsAlmostSameHeight || !wordIsAdjacentWithinPreviousHeight)
              lineIndex++;

            // Set the word's line number.
            word.line = lineIndex;

            // Update the previous word values.
            previousWordHeight = word.height;
            previousWordTop = word.y;

            // Return the modified word.
            return word;
          })
        ),
        // Sort by line number, and ascending x coordinates where line number is the the same
        map((results) =>
          results.sort((a: any, b: any) => a.line - b.line || a.x - b.x)
        ),
        map(
          (words) =>
            (this.textLayers = words.map((word: any) => ({
              height: word.height / window.devicePixelRatio,
              width: word.width / window.devicePixelRatio,
              text: word.text,
              line: word.line,
              words: [],
              x: word.x / window.devicePixelRatio,
              y: word.y / window.devicePixelRatio,
              dragSelect: false,
              regExHighlighted: false,
              highlighted: false,
              selected: false,
              multiSelected: false,
            })))
        )
      );
    }
  }

  private deleteAnnotationCanvas() {
    this.annotationCanvas.removeEventListener('mousemove', this.kfiMouseMove);
    this.annotationCanvas.removeEventListener('mouseup', this.mouseUp);
    this.annotationCanvas.removeEventListener('mousedown', this.mouseDown);
    this.annotationCanvas.removeEventListener(
      'contextmenu',
      this.kfiContextMenu
    );
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
    this.annotationCanvas.remove();
  }

  /**
   * Returns if the drag select should highlight a word.
   *
   * @param textLayer - Text layer object.
   * @returns - Boolean.
   */
  private doesKfiDragHighlight(textLayer: TextLayer): boolean {
    if (!this.kfiRectangleDiv) return false;
    const boxLeft = Number.parseInt(this.kfiRectangleDiv.style.left, 10),
      boxTop = Number.parseInt(this.kfiRectangleDiv.style.top, 10),
      boxRight =
        Number.parseInt(this.kfiRectangleDiv.style.left, 10) +
        Number.parseInt(this.kfiRectangleDiv.style.width, 10),
      boxBottom =
        Number.parseInt(this.kfiRectangleDiv.style.top, 10) +
        Number.parseInt(this.kfiRectangleDiv.style.height, 10);

    // Text layer falls within the bounds of the box.
    const textLayerInBox =
      textLayer.x > boxLeft &&
      textLayer.x + textLayer.width < boxRight &&
      textLayer.y > boxTop &&
      textLayer.y + textLayer.height < boxBottom;

    // // Text layer is overlapped by the box on the x-axis.
    const textLayerOverlapX =
      textLayer.x <= boxRight && textLayer.x + textLayer.width >= boxLeft;

    // Text layer is overlapped by the box on the y-axis.
    const textLayerOverlapY =
      textLayer.y <= boxBottom && textLayer.y + textLayer.height >= boxTop;

    return textLayerInBox || (textLayerOverlapX && textLayerOverlapY);
  }

  private getArchiveImportFile(viewIndex: any, documentId: any) {
    this.requestedDocuments = new ArchiveImportRequestedDocumentMap(documentId);
    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );
    const archiveImportFile: ArchiveImportFile = {
      filename: this.requestedDocuments.get(
        this.viewIndex
      ) as ArchiveImportRequestedDocument,
    };
    this.logger.debug('archive file import', archiveImportFile);
    this.document = archiveImportFile;
    this.loadImportDocumentAsPdf(archiveImportFile.filename);
    return of(archiveImportFile);
  }

  // We need to know the locales decimal seperator character (can't just assume .)
  private getDecimalSeparator(locale: any) {
    const numberWithDecimalSeparator = 1.1;
    const numberFormat = Intl.NumberFormat(locale);
    assertExists(numberFormat, 'Number format must exist.');
    const formatParts = numberFormat.formatToParts(numberWithDecimalSeparator);
    assertExists(formatParts, 'Must be able to format to parts.');
    return formatParts.find((part) => part.type === 'decimal')?.value;
  }

  private getNextField() {
    const currentField = this.rightSidebar.indexer.lastFocusedField,
      indexerFields = this.rightSidebar.indexer.indexerFields;
    let index = currentField
      ? indexerFields.findIndex((f) => f.id == currentField.id) + 1
      : 0;
    let field: IndexerField | undefined =
      this.rightSidebar.indexer.indexerFields[index];
    if (!field && index >= this.rightSidebar.indexer.indexerFields.length) {
      // No more fields to iterate through.
      // Field will be undefined at this point.
      return undefined;
    }
    let fieldControl = this.rightSidebar.indexer.getFormControl(field.id);

    // Skip any field disabled in the indexer form.
    while (field && fieldControl.disabled) {
      index++;
      field = this.rightSidebar.indexer.indexerFields[index];
      if (!field && index >= this.rightSidebar.indexer.indexerFields.length) {
        // No more fields to iterate through.
        // Field will be undefined at this point.
        break;
      }
      fieldControl = this.rightSidebar.indexer.getFormControl(field.id);
    }

    return field;
  }

  private getSearchResultForArchiveRoute(
    archiveId: number,
    databaseId: number,
    searchId: number,
    searchPrompts: SearchPrompt[],
    documentId: string,
    viewIndex: number
  ) {
    this.logger.debug('Loading single document search for:', {
      databaseId,
      archiveId,
      searchId,
      searchPrompts,
      documentId,
      viewIndex,
    });

    // Support multiple documents.
    this.requestedDocuments = new ArchiveSearchRequestedDocumentMap(documentId);
    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );
    const id = this.requestedDocuments.get(
      this.viewIndex
    ) as ArchiveSearchRequestedDocument;
    assertExists(id, 'Document Id is required.');
    // Search for the matching document, return it as the final value of
    // this Observable, and trigger a load of the document.
    return this.runSearchForSingleDocument(
      archiveId,
      searchId,
      searchPrompts,
      0,
      id
    ).pipe(
      tap((document) => {
        this.loadDocumentAsPdf(databaseId, archiveId, document);
      })
    );
  }

  private getSearchResultForInboxRoute(
    databaseId: number,
    inboxId: any,
    documentId: any,
    viewIndex: any
  ) {
    this.logger.debug('Loading inbox document for:', {
      databaseId,
      inboxId,
      documentId,
      viewIndex,
    });

    // Support multiple documents.
    this.requestedDocuments = new InboxRequestedDocumentMap(documentId);
    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );
    // Get the matching inbox file.
    return this.inboxesQuery.activeInbox$.pipe(
      first(),
      switchMap((inbox) => {
        /**
         * We need to run the get inbox call on a specific inbox to get its files if they
         are not already in the store.
         */
        return inbox.files.length > 0
          ? of(inbox)
          : this.inboxesService.getById(inbox.id);
      }),
      map((inbox) => {
        const documentName = this.requestedDocuments.get(
          this.viewIndex
        ) as InboxRequestedDocument;
        const requestedFileName = decodeURIComponent(documentName);
        const matchingFile = inbox.files.find(
          (file) => file.filename + file.fileType === requestedFileName
        );
        try {
          assertExists(matchingFile, 'No matching inbox file.');
          this.document = matchingFile;
          // Request the image and load it in the viewer.
          this.loadDocumentAsPdfFromInbox(inboxId, documentName);
          return matchingFile;
        } catch {
          // Document not found. Remove from URL.
          this.onLoadFailure();
        }
      })
    );
  }

  private getSearchResultForTaskSearchRoute(
    databaseId: number,
    archiveId: number,
    documentId: any,
    viewIndex: any
  ) {
    this.logger.debug('Loading single document task search for:', {
      databaseId,
      archiveId,
      documentId,
      viewIndex,
    });

    // Support multiple documents.
    this.requestedDocuments = new ArchiveSearchRequestedDocumentMap(documentId);

    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );

    const id = this.requestedDocuments.get(
      this.viewIndex
    ) as ArchiveSearchRequestedDocument;

    assertExists(id, 'Document Id is required.');

    return this.taskSearchesService
      .getQueuedDocument(databaseId, archiveId, id)
      .pipe(
        map((result: SearchResult) => {
          this.logger.debug('Search results:', result);
          assertExists(result, 'No document was found matching query.');
          return result;
        }),
        tap((document) => {
          this.loadDocumentAsPdf(databaseId, archiveId, document);
        })
      );
  }

  private getSearchResultForArchiveRevision(
    databaseId: number,
    archiveId: number,
    documentIdRouteString: string
  ): Observable<SearchResult> {
    // Support multiple documents.
    this.requestedDocuments = new ArchiveRevisionRequestedDocumentMap(
      documentIdRouteString as string
    );

    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(this.viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );

    const document = this.requestedDocuments.get(
      this.viewIndex
    ) as ArchiveRevisionRequestedDocument;

    if (document.versionNumber === CURRENT_DOCUMENT_REVISION) {
      // This is the current version so don't bother with loading previous revisions.
      return this.searchesService
        .getDocumentData(
          databaseId,
          this.archiveId,
          document.docId,
          document.secureId
        )
        .pipe(
          tap((document) => {
            this.loadDocumentAsPdf(databaseId, this.archiveId, document);
          })
        );
    }

    return this.documentProvider
      .getDocumentRevisions(
        databaseId,
        archiveId,
        document.docId,
        document.secureId,
        this.auth.user.token
      )
      .pipe(
        switchMap((revisions) => {
          const revision = revisions.find(
            (r) => r.version === document.versionNumber
          );
          assertExists(
            revision,
            'Revision with provided verison number must exist.'
          );
          this.revisionArchive = this.archivesQuery.getVersionsArchive();
          return this.searchesService
            .getDocumentData(
              databaseId,
              this.revisionArchive.id,
              revision.documentId,
              revision.secureId
            )
            .pipe(
              tap((document) => {
                this.loadDocumentAsPdf(
                  databaseId,
                  this.revisionArchive.id,
                  document
                );
              })
            );
        })
      );
  }

  private getSearchResultFromArchiveDocument(
    databaseId: number,
    archiveId: number,
    documentId: string
  ): Observable<SearchResult> {
    // Support multiple documents.
    this.requestedDocuments = new ArchiveRequestedDocumentMap(
      documentId as string
    );

    // Get the index, but do not exceed the set.
    this.viewIndex = Math.min(
      Number(this.viewIndex ?? 0),
      this.requestedDocuments.size - 1
    );

    const document = this.requestedDocuments.get(
      this.viewIndex
    ) as ArchiveRequestedDocument;

    return this.searchesService
      .getDocumentData(databaseId, archiveId, document.docId, document.secureId)
      .pipe(
        tap((document) => {
          this.loadDocumentAsPdf(databaseId, archiveId, document);
        })
      );
  }

  private getSearchResultFromArchiveSession(
    sessionId: any,
    viewIndex: any,
    databaseId: number,
    archiveId: number
  ): Observable<SearchResult> {
    return this.archiveCacheQuery.selectSession(sessionId).pipe(
      map((cache) => {
        assertExists(cache);
        // Map the cached documents to the requested documents object.
        const documentIds = cache.documents.map((d) => d.id);
        this.requestedDocuments = new ArchiveSearchRequestedDocumentMap(
          documentIds
        );

        // Get the index, but do not exceed the set.
        this.viewIndex = Math.min(
          Number(viewIndex ?? 0),
          this.requestedDocuments.size - 1
        );

        // Get the document the url has requested
        const cachedDocument = cache.documents[this.viewIndex];

        assertExists(
          cachedDocument,
          'Archive document must exist in the cache to open it without a search.'
        );

        return cachedDocument;
      }),
      switchMap((cachedDocument) => {
        return this.searchesService
          .getDocumentData(
            databaseId,
            archiveId,
            cachedDocument.id,
            cachedDocument.secureId
          )
          .pipe(
            tap((document) => {
              this.loadDocumentAsPdf(databaseId, archiveId, document);
            })
          );
      })
    );
  }

  /**
   * Handle error pipelines when a document can not be loaded.
   *
   * @returns Oservable Never.*/
  private handleMissingDocument() {
    this.onLoadFailure();
    return EMPTY;
  }

  private hideHoverPlus(): void {
    const hoverPlus = this.pdfviewer.pageElement.querySelector(
      '#kfi_hover_plus'
    ) as HTMLElement;
    if (!hoverPlus) return;
    hoverPlus.hidden = true;
  }

  private hideHoverText(): void {
    const hoverText = this.pdfviewer.pageElement.querySelector(
      '#kfi_hover_text'
    ) as HTMLElement;
    if (!hoverText) return;
    this.kfiHoverText = '';
    hoverText.hidden = true;
  }

  private highlightByRegularExpression(field: Field): void {
    this.regexHighlightContext.clearRect(
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );
    if (field.regex) {
      // Remove highlight when clicking also check existing functionality
      this.regexHighlightContext.beginPath();
      for (const textLayer of this.textLayers) {
        const expression = new RegExp(field.regex.replace(/\(\?#.*? #\)/g, ''));
        if (expression.test(textLayer.text)) {
          textLayer.regExHighlighted = true;
          this.regexHighlightContext.rect(
            textLayer.x,
            textLayer.y,
            textLayer.width,
            textLayer.height
          );
          this.regexHighlightContext.fillStyle = 'rgba(0,255,0,.2)';
          this.regexHighlightContext.fillRect(
            textLayer.x,
            textLayer.y,
            textLayer.width,
            textLayer.height
          );
        }
      }
    }
  }

  private initializeKfiRectangleDiv(x: number, y: number): void {
    this.kfiRectangleDiv.style.width =
      Math.abs(x - this.kfiRectangle.startX) + 'px';
    this.kfiRectangleDiv.style.height =
      Math.abs(y - this.kfiRectangle.startY) + 'px';
    this.kfiRectangleDiv.style.left =
      x - this.kfiRectangle.startX < 0
        ? x + 'px'
        : this.kfiRectangle.startX + 'px';
    this.kfiRectangleDiv.style.top =
      y - this.kfiRectangle.startY < 0
        ? y + 'px'
        : this.kfiRectangle.startY + 'px';
  }

  private keyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Shift') {
      this.showHoverPlus();
    }
  };

  private keyUp = (event: KeyboardEvent): void => {
    if (event.key === 'Alt') {
      event.preventDefault();
    }
    if (event.key === 'Shift') {
      this.hideHoverPlus();
      if (!this.currentTextLayer) return;
      for (const textLayer of this.textLayers) {
        textLayer.highlighted = false;
        textLayer.multiSelected = false;
      }
      if (this.kfiShiftHoverText) {
        this.setNextFocusedFieldValue(this.kfiShiftHoverText);
        this.kfiShiftHoverText = '';
      }
    }
  };

  /**
   * Subscribes to the viewerAutoSave observable and adds/removes the dirtyFn.
   */
  private listenForAutoSave(): void {
    this.appQuery.viewerAutoSave$
      .pipe(untilDestroyed(this))
      .subscribe((autoSave) => {
        this.autoSave = autoSave;
      });
  }

  private listenForUserSettings = () => {
    this.appQuery.keyfreeUseOcr$.subscribe((use) => {
      this.useLocalOcr = use;
    });
  };

  private listenForZoomChange(): void {
    // Ensure there is only ever one scale change subscription.
    this.scaleChangeSubscription?.unsubscribe();
    this.scaleChangeSubscription = this.pdfviewer.pdfJsComponent.onScaleChange
      .pipe(untilDestroyed(this), debounceTime(500))
      .subscribe(() => {
        this.updateKfi(this.forceOcr).subscribe(() => {
          this.setFieldFocus(false);
        });
      });
  }

  private listenToTableFieldEvent(): void {
    this.tableFieldUIService.activeTableField$.subscribe((activeTableField) => {
      this.logger.debug('Active table field or row changed.', activeTableField);
      this.showTableFieldGrid = !!activeTableField;
    });
  }

  private loadDocumentAsPdf(
    databaseId: number,
    archiveId: number,
    document: SearchResult
  ) {
    // Store the current open document.
    this.document = document;
    // Get a PDF for the document.
    // TODO: convert/guest bits should happen in service as part of the request to get a URL.
    const sanitizedFileExtension = document.fileType
      .replace('.', '')
      .toLowerCase();
    const isConvertableFileType =
      PdfViewerComponent.isClientConvertableExtension(sanitizedFileExtension);
    const useClientConvert = isConvertableFileType;
    // If the file is not convertable or a PDF and we are a guest, then it is unsupported for this viewer.
    this.isUnsupportedDocument =
      this.auth.isGuest &&
      !isConvertableFileType &&
      sanitizedFileExtension !== 'pdf';

    // Handle unsupported document formats.
    if (this.isUnsupportedDocument) {
      this.logger.error(
        'Unsupported document format, can not be displayed in viewer.'
      );
      this.pdfOptions = undefined;
      return;
    }

    // Get a URL for the document.
    const documentUrl = this.documentProvider.getArchivePreviewUrl(
      databaseId,
      archiveId,
      document.id,
      this.auth.user.token,
      document.secureId,
      false
    );

    // Check for annotations.
    this.documentProvider
      .getDocumentAnnotations(
        this.databaseId,
        this.archiveId,
        this.document.id,
        this.auth.user.token,
        this.document.secureId
      )
      .subscribe((annotations) => {
        // Set the viewer options to display the requested document.
        this.pdfOptions = {
          url: useClientConvert
            ? // If convertable type, add the URL suffix.
              documentUrl +
              '&clientConvert=' +
              document.fileType.replace('.', '')
            : // Othewrwise, use the standard request URL.
              documentUrl,
          annotations,
        };
      });
  }

  private loadDocumentAsPdfFromInbox(inboxId: number, documentName: string) {
    // Get a PDF for the document.
    // The file name as it is retrieved from the API can sometimes seem to have an encoded filename
    // so ensure it is decoded before use.
    documentName = decodeURIComponent(documentName);
    // TODO: convert/guest bits should happen in service as part of the request to get a URL.
    const documentFragments = documentName.split('.');
    const sanitizedFileExtension = documentFragments[
      documentFragments.length - 1
    ]
      .replace('.', '')
      .toLowerCase();
    const isConvertableFileType =
      PdfViewerComponent.isClientConvertableExtension(sanitizedFileExtension);
    const useClientConvert = isConvertableFileType;

    // TODO: Inboxes do not currently have a concept of client conversion. All files still get converted
    // at the server.

    // If the file is not convertable or a PDF and we are a guest, then it is unsupported for this viewer.
    this.isUnsupportedDocument =
      this.auth.isGuest &&
      !isConvertableFileType &&
      sanitizedFileExtension !== 'pdf';

    // Handle unsupported document formats.
    if (this.isUnsupportedDocument) {
      this.logger.error(
        'Unsupported document format, can not be displayed in viewer.'
      );
      this.pdfOptions = undefined;
      return;
    }

    // Get a URL for the document.
    const documentUrl = this.documentProvider.getInboxPreviewUrl(
      inboxId,
      documentName
    );

    // Check for annotations.
    // TODO: inboxes will probably need this too...
    // this.documentProvider
    //   .getDocumentAnnotations(
    //     this.databaseId,
    //     this.archiveId,
    //     this.document.id,
    //     this.auth.user.token,
    //     this.document.secureId
    //   )
    //   .subscribe((annotations) => {
    const annotations = [] as DocumentAnnotations;
    //     // Set the viewer options to display the requested document.
    this.pdfOptions = {
      url: useClientConvert
        ? // If convertable type, add the URL suffix.
          documentUrl + '&clientConvert=' + sanitizedFileExtension
        : // Othewrwise, use the standard request URL.
          documentUrl,
      annotations,
      //   };
      // });
    };
  }

  private loadImportDocumentAsPdf(filename: string): void {
    const documentFragments = filename.split('.');
    const sanitizedFileExtension = documentFragments[
      documentFragments.length - 1
    ]
      .replace('.', '')
      .toLowerCase();
    const isConvertableFileType =
      PdfViewerComponent.isClientConvertableExtension(sanitizedFileExtension);
    const useClientConvert = isConvertableFileType;
    // If the file is not convertable or a PDF and we are a guest, then it is unsupported for this viewer.
    this.isUnsupportedDocument =
      this.auth.isGuest &&
      !isConvertableFileType &&
      sanitizedFileExtension !== 'pdf';

    // Handle unsupported document formats.
    if (this.isUnsupportedDocument) {
      this.logger.error(
        'Unsupported document format, can not be displayed in viewer.'
      );
      this.pdfOptions = undefined;
      return;
    }

    // Get a URL for the document.
    const documentUrl = this.documentProvider.getImportFileUrl(filename);
    // const documentUrl = this.documentProvider.getInboxPreviewUrl(
    //   inboxId,
    //   documentName
    // );

    this.pdfOptions = {
      url: useClientConvert
        ? // If convertable type, add the URL suffix.
          documentUrl + '&clientConvert=' + sanitizedFileExtension
        : // Othewrwise, use the standard request URL.
          documentUrl,
      annotations: [],
      //   };
      // });
    };
  }

  private mouseDown = (event: MouseEvent) => {
    event.preventDefault();
    this.isMouseDown = true;
    this.createKfiDragRectangle(event);
  };

  /**
   * Mouse click event.
   *
   * @param event - Mouse Event.
   */
  private mouseUp = (event: MouseEvent) => {
    this.isMouseDown = false;
    this.clearRegExHighlights();
    const isRightClick = event.button === 2;
    const rect = this.annotationCanvas.getBoundingClientRect(),
      x = event.clientX - rect.left,
      y = event.clientY - rect.top;
    if (this.currentTextLayer && !this.dragActive) {
      if (!event.shiftKey) {
        if (event.altKey) {
          this.kfiEditDialogService
            .openKfiEditDialog(this.currentField.name, this.dragText)
            .subscribe((result) => {
              if (result) {
                this.setNextFocusedFieldValue(result);
              } else {
                this.setFieldFocus(false);
              }
            });
        } else {
          this.setNextFocusedFieldValue(this.dragText, !isRightClick);
        }
      }
      if (event.shiftKey && !this.currentTextLayer.multiSelected) {
        this.currentTextLayer.multiSelected = true;
      }
    }
    if (
      this.kfiRectangleDiv &&
      Number.parseInt(this.kfiRectangleDiv.style.width, 10) > 2 &&
      this.dragActive
    ) {
      if (event.altKey) {
        this.kfiEditDialogService
          .openKfiEditDialog(this.currentField.name, this.dragText)
          .subscribe((result) => {
            if (result) {
              this.setNextFocusedFieldValue(result);
            } else {
              this.setFieldFocus(false);
            }
          });
      } else {
        this.setNextFocusedFieldValue(this.dragText);
      }
    }
    this.dragActive = false;
    for (const element of this.pdfviewer.pageElement.querySelectorAll(
      '.kfi-rectangle'
    )) {
      element.remove();
    }
  };

  /**
   * Re-navigate to the displayed document based on current routing values.
   *
   * @param forceReload Force a reload of the same route.
   * @returns An observable of whether or not navigation was successful.
   */
  private navigateToDocument(forceReload = false): Observable<boolean> {
    const navigate = (): Promise<boolean> => {
      switch (this.displayMode) {
        case 'archive': {
          return this.router.navigate(
            [
              'db',
              this.databaseId,
              'archive',
              this.archiveId,
              'search',
              this.searchId,
              'document',
              this.requestedDocuments.toString(),
              'view',
              this.viewIndex,
            ],
            {
              queryParamsHandling: 'merge',
            }
          );
        }
        case 'archiveImport':
          return this.router.navigate(
            [
              'db',
              this.databaseId,
              'archive',
              this.archiveId,
              'import',
              this.requestedDocuments.toString(),
              'view',
              this.viewIndex,
            ],
            {
              queryParamsHandling: 'merge',
            }
          );
        case 'task': {
          return this.router.navigate(
            [
              'db',
              this.databaseId,
              'archive',
              this.archiveId,
              'task',
              this.taskId,
              'document',
              this.requestedDocuments.toString(),
              'view',
              this.viewIndex,
            ],
            {
              queryParamsHandling: 'merge',
            }
          );
        }
        case 'archiveSession': {
          return this.router.navigate([
            'db',
            this.databaseId,
            'archive',
            this.archiveId,
            'document',
            'session',
            this.sessionId,
            'view',
            this.viewIndex,
          ]);
        }
        default: {
          return this.router.navigate(
            [
              'db',
              this.databaseId,
              'inbox',
              this.inboxId,
              'document',
              this.requestedDocuments.toString(),
              'view',
              this.viewIndex,
            ],
            {
              queryParamsHandling: 'merge',
            }
          );
        }
      }
    };

    if (forceReload) {
      // Trip router to cause a reuse.
      return from(
        this.router.navigateByUrl('/', {
          skipLocationChange: true,
          state: { reloading: true },
        })
      ).pipe(switchMap(() => from(navigate())));
    } else {
      return from(navigate());
    }
  }

  private redrawKfi = () => {
    // Page not ready yet, abort. Next cycle of the calling timeout will continue checks.
    try {
      this.pdfviewer.pageElement;
    } catch {
      return;
    }
    // Never set anything here, only draw
    if (!this.canvas) return;
    this.annotationContext.clearRect(
      0,
      0,
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    let highlightsExist = false;

    for (const textLayer of this.textLayers) {
      // Not using drag select
      if (textLayer.highlighted && !this.dragActive) {
        // Only highlight words that are not already reg ex highlighted.
        if (!textLayer.regExHighlighted) {
          this.annotationContext.beginPath();
          this.annotationContext.rect(
            textLayer.x,
            textLayer.y,
            textLayer.width,
            textLayer.height
          );

          this.annotationContext.fillStyle = 'rgba(255,0,0,.2)';
          if (textLayer.multiSelected) {
            this.annotationContext.fillStyle = 'rgb(0,0,255,.2)';
          }
          this.annotationContext.fillRect(
            textLayer.x,
            textLayer.y,
            textLayer.width,
            textLayer.height
          );
        }
        highlightsExist = true;
        if (!this.isMouseDown) {
          this.updateHoverText(textLayer.text, textLayer.line);
        }
      }
      // Using drag select.
      if (this.dragActive && textLayer.dragSelect) {
        this.annotationContext.beginPath();
        this.annotationContext.rect(
          textLayer.x,
          textLayer.y,
          textLayer.width,
          textLayer.height
        );
        this.annotationContext.fillStyle = 'rgb(0,0,255,.2)';
        this.annotationContext.fillRect(
          textLayer.x,
          textLayer.y,
          textLayer.width,
          textLayer.height
        );
        this.updateHoverText(textLayer.text, textLayer.line);
        textLayer.highlighted = true;
        highlightsExist = true;
      }
    }
    this.dragText =
      this.kfiHoverText !== '' ? this.kfiHoverText : this.dragText;

    if ((this.isMouseDown && this.dragActive) || this.isShiftDown) {
      this.kfiHoverText = '';
    }

    if (!this.dragActive) {
      for (const textLayer of this.textLayers) {
        if (textLayer.dragSelect) {
          textLayer.dragSelect = false;
        }
      }
    }
    if (!highlightsExist) {
      this.hideHoverText();
    }
    if (!this.rightSidebar.indexer.lastFocusedField) {
      const targetField = this.tableFieldGrid?.getTargetField();
      if (targetField) {
        this.updateHoverHeader(targetField?.name);
      } else {
        this.updateHoverHeader(this.translate.translate('NO_FIELD_SELECTED'));
      }
    }
  };

  /**
   * Removes every instance of the annotation canvases used for kfi.
   */
  private removeAnnotationCanvases(): void {
    const elements =
      this.pdfviewer.contentWindow.document.querySelectorAll(
        "[id^='anno_page_']"
      );
    for (const element of elements) element.remove();
  }

  /**
   * Removes every instance of the regular expression canvases used for kfi.
   */
  private removeRegExHighlightCanvases(): void {
    const elements = this.pdfviewer.contentWindow.document.querySelectorAll(
      "[id^='regex_highlight_page_']"
    );
    for (const element of elements) element.remove();

    this.regexHighlightCanvas.remove();
  }

  /**
   * Run the specified search and load the result data, of one document.
   *
   * @param archiveId Archive Id.
   * @param searchId Search Id.
   * @param searchPrompts Search parameter data.
   * @param tabId Tab Id to restrict the search to. 0 will not filter.
   * @param documentId Document Id.
   * @returns Observable search result.
   */
  private runSearchForSingleDocument(
    archiveId: number,
    searchId: number,
    searchPrompts: SearchPrompt[],
    tabId: number = 0,
    documentId: number
  ) {
    this.isSearchLoading = true;
    const defaultSearchOptions: SearchOptions = {
      page: 1,
      countOnly: false,
      recordsPerPage: 1,
      searchCriteria: searchPrompts
        ? createApiSearchPromptString(searchPrompts)
        : '',
      sort: '',
      tabId,
      targetArchiveId: archiveId,
      documentId,
      includeExtendedData: true,
    };

    // Use a select for the search, ignoring nil values.
    // This will make the actual run of the search wait for the search to be loaded.
    // Otherwise this can cause issues when loading directly from a shared link to an instance.
    const search$ = this.searchesQuery.isLoading$.pipe(
      untilDestroyed(this),
      first((isLoading) => isLoading === false),
      map(() => this.searchesQuery.getEntity(searchId)),
      tap((search) => {
        if (!search) {
          // We don't know for sure why the search wasn't found in the store
          // but it was probably because the user is not secured.
          const error = new UserFriendlyError(
            undefined,
            'Search was not found in the entity store. This is likely because the user does not have permissions to the search.',
            'SEARCH_NOT_AVAILABLE'
          );
          this.notify.error(error);
          this.router.navigate(['db', this.databaseId], {
            queryParamsHandling: 'merge',
          });
        }
      }),
      filterNilValue()
    );
    // Execute the search.
    return search$.pipe(
      switchMap((search) =>
        this.searchesService.run(search, defaultSearchOptions).pipe(
          untilDestroyed(this),
          map((resultResponse) => {
            this.logger.debug('Search results:', resultResponse);
            // Get a single result.
            const matchedDocument = resultResponse.searchResults[0];
            assertExists(
              matchedDocument,
              'No document was found matching query.'
            );
            return matchedDocument;
          }),
          tap(() => (this.isSearchLoading = false))
        )
      )
    );
  }

  private setFieldFocus(nextField?: boolean): Field {
    const field = nextField
      ? this.getNextField()
      : this.rightSidebar.indexer.lastFocusedField || this.currentField;
    assertExists(field, 'Field must exist');
    if (field.multiValue) {
      const component =
        this.rightSidebar.indexer.multiValueFieldComponents.find(
          (f) => f.field.id === field.id
        );
      assertExists(
        component,
        'MultiValue Field component for the field must exist.'
      );
      // There should always be at least one MV field entry.
      component.fieldComponents.first.fieldComponent.focus();
    } else {
      const component = this.rightSidebar.indexer.fieldComponents.find(
        (f) => f.field.id === field.id
      );
      assertExists(component, 'Field component for the field must exist.');
      component.fieldComponent.focus();
    }
    this.updateHoverHeader(field.name);
    return field;
  }

  private setNextFocusedFieldValue(text: string, goToNextField = true): void {
    if (!this.rightSidebar.indexer.lastFocusedField) {
      // If the last focused field is undefined we've exhausted fields and move on to table fields
      if (this.rightSidebar.indexer.tableFields.length > 0) {
        const targetField = this.tableFieldGrid?.getTargetField();
        assertExists(targetField, 'Table field must exist.');
        text = this.validateFieldValue(targetField, text);
        if (text.includes('\n')) {
          this.tableFieldGrid?.addColumnData(text.split('\n'));
        } else {
          this.tableFieldGrid?.addDataToFocusedCell(text, true);
        }
        this.lastLine = 0;
        this.updateHoverHeader(targetField.name);
      }
    } else {
      if (!this.rightSidebar.indexer.lastFocusedField) return;
      for (
        let index = 0, c = this.rightSidebar.indexer.indexerFields.length;
        index < c;
        ++index
      ) {
        if (
          this.rightSidebar.indexer.lastFocusedField.id ===
          this.rightSidebar.indexer.indexerFields[index].id
        ) {
          text = this.validateFieldValue(
            this.rightSidebar.indexer.indexerFields[index],
            text
          );
          // Multi Value Field
          if (this.rightSidebar.indexer.indexerFields[index].multiValue) {
            this.rightSidebar.indexer.addValueToMultiValueField(
              this.rightSidebar.indexer.indexerFields[index].id,
              [text]
            );
            if (!goToNextField) {
              const mvFieldComponent =
                this.rightSidebar.indexer.multiValueFieldComponents.find(
                  (c) =>
                    c.field.id ===
                    this.rightSidebar.indexer.indexerFields[index].id
                );
              setTimeout(() => {
                mvFieldComponent?.fieldComponents.last.fieldComponent.focus();
              });
            }
          } else {
            // Regular Field
            this.rightSidebar.indexer.setFieldValue(
              this.rightSidebar.indexer.indexerFields[index].id,
              text.replace(/[\n\r]/g, ' ')
            );
          }
          // Add one to set it to the next field.
          const nextField = this.getNextField();

          if (this.tableFieldGrid?.activeTableField && !nextField) {
            this.setNextTableFieldFocused();
            return;
          }

          // If empty clear kfi annotations and turn off kfi.
          if (!nextField && this.annotationCanvas) {
            this.annotationContext.clearRect(
              0,
              0,
              this.annotationCanvas.clientWidth,
              this.annotationCanvas.clientHeight
            );
            this.onKfiToggle();
            return;
          }
          this.setFieldFocus(goToNextField);
          return;
        }
      }
    }
  }

  private setNextTableFieldFocused(): void {
    // Focus table field inputs.
    const columns = this.tableFieldGrid?.grid.api.getAllGridColumns() || [];
    if (this.tableFieldGrid?.activeTableField) {
      let lastRowIndex = this.tableFieldGrid.grid.api.getLastDisplayedRow();
      if (lastRowIndex === -1) {
        // There are no rows if we get here so add one and try to get the index again.
        this.tableFieldGrid.onClickAddRow();
        lastRowIndex = this.tableFieldGrid.grid.api.getLastDisplayedRow();
      }
      const row =
        this.tableFieldGrid.grid.api.getDisplayedRowAtIndex(lastRowIndex);
      assertExists(row, 'A row must exist.');
      // Check to see if we should add a new row.
      let valuesExist = false;
      for (const [key, value] of Object.entries(row.data)) {
        if (value !== '' && key !== 'id') valuesExist = true;
      }
      if (valuesExist) {
        this.tableFieldGrid.onClickAddRow();
        lastRowIndex = this.tableFieldGrid.grid.api.getLastDisplayedRow();
      }
      this.tableFieldGrid?.focusCellForEditing(lastRowIndex, columns[0]);
      const targetedField = this.tableFieldGrid?.getTargetField();
      if (!targetedField) return;
      this.updateHoverHeader(targetedField.name);
    }
  }

  private showHoverPlus() {
    const hoverPlus = this.pdfviewer.pageElement.querySelector(
      '#kfi_hover_plus'
    ) as HTMLElement;
    if (!hoverPlus) return;
    hoverPlus.hidden = false;
  }

  private updateHoverContent(): void {
    const pageElement = this.pdfviewer.pageElement;
    (pageElement.querySelector('#hover_content') as HTMLElement).textContent =
      this.kfiHoverText;
  }

  private updateHoverHeader(value: string) {
    if (!value) return;
    const hoverHeader = this.pdfviewer.pageElement.querySelector(
      '#hover_header'
    ) as HTMLElement;
    if (!hoverHeader) return;
    hoverHeader.textContent = value;
  }

  private updateHoverText(word: string, line: number): void {
    const hoverText = this.pdfviewer.pageElement.querySelector(
      '#kfi_hover_text'
    ) as HTMLElement;
    if (hoverText) hoverText.hidden = false;
    const header = this.rightSidebar.indexer.lastFocusedField
      ? this.rightSidebar.indexer.lastFocusedField.name
      : '';
    this.updateHoverHeader(header);
    if (!this.isMouseDown && !this.isShiftDown) {
      this.kfiHoverText = word;
      this.lastLine = line;
    } else if (!this.isMouseDown && this.isShiftDown) {
      let separator = ' ';
      if (this.lastLine === 0) {
        this.lastLine = line;
      }
      if (this.lastLine !== line) {
        separator = '\n';
      }
      this.lastLine = line;
      this.kfiHoverText =
        this.kfiHoverText.trim() === ''
          ? word
          : this.kfiHoverText + separator + word;
      this.kfiShiftHoverText = this.kfiHoverText;
    } else if (!this.isShiftDown) {
      let separator = ' ';
      if (this.lastLine === 0) {
        this.lastLine = line;
      }
      if (this.lastLine !== line && !this.isShiftDown) {
        separator = '\n';
      }
      this.lastLine = line;
      this.kfiHoverText =
        this.kfiHoverText.trim() === ''
          ? word
          : this.kfiHoverText + separator + word;
    }
    this.updateHoverContent();
  }

  private updateHoverTextLocation(x: number, y: number): void {
    let hoverText = this.pdfviewer.pageElement.querySelector(
      '#kfi_hover_text'
    ) as HTMLElement;
    if (!hoverText) {
      hoverText = this.createHoverText();
    }
    hoverText.style.zIndex = '9999999';
    hoverText.style.left =
      // Keep the hover text on the screen and not behind the indexer.
      this.annotationCanvas.width - (x + 150) < 0
        ? (x - 125).toString() + 'px'
        : (x + 25).toString() + 'px';
    hoverText.style.top =
      this.annotationCanvas.height - (y + 150) < 0
        ? (y - 125).toString() + 'px'
        : (y + 25).toString() + 'px';
    hoverText.style.backgroundColor = 'white';
    hoverText.style.position = 'absolute';
    hoverText.hidden = false;
  }

  /**
   * Update kfi keywords and annotations.
   *
   * @param forceOcr - Force use of local ocr.
   * @returns Promise when kfi is done loading.
   */
  private updateKfi(forceOcr?: boolean) {
    if (!this.kfiActive) return of({});
    this.addOrRemoveKfiOverlayClass('remove');
    this.createAnnotationCanvas();
    this.createRegexHighlightCanvas();
    return this.createTextLayers(forceOcr).pipe(
      tap(() => {
        this.addOrRemoveKfiOverlayClass('add');
      })
    );
  }

  /**
   * Checks to see if the value that will be passed matches the data type of the field.
   *
   * @param field - Field.
   * @param value - Value to be passed into the field.
   * @returns - The value if it's valid or '' if not.
   */
  private validateFieldValue(field: Field, value: any): any {
    if (!value) return;
    // Get the current locale from the browser (this could just be in the function if we only ever support current)
    const locale = navigator.languages[0];
    // Checking for a negative marker in the string (this is the biggest part of it that is guessing)
    const hasNeg = value.includes('-');
    // Remove anything that is not a number or separator
    const re = new RegExp(
      '[^\\d' + this.getDecimalSeparator(locale) + ']',
      'g'
    );
    let sanitizedNumber = value.replace(re, '');
    // Remark as negative if needed
    sanitizedNumber = hasNeg ? '-' + sanitizedNumber : sanitizedNumber;
    // Get the final value as a number, and if a number
    const asNumber = Number.parseFloat(sanitizedNumber);
    const isNumber = !Number.isNaN(asNumber);
    // The number as a string does not match the sanitized number string it came from
    // so it could be something like where sanitized value is '1.2.3.4' in which case the asNumber
    // ends up being 1.2.
    const numberEqualsString = sanitizedNumber === asNumber.toString();
    if (
      field.type === FieldDataType.decimal &&
      (!isNumber || !numberEqualsString)
    ) {
      this.notify.error('"' + value + '" cannot be parsed into a decimal.');
      return '';
    }

    return field.type === FieldDataType.decimal ||
      field.type === FieldDataType.integer
      ? sanitizedNumber
      : value;
  }
}
