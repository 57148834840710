<h1 mat-dialog-title>{{ search.name }}</h1>
<div mat-dialog-content>
  <form [formGroup]="searchPromptForm" id="promptForm" (ngSubmit)="onSubmit()">
    <p *ngFor="let searchParameter of visibleDisplayPrompts">
      <!-- Entry point into dialog contents. -->
      <ng-container *ngIf="searchParameter.id === -1; else searchFieldPrompt">
        <mat-form-field
          class="full-width"
          [matTooltip]="getSearchOperator(searchParameter)"
        >
          <mat-label transloco="KEYWORD_SEARCH"></mat-label>
          <input
            matInput
            type="text"
            [formControlName]="searchParameter.id"
            class="form-control"
          />
        </mat-form-field>
      </ng-container>
      <ng-template #searchFieldPrompt>
        <ng-container *ngIf="getField(searchParameter.fieldId) as searchField">
          <ng-container
            *ngIf="
              !search.settings.multiValue && fieldHasList(searchField);
              then listPrompt;
              else nonListSearchField
            "
          >
          </ng-container>

          <!-- All the templates for a search prompt that is backed by a field go here. -->
          <ng-template #listPrompt>
            <ng-container [ngSwitch]="searchField.list.type">
              <app-dropdown-field
                *ngSwitchCase="fieldListType.dropdown"
                [field]="searchField"
                [form-control]="
                  getFormControlFromSearchParameter(searchParameter.id)
                "
                [list-primary-filter-control]="
                  getFormControlFromField(searchField.list.primary)
                "
                [list-secondary-filter-control]="
                  getFormControlFromField(searchField.list.secondary)
                "
                [matTooltip]="getSearchOperator(searchParameter)"
              ></app-dropdown-field>
              <app-typeahead-field
                *ngSwitchCase="fieldListType.typeahead"
                [field]="searchField"
                [form-control]="
                  getFormControlFromSearchParameter(searchParameter.id)
                "
              ></app-typeahead-field>
            </ng-container>
          </ng-template>

          <!-- Basic (non list) search field. -->
          <ng-template #nonListSearchField>
            <mat-form-field
              class="full-width"
              [matTooltip]="getSearchOperator(searchParameter)"
            >
              <mat-label>{{ searchParameter.prompt }}</mat-label>
              <input
                *ngIf="!search.settings.multiValue; else multiValueSearch"
                matInput
                [type]="getPromptInputType(searchField.type)"
                [formControlName]="searchParameter.id"
                class="form-control"
              />
              <ng-template #multiValueSearch>
                <mat-chip-grid #multiValueSearchChipList>
                  <mat-chip-row
                    *ngFor="
                      let value of getMultiValueSearchValues(searchParameter.id)
                    "
                    (removed)="
                      removeMultiValueSearchValue(searchParameter.id, value)
                    "
                  >
                    {{ value }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input
                    placeholder="{{ 'ADDITIONAL_VALUE' | transloco }}..."
                    [type]="getPromptInputType(searchField.type)"
                    [matChipInputFor]="multiValueSearchChipList"
                    [matChipInputSeparatorKeyCodes]="
                      multiValueSearchSeparatorCodes
                    "
                    matChipInputAddOnBlur
                    (matChipInputTokenEnd)="
                      addMultiValueSearchValue(searchParameter.id, $event)
                    "
                    (paste)="
                      onMultiSearchValuePaste($event, searchParameter.id)
                    "
                  />
                </mat-chip-grid>
              </ng-template>
            </mat-form-field>
          </ng-template>
          <!-- All the templates for a search prompt that is backed by a field go here.  -->
        </ng-container>
      </ng-template>
    </p>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onReset()" transloco="RESET"></button>
  <span class="spacer"></span>
  <button mat-button mat-dialog-close transloco="CLOSE"></button>
  <button
    mat-button
    type="submit"
    form="promptForm"
    transloco="SEARCH"
  ></button>
</div>
