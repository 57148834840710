import { Component, Inject } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

import { ThumbnailProvider } from 'models';
import { THUMBNAIL_PROVIDER } from 'src/app/common/tokens';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';

import {
  ArchiveThumbnailSource,
  InboxThumbnailSource,
  ThumbnailSource,
} from '../thumbnail-source';

/** Thumbnail tooltip parameters. */
interface ThumbnailTooltipParameters extends ThumbnailSource, ITooltipParams {}

/** Thumbnail preview tooltip. */
@Component({
  selector: 'app-thumbnail-preview-tooltip',
  templateUrl: './thumbnail-preview-tooltip.component.html',
  styleUrls: ['./thumbnail-preview-tooltip.component.scss'],
})
export class ThumbnailPreviewTooltipComponent implements ITooltipAngularComp {
  /** Whether the image is still being loaded. */
  imageLoading = true;
  /** Thumnbail URL. */
  url: string;
  private tooltipParameters: ThumbnailTooltipParameters;

  constructor(
    private authService: AuthenticationService,
    private appQuery: ApplicationQuery,
    @Inject(THUMBNAIL_PROVIDER) private thumbnailProvider: ThumbnailProvider
  ) {}

  /** @inheritdoc */
  agInit(params: ThumbnailTooltipParameters): void {
    this.tooltipParameters = params;
    this.url = this.createThumbnailUrl();
  }

  private createArchiveThumbnailUrl(): string {
    const source = this.tooltipParameters.source as ArchiveThumbnailSource;
    const url = this.thumbnailProvider.getArchiveUrl(
      source.databaseId,
      source.archiveId,
      this.tooltipParameters.data.id,
      this.authService.user.token,
      this.tooltipParameters.data.secureId,
      1,
      this.appQuery.thumbnailHeight,
      this.appQuery.thumbnailWidth
    );

    return url;
  }

  private createInboxThumbnailurl(): string {
    const source = this.tooltipParameters.source as InboxThumbnailSource;
    return this.thumbnailProvider.getInboxUrl(
      source.inboxId,
      this.tooltipParameters.data.filename +
        this.tooltipParameters.data.fileType,
      this.authService.user.token,
      this.appQuery.thumbnailHeight,
      this.appQuery.thumbnailWidth,
      1
    );
  }

  private createThumbnailUrl(): string {
    if (
      this.tooltipParameters.sourceType === 'archive' ||
      this.tooltipParameters.sourceType === 'taskSearch' // Task search preview url is identical to regular archive url.
    ) {
      return this.createArchiveThumbnailUrl();
    } else {
      return this.createInboxThumbnailurl();
    }
  }
}
