import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { X2jOptions, XMLParser } from 'fast-xml-parser';
import {
  DocumentAnnotations,
  DocumentProvider,
  DocumentRevision,
  UserFriendlyError,
} from 'models';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, map, of } from 'rxjs';

import {
  ConverFreehandDataToPathAnnotation,
  ConvertCustomRectangleDataToRectangleAnnotation,
  ConvertCustomStampDataToStampAnnotation,
  ConvertEllipseDataToEllipseAnnotation,
  ConvertEmbeddedImageDataToImageAnnotation,
  ConvertLineDataToLineAnnotation,
  ConvertLinesDataToPathAnnotation,
  ConvertRectangleDataToRectangleAnnotation,
  ConvertTextDataToTextAnnotation,
  LayerDataItems,
  Point,
  S9ApiDocumentRevision,
  createDocumentRevisionFromApi,
} from '../models';

import { Square9ApiConfig } from './square9-api-config.model';
import { SQUARE9_API_CONFIG } from './square9-api-config.token';

/** Square 9 API Document Service. */
@Injectable({
  providedIn: 'root',
})
export class Square9ApiDocumentService implements DocumentProvider {
  private basePath: string;

  constructor(
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig,
    private httpClient: HttpClient,
    private logger: NGXLogger
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = `${apiUrl}`));
  }

  /** @inheritdoc */
  getArchiveDownloadUrl(
    databaseId: number,
    archiveId: number,
    documentId: number,
    token: string,
    secureId: string,
    withAnnotations: boolean = false
  ): string {
    return (
      `${this.basePath}` +
      `/dbs/${databaseId}` +
      `/archives/${archiveId}` +
      `/documents/${documentId}` +
      `/export` +
      `?token=${token}` +
      `&secureid=${secureId}` +
      `&withAnnotations=${withAnnotations}`
    );
  }

  /** @inheritdoc */
  getArchivePreviewUrl(
    databaseId: number,
    archiveId: number,
    documentId: number,
    token: string,
    secureId: string,
    withAnnotations: boolean = false
  ): string {
    return (
      `${this.basePath}` +
      `/dbs/${databaseId}` +
      `/archives/${archiveId}` +
      `/documents/${documentId}` +
      `/previewpdf` +
      `?token=${token}` +
      `&withAnnotations=${withAnnotations}` +
      `&secureid=${secureId}` +
      '&__ext=.pdf'
    );
  }

  /** @inheritdoc */
  getDocumentAnnotations(
    databaseId: number,
    archiveId: number,
    documentId: number,
    token: string,
    secureId: string
  ): Observable<DocumentAnnotations> {
    // Request the data.
    const url =
      `${this.basePath}` +
      `/dbs/${databaseId}` +
      `/archives/${archiveId}` +
      `/documents/${documentId}` +
      `/downloadannotationfile`;

    // Request raw XML.
    const atalasoftFormattedData = this.httpClient.get(url, {
      params: { token, secureid: secureId },
      headers: { accept: 'application/ssa' },
      responseType: 'text',
    });

    // Parse XML and convert into document annotations object types.
    const parsedAnnotationData = atalasoftFormattedData.pipe(
      map(this.extractAnnotationLayerDataItemsPages),
      map(this.convertLayerDataItemsPagesToAnnotationList)
    );

    // Return the correctly formatted DocumentAnnotations as an Observable.
    return parsedAnnotationData.pipe(
      catchError((error) => {
        if (error instanceof UserFriendlyError && error.error.status === 404) {
          this.logger.debug('No annotations were found for document.');
        } else {
          this.logger.error(
            'An unknown error occured while retrieving document annotations',
            error
          );
        }
        return of([] as DocumentAnnotations);
      })
    );
  }

  /** @inheritdoc */
  getDocumentRevisions(
    databaseId: number,
    archiveId: number,
    documentId: number,
    secureId: string,
    token: string
  ): Observable<DocumentRevision[]> {
    const url =
      `${this.basePath}` +
      `/dbs/${databaseId}` +
      `/archives/${archiveId}` +
      `/documents/${documentId}` +
      `/rev`;

    return this.httpClient
      .get<S9ApiDocumentRevision[]>(url, {
        params: new HttpParams().set('token', token).set('secureid', secureId),
      })
      .pipe(
        map((apiRevisions) =>
          apiRevisions.map((r) => createDocumentRevisionFromApi(r))
        )
      );
  }

  /** @inheritdoc */
  getImportFileUrl(filename: string): string {
    //String cachedFile, bool convert, int page = -1
    return `${this.basePath}/tempfiles?convert=false&cachedfile=${filename}`;
  }

  /** @inheritdoc */
  getInboxDownloadUrl(inboxId: number, filename: string): string {
    return `${this.basePath}/inboxes/${inboxId}?filename=${filename}`;
  }

  /** @inheritdoc */
  getInboxPreviewUrl(inboxId: number, filename: string): string {
    return `${
      this.basePath
    }/inboxes/${inboxId}?convertbw=false&filename=${encodeURIComponent(
      filename
    )}`;
  }

  /**
   * Convert a list of LayerDataItems (pages) and to DocumentAnnotations.
   *
   * This is where we perform all conversation between the Atalasoft objects
   * provided by an SSA file after deserializing, into new the app types.
   *
   * We loop over all pages in the annotation data set; and for any class of
   * annotations they contain we map them onto a new array of annotations of
   * each new type.
   *
   * @param pages LayerDataItems Array.
   * @returns DocumentAnnotations.
   */
  private convertLayerDataItemsPagesToAnnotationList = (
    pages: LayerDataItems[]
  ): DocumentAnnotations => {
    const annotations: DocumentAnnotations = [];
    for (const [pageIndex, layerDataItems] of pages.entries()) {
      // Add fill only rectangles.
      if (layerDataItems.CustomRectangleData) {
        const source = layerDataItems.CustomRectangleData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(
            ConvertCustomRectangleDataToRectangleAnnotation(item, pageIndex)
          );
        }
      }

      // Add rectangles.
      if (layerDataItems.RectangleData) {
        const source = layerDataItems.RectangleData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(
            ConvertRectangleDataToRectangleAnnotation(item, pageIndex)
          );
        }
      }

      // Add notes.
      if (layerDataItems.CustomTextData) {
        const notes = layerDataItems.CustomTextData;
        // Always work with an array.
        const noteArray = Array.isArray(notes) ? notes : [notes];
        for (const item of noteArray) {
          annotations.push(ConvertTextDataToTextAnnotation(item, pageIndex));
        }
      }

      // Add stamps.
      if (layerDataItems.CustomStampData) {
        const stamps = layerDataItems.CustomStampData;
        // Always work with an array.
        const stampArray = Array.isArray(stamps) ? stamps : [stamps];
        for (const item of stampArray) {
          annotations.push(
            ConvertCustomStampDataToStampAnnotation(item, pageIndex)
          );
        }
      }

      // Add ellipse drawings.
      if (layerDataItems.EllipseData) {
        const source = layerDataItems.EllipseData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(
            ConvertEllipseDataToEllipseAnnotation(item, pageIndex)
          );
        }
      }

      // Add images & signatures.
      if (layerDataItems.EmbeddedImageData) {
        const source = layerDataItems.EmbeddedImageData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(
            ConvertEmbeddedImageDataToImageAnnotation(item, pageIndex)
          );
        }
      }

      // "Custom Signatures" are just unburned images.
      if (layerDataItems.CustomSignatureData) {
        const source = layerDataItems.CustomSignatureData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(
            ConvertEmbeddedImageDataToImageAnnotation(item, pageIndex)
          );
        }
      }

      // Add freehand drawings (signatures).
      if (layerDataItems.FreehandData) {
        const source = layerDataItems.FreehandData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(ConverFreehandDataToPathAnnotation(item, pageIndex));
        }
      }

      // Add lines.
      if (layerDataItems.LineData) {
        const source = layerDataItems.LineData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(ConvertLineDataToLineAnnotation(item, pageIndex));
        }
      }

      // Add Multi-point lines.
      if (layerDataItems.LinesData) {
        const source = layerDataItems.LinesData;
        // Always work with an array.
        const items = Array.isArray(source) ? source : [source];
        for (const item of items) {
          annotations.push(ConvertLinesDataToPathAnnotation(item, pageIndex));
        }
      }
    }

    this.logger.debug('Converted Annotations:', annotations);

    // Return converted data.
    return annotations;
  };

  /**
   * Convert raw XML to  page seperated layer items.
   *
   * @param atalasoftAnnotationsXml Raw XML of Atalasoft annotations format.
   * @returns Pages of layer data items as array. 0 based page numbering.
   */
  private extractAnnotationLayerDataItemsPages = (
    atalasoftAnnotationsXml: string
  ) => {
    // Convert XML document to JS object.
    const alwaysArray = new Set(['root.a.c', 'root.b']);
    const options: Partial<X2jOptions> = {
      ignoreAttributes: true, // These wont be availble to the tag value processor anyway.
      attributeNamePrefix: '@_',
      ignoreDeclaration: true,
      preserveOrder: false, // This makes everything an array.
      isArray: (
        TagName: string,
        indexPath: string,
        IsLeafNode: boolean,
        IsAttribute: boolean
      ): boolean => {
        return alwaysArray.has(indexPath);
      },
      tagValueProcessor: (
        tagName: string,
        tagValue: string,
        IndexPath: string,
        HasAttributes: boolean,
        IsLeafNode: boolean
      ): any => {
        // If it is ImageData, leave the string.
        if (tagName === 'ImageData') return tagValue;
        // Looks like a boolean.
        if (tagValue === 'True' || tagValue === 'False') {
          return tagValue === 'True';
        }
        // Looks like a number (explude empty strings, only real numbers here)..
        if (
          tagValue !== '' &&
          tagValue !== ' ' &&
          !Number.isNaN(Number(tagValue))
        ) {
          return Number(tagValue);
        }
        // Looks like a datetime.
        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(tagValue)) {
          return new Date(tagValue);
        }
        // Looks like a size, that wasn't just a number.
        if (tagName === 'Size') {
          const [width, height] = tagValue.split(',');
          return { height: Number(height), width: Number(width) };
        }
        // Looks like a Point.
        const pointLike = /^-?\d+\.?\d*,-?\d+\.?\d*$/;
        if (pointLike.test(tagValue)) {
          const [x, y] = tagValue.split(',');
          return { x: Number(x), y: Number(y) } as Point;
        }
        // Looks like a list of points.
        const pointListLike = /-?\d+\.?\d*(,-?\d+\.?\d*)+/;
        if (pointListLike.test(tagValue)) {
          return tagValue.split(',').map((s) => Number(s));
        }
        return tagValue;
      },
      trimValues: true,
      removeNSPrefix: true,
    };

    // Parse the XML annotations using the configured parser rules.
    const parsedXml = new XMLParser(options).parse(
      atalasoftAnnotationsXml as string
    ) as any;

    // Select the complete layer data from the root XML object.
    const layerData = parsedXml.xmpmeta.RDF.Description[1].LayerDataCollection;

    // If no layer data is present, it will be an empty string parsed and we are done.
    if (layerData === '') return [];

    // Check if there are annotation beyond the first page, ensure paged array.
    if (!Array.isArray(layerData.LayerData)) {
      layerData.LayerData = [layerData.LayerData];
    }

    // Select only the layer data items (annotation layer) from the object and group them into a page array.
    const pagedLayerDataItems: LayerDataItems[] = layerData.LayerData.map(
      (l: any) => l?.Items?.Items ?? [] // Blank pages from merges may only contain `""` rather than a LayerData object.
    );

    this.logger.debug('XML parsed layer data items:', pagedLayerDataItems);
    return pagedLayerDataItems;
  };
}
