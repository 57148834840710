<div class="login-container">
  <div class="login-card-container">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>
          <span (dblclick)="onDoubleClickTitle()">
            <ng-content select="[login-title]"></ng-content>
          </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="isValidInstance">
          <form
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            *ngIf="showInternalUserLogin"
          >
            <mat-form-field appearance="fill">
              <mat-label transloco="USERNAME"></mat-label>
              <input
                matInput
                type="text"
                formControlName="username"
                class="form-control"
              />
              <mat-error *ngIf="this.f.username.invalid">
                {{ userInputError }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label transloco="PASSWORD"></mat-label>
              <input
                matInput
                type="password"
                formControlName="password"
                class="form-control"
              />
              <mat-error *ngIf="this.f.password.invalid">{{
                passwordInputError
              }}</mat-error>
            </mat-form-field>
            <ngx-login-button [disabled]="loading" [loading]="loadingBasic">
              <ngx-logo-svg button-icon></ngx-logo-svg>
              <span button-text transloco="LOGIN"></span>
            </ngx-login-button>
          </form>
          <ngx-divider-with-text
            *ngIf="
              (providersExist || guestLoginEnabled) && showInternalUserLogin
            "
            [text]="'OR' | transloco"
          ></ngx-divider-with-text>
          <ngx-login-button
            [disabled]="loading"
            [loading]="loadingGuest"
            *ngIf="guestLoginEnabled"
            (click)="onGuestLogin()"
          >
            <ngx-logo-svg button-icon></ngx-logo-svg>
            <span button-text transloco="LOGIN_AS_GUEST"></span>
          </ngx-login-button>
          <ngx-login-button
            *ngIf="ntlmAuthentication$ | async"
            [disabled]="loading"
            (click)="onWindowsLogin()"
          >
            <mat-icon
              button-icon
              class="svg-button-icon"
              svgIcon="microsoft"
            ></mat-icon>
            <span button-text>Windows</span>
          </ngx-login-button>
          <ng-container *ngIf="providersExist">
            <ngx-login-button
              *ngIf="hasProvider('okta')"
              background-color="#007dc1"
              color="#fff"
              [disabled]="loading"
              [loading]="loadingOkta"
              (click)="onOktaLogin()"
            >
              <div id="okta-circle" button-icon></div>
              <span button-text>Okta</span>
            </ngx-login-button>
            <ngx-login-button
              background-color="#334"
              color="#fff"
              *ngIf="hasProvider('azure')"
              [disabled]="loading"
              [loading]="loadingAzure"
              (click)="onMicrosoftLogin()"
            >
              <mat-icon
                class="svg-button-icon"
                svgIcon="microsoft"
                button-icon
              ></mat-icon>
              <span button-text class="microsoft-button-label">Microsoft</span>
            </ngx-login-button>
            <ngx-login-button
              *ngIf="hasProvider('onelogin')"
              background-color="#3a3e51"
              color="#fff"
              [disabled]="loading"
              [loading]="loadingOnelogin"
              (click)="onOneLogin()"
            >
              <div id="one-one" button-icon></div>
              <span button-text>OneLogin</span>
            </ngx-login-button>
            <ngx-login-button
              *ngIf="hasProvider('google')"
              background-color="#ffffff"
              color="#444"
              [disabled]="loading"
              [loading]="loadingGoogle"
              (click)="onGoogleLogin()"
            >
              <mat-icon
                class="svg-button-icon"
                svgIcon="google"
                button-icon
              ></mat-icon>
              <span button-text>Google</span>
            </ngx-login-button>
          </ng-container>
        </ng-container>
        <app-api-url-field
          [(url)]="instanceUrl"
          (isValidChanged)="isValidInstance = $event"
        ></app-api-url-field>
        <div id="error-box" *ngIf="error">
          <span [transloco]="error"></span>
          <button id="error-close-btn" mat-icon-button (click)="onClearError()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div id="tos">
          Your use of this service constitutes acceptance of the
          <a id="tos-link" [href]="tosUrl$ | async" target="_blank">Terms</a>.
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
  <div
    class="login-side-panel-container"
    *ngIf="
      (hideLoginSidePanel$ | async) === false &&
      (isSmallViewport$ | async) === false
    "
  >
    <ng-container *ngTemplateOutlet="sidePanel"></ng-container>
  </div>
</div>
<ng-container
  *ngIf="
    (hideLoginSidePanel$ | async) === false &&
    (isSmallViewport$ | async) === true
  "
>
  <ng-container *ngTemplateOutlet="sidePanel"></ng-container>
</ng-container>

<ng-template #sidePanel>
  <iframe id="side-frame" [src]="loginSidePanelUrl$ | async" frameborder="0">
  </iframe>
</ng-template>
