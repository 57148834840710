import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { assertExists } from 'lib/common/src/public-api';
import {
  SearchOptions,
  SearchPrompt,
  SearchProvider,
  createApiSearchPromptString,
} from 'models';
import { SEARCH_PROVIDER } from 'src/app/common/tokens';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

import { ViewTabItem } from '../../models/view-tab-item';

/**
 * Tab View.
 */
@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements OnInit {
  /** Active tab. */
  @Input()
  activeTab: ViewTabItem;
  /** Active tab emitter. */
  @Output()
  activeTabChange = new EventEmitter<ViewTabItem>();
  /** Search prompts from the user's search. */
  @Input()
  searchPrompts: SearchPrompt[];
  /** Selected tab index. */
  selectedIndex: number;
  /** Current set of view tabs. */
  viewTabs: ViewTabItem[];

  private defaultSearchOptionsForCount: SearchOptions = Object.freeze({
    page: 1,
    countOnly: true, // The important part.
    recordsPerPage: this.appQuery.archiveResultsPerPage,
    searchCriteria: '',
    sort: '',
    tabId: 0,
    targetArchiveId: 0,
  });

  constructor(
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private appQuery: ApplicationQuery,
    // TODO: The methods used from search provider perhaps should be barreled into the search service.
    @Inject(SEARCH_PROVIDER) private searchProvider: SearchProvider,
    private logger: NGXLogger
  ) {}

  /**
   *  Gets the tab count string to be displayed.
   *
   * @param tabCount Tab count.
   * @returns A string to be displayed as the tab count.
   */
  getTabCountString(tabCount: number): string {
    return tabCount >= this.appQuery.archiveResultsPerPage
      ? `${this.appQuery.archiveResultsPerPage}+`
      : tabCount.toString();
  }

  ngOnInit(): void {
    this.logger.debug('Tab View: Initializing.');
    this.loadTabCounts();
  }

  /**
   * Event handler for tab change.
   *
   * Sets the active tab to the selected index.
   *
   * @param value Value.
   */
  onTabChanged(value: number): void {
    this.activeTab = this.viewTabs[value];
    this.activeTabChange.emit(this.activeTab);
    this.logger.debug('Tab View: Selected tab changed:', this.activeTab);
  }

  /**
   * Run searches to get result counts for each tab.
   *
   * We skip the first tab, since that tab will get
   * a full search run on it for the grid, and it would be redundant.
   */
  private loadTabCounts() {
    this.searchesQuery.searchRouteParams$.subscribe({
      next: ([databaseId, archiveId, searchId, searchPrompts]) => {
        try {
          assertExists(databaseId);
          const archive = this.archivesQuery.getEntity(archiveId);
          assertExists(archive);
          // When the archive changes, create view tab items.
          this.viewTabs = archive.viewTabs.map((t) => new ViewTabItem(t, true));
          const tabCount = this.viewTabs.length;
          this.logger.debug(
            `Tab View: Active archive has ${tabCount ? tabCount : 'no'} tabs.`
          );
          // Get counts for each tab.
          for (const [index, tab] of this.viewTabs.entries()) {
            if (index === 0) {
              // Set the first tab as active.
              this.activeTab = tab;
              this.selectedIndex = 0;
              this.activeTabChange.emit(tab);
              this.logger.debug('Tab View: Setting the first tab as active.');
              // Skip the counts for first tab, it will need a full search anyway.
              continue;
            }
            // Build the search options.
            // TODO: We should probably have a CountSearchOptions type.
            const searchOptions: SearchOptions = {
              ...this.defaultSearchOptionsForCount,
              tabId: tab.description.id,
              targetArchiveId: this.archivesQuery.activeId,
              searchCriteria: createApiSearchPromptString(searchPrompts),
            };
            this.logger.debug(
              'Tab View: Running count search for: ',
              this.searchesQuery.active,
              '...with options:',
              searchOptions
            );
            // Execute the count search.
            assertExists(searchId);
            const search = this.searchesQuery.getEntity(searchId);
            assertExists(search);
            this.searchProvider
              .runForCount(databaseId, search, searchOptions)
              .subscribe({
                next: (count) => {
                  // Push the count result to the observable, and mark loading as complete.
                  this.logger.debug(
                    `Tab View: Setting count to "${count}" for "${tab.description.label}".`
                  );
                  tab.count = count;
                },
              });
          }
        } catch {
          this.logger.debug(
            'Tab View: No active archive, empty list of view tabs.'
          );
          this.viewTabs = [];
        }
      },
    });
  }
}
