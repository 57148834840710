<mat-form-field *ngIf="focused; else maskedFormField">
  <mat-label>{{ field.name }}</mat-label>
  <input
    #input
    max="9999-12-31"
    min="1753-01-01"
    matInput
    [ngxMatDatetimePicker]="picker"
    [formControl]="control"
    [appMultiValueHotkeys]="mvFieldMenu"
    (blur)="onBlur($event)"
    (focus)="onFocus()"
  />
  <mat-datepicker-toggle
    matSuffix
    [tabIndex]="-1"
    [for]="$any(picker)"
  ></mat-datepicker-toggle>
  <button
    *ngIf="field.multiValue"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <ngx-mat-datetime-picker
    #picker
    [enableMeridian]="true"
    [restoreFocus]="false"
    (closed)="onPickerClosed()"
  ></ngx-mat-datetime-picker>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>

<ng-template #maskedFormField>
  <mat-form-field>
    <mat-label>{{ field.name }}</mat-label>
    <input
      #maskedInput
      matInput
      type="text"
      [formControl]="maskedControl"
      (focus)="onMaskControlFocus()"
    />
    <mat-error
      *ngIf="maskedControl.invalid"
      class="ellipsis"
      [matTooltip]="maskedErrorMessage"
      >{{ maskedErrorMessage }}</mat-error
    >
  </mat-form-field>
</ng-template>
