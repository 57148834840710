/** This import isn't used but we need it to suppress typescript errors in jsdoc. */
import { AssertionError } from 'assert';

import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { PdfJsViewerComponent } from '@kariudo/ng2-pdfjs-viewer';
import * as pdfjs from '@kariudo/pdfjs/legacy/build/pdf.mjs';
import { TextItem } from '@kariudo/pdfjs/types/src/display/api';
import {
  PdfViewerComponent as NgPdfViewerComponent,
  PDFDocumentProxy,
  PDFProgressData,
} from 'ng2-pdf-viewer';
import { NGXLogger } from 'ngx-logger';
import { PDFDocument, PDFPage, StandardFonts } from 'pdf-lib';
import {
  EMPTY,
  Observable,
  catchError,
  concatMap,
  debounceTime,
  filter,
  from,
  last,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeLast,
  tap,
} from 'rxjs';

import { assert, assertExists, assertTypeByKey } from 'common';
import {
  DocumentAnnotations,
  ImageAnnotation,
  PathAnnotation,
  PdfOptions,
  StampAnnotation,
  TextAnnotation,
} from 'models';
import { NotificationService } from 'src/app/services/notification.service';

import { AppConfigQuery } from '../../app-config';
import * as utif from '../lib/utif';
import { PartialLoadData, PdfDocumentWorkload } from '../models';
import { PdfViewerService } from '../services/pdf-viewer.service';

import { PartialLoadSnackbarComponent } from './partial-load-snackbar/partial-load-snackbar.component';

/**
 * File types supported for client-side PDF conversion.
 */
type ConvertableFileType = 'png' | 'jpg' | 'jpeg' | 'tiff' | 'tif';

/**
 * PDF Viewer.
 *
 * This component implements two different viewer libraries.
 *
 * The library enabled for use is controlled by the `usePdfJs` input boolean merged
 * with the default value from the instance configuration for `usePdfJs`.
 *
 * The PdfJs viewer offeres the mozilla PDF.js viewer in a wrapped form, that
 * provides the user a familiar interface along with a lot of out of the box
 * functionality.
 *
 * @see https://github.com/kariudo/ng2-pdfjs-viewer
 *
 * The `ng-pdf-viewer` is more stripped down and provdies custom controls and
 * implmentation.
 *
 * @see https://github.com/legalthings/angular-pdfjs-viewer
 */
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PdfViewerService],
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
  /** Emits when document load state changes. */
  @Output()
  documentLoadingChange = new EventEmitter<boolean>();
  /** Go to the first page when the document loads. */
  @Input('go-to-first-page-on-load')
  goToFirstPageOnLoad = false;
  /** Event triggered when an error occurs while loading a document which prevents its open. */
  @Output()
  loadFailure: EventEmitter<any> = new EventEmitter();
  /** Event triggered when the page number changes. */
  @Output()
  pageChange = new EventEmitter<number>();
  /** Viewer component. */
  @ViewChild(NgPdfViewerComponent)
  pdfComponent: NgPdfViewerComponent;
  /** Viewer component for PdfJs mode. */
  @ViewChild(PdfJsViewerComponent)
  pdfJsComponent: PdfJsViewerComponent;
  /** Use PDF.js wrapped libray. */
  @Input('use-pdfjs')
  usePdfJs = this.appConfig.usePdfJs;
  /** Document Annotations. */
  documentAnnotations: DocumentAnnotations;
  /** Annotations loading state. */
  isAnnotationsLoading = false;
  /** True while the PDF.js viewer is processing a document, indicates it should be hidden. */
  isDocumentLoading = false;
  /** Loading progress percentage (0-100). */
  loadingProgressPercentage = 0;
  /**
   * Partial document is currently displayed.
   *
   * This is used to inform the user that we have not loaded the full document.
   */
  partialDocumentDisplayed = false;
  /** State of a partially loaded document. */
  partialLoadState?: PartialLoadData;
  /** Observable PDF Url. */
  pdfSrc$ = this.pdfViewerService.url$.pipe(
    tap(() => this.resetViewerModifiedState()),
    filter((url) => !!url),
    switchMap((url) => {
      // Ensure that the partial load snackbar is dismissed since the document is changing.
      this.partialLoadSnackbarReference?.dismiss();

      // Show loading progress bar and toast as required.
      const dismissLoadingAnnotationsToast = this.beginLoadingProgress();

      // Load a PDFDocument from the existing PDF URL to bytes as Observable.
      const pdfDocument$ = this.loadDocumentFromUrl(url);

      // Embed any fonts if needed and add annotations..
      const pdfBytes$ = pdfDocument$.pipe(
        // Embed any required fonts.
        switchMap((workload) => this.embedAllRequiredFonts(workload)),
        // Embed any images.
        switchMap((workload) => this.embedAllRequiredImages(workload)),
        // Apply all annotations.
        map((workload) => this.applyAllAnnotations(workload)),
        // Save the changes to the PDF document, and return the bytes.
        switchMap((workload) => this.saveWorkloadToBytes(workload)),
        // Hide annotation loading progress bar.
        tap(() => {
          this.completeLoadingProgress(dismissLoadingAnnotationsToast);
        })
      );

      // Return the result.
      return pdfBytes$.pipe(
        tap(() => {
          // PdfJs does not react to the URL changing with a refresh, queue a
          // refresh to occur by force after the URL is updated.
          if (this.usePdfJs && this.pdfJsComponent)
            setTimeout(() => this.pdfJsComponent.refresh());
        })
      );
    })
  );
  /** Currently reloading a complete version of the document after a partial load. */
  reloadingCompleteDocument = false;

  private partialLoadSnackbarReference?: MatSnackBarRef<PartialLoadSnackbarComponent>;
  private pdfBytes: Uint8Array;
  private pdfWorker: pdfjs.PDFWorker;

  constructor(
    @Self() private pdfViewerService: PdfViewerService,
    private logger: NGXLogger,
    private appConfig: AppConfigQuery,
    private httpClient: HttpClient,
    private notify: NotificationService,
    private translate: TranslocoService,
    private changeDetectorReference: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {
    // Set our worker source.
    pdfjs.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
    // Init a single pdf worker for this instance of pdf viewer.
    this.pdfWorker = new pdfjs.PDFWorker();
  }

  /**
   * Check if a file extension is client convertable.
   *
   * Case and `.` will be ignored.
   *
   * @param fileExtension File extension.
   * @returns True if the client can convert this format to a PDF for display.
   */
  static isClientConvertableExtension(fileExtension: string): boolean {
    return ['png', 'jpg', 'jpeg', 'tif', 'tiff'].includes(
      fileExtension.replace('.', '').toLowerCase()
    );
  }

  /**
   * Add a new page to a PDF with an embedded image.
   *
   * @param pdf The PDF to add the page to.
   * @param pdfConvertableBytes Image bytes (`ArrayBuffer` or Data URL `string`).
   * @param filetype File type of the image.
   * @returns The observable `PDFDocument` with embedded image.
   */
  private static addPageOfImage(
    pdf: PDFDocument,
    pdfConvertableBytes: ArrayBuffer | string,
    filetype: 'png' | 'jpg'
  ): Observable<PDFDocument> {
    const page = pdf.addPage();
    const embedPromise =
      filetype === 'png'
        ? pdf.embedPng(pdfConvertableBytes)
        : pdf.embedJpg(pdfConvertableBytes);
    return from(embedPromise).pipe(
      tap((pdfImage) => {
        // Set the new page size to match the image.
        page.setSize(pdfImage.width, pdfImage.height);
        // Draw the image to the new page.
        page.drawImage(pdfImage);
      }),
      // Return the obserable of the new PDF.
      map(() => pdf)
    );
  }
  /**
   * Is the URL convertable to PDF.
   *
   * @param url Url to check.
   * @returns Convertable file extension (i.e.; "png").
   */
  private static getUrlPdfConvertableType(
    url: string
  ): ConvertableFileType | undefined {
    const convertableQueryParameterValue = /&clientConvert=([^&]+)/
      .exec(url)?.[1]
      .toLowerCase();
    if (!convertableQueryParameterValue) return;
    assert(
      PdfViewerComponent.isClientConvertableExtension(
        convertableQueryParameterValue
      )
    );
    return convertableQueryParameterValue as ConvertableFileType;
  }

  /**
   * Is the byte array a PDF.
   *
   * Checks in the first 1024 bytes for the PDF format header "%PDF".
   *
   * @param arrayBuffer Buffer to test.
   * @returns True if the byte array contains a PDF.
   */
  private static isPdfArrayBuffer(arrayBuffer: ArrayBuffer): boolean {
    // Get up to the first 1024 bytes in the buffer.
    const bytes = new Uint8Array(
      arrayBuffer.slice(0, Math.min(1024, arrayBuffer.byteLength))
    );
    if (bytes?.length < 4) return false;
    for (let index = 0; index < bytes.length; index++)
      if (
        bytes[index] === '%'.charCodeAt(0) &&
        bytes[index + 1] === 'P'.charCodeAt(0) &&
        bytes[index + 2] === 'D'.charCodeAt(0) &&
        bytes[index + 3] === 'F'.charCodeAt(0)
      )
        return true;
    return false;
  }

  /**
   * Is the URL convertable to PDF.
   *
   * @param url Url to check.
   * @returns True if the url file type can be converted to PDF.
   */
  private static isUrlPdfConvertable(url: string): boolean {
    return !!PdfViewerComponent.getUrlPdfConvertableType(url);
  }

  /**
   * Runs find in document using the provided search text.
   *
   * @param searchText Search text.
   * @param caseSenstive Match case.
   * @param hightlightAll Highlight all matches.
   * @param wholeWord Consider the whole word.
   */
  findInDocument(
    searchText: string,
    caseSenstive: boolean,
    hightlightAll: boolean,
    wholeWord: boolean
  ): void {
    assert(this.usePdfJs, 'PDFjs must be used to run find in document.');
    this.pdfJsComponent.PDFViewerApplication.findBar.open();
    this.pdfJsComponent.PDFViewerApplication.findBar.findField.value =
      searchText;
    this.pdfJsComponent.PDFViewerApplication.findBar.caseSensitive.checked =
      caseSenstive;
    this.pdfJsComponent.PDFViewerApplication.findBar.highlightAll.checked =
      hightlightAll;
    this.pdfJsComponent.PDFViewerApplication.findBar.entireWord.checked =
      wholeWord;
    this.pdfJsComponent.PDFViewerApplication.findBar.findNextButton.click();
  }

  /**
   * Get page words.
   *
   * @param pageNumber - Page number.
   * @param canvasWidth - Canvas width.
   * @returns - Observable of page.
   */
  getPageWords(pageNumber: number, canvasWidth: number): Observable<any> {
    // Make a copy because pdf.js flushes the bytes when running getDocument.
    const pdfBytes = new Uint8Array(this.pdfBytes);
    const document = from(
      pdfjs.getDocument({ data: pdfBytes, worker: this.pdfWorker }).promise
    );
    return document.pipe(
      switchMap((document) => from(document.getPage(pageNumber))),
      switchMap((page) =>
        from(
          page.getTextContent({
            keepWhiteSpace: true,
            includeTextContentChars: true,
          } as any)
        ).pipe(
          map((content) => {
            const words = [];
            for (const item of content.items) {
              assertTypeByKey<TextItem>(item, 'height', 'number');
              if (!item.chars) continue;
              const height = page.getViewport({ scale: 1 }).viewBox[3],
                width = page.getViewport({ scale: 1 }).viewBox[2],
                scale = canvasWidth / width,
                wordY =
                  (height - Number(item.transform[5]) - item.height) * scale,
                wordHeight = item.height;
              // If a split occurs, we figure out x-coordinates and widths after splitting the string up.
              const splitWords = item.str.split(' ');
              if (splitWords.length > 1) {
                const spaceWidth = this.getSpaceWidth(item);
                let wordX = item.transform[4];
                for (const index in splitWords) {
                  //Calculate word width.
                  let wordWidth = 0;
                  for (const char of [...splitWords[index]]) {
                    const charWidth = item.chars.find(
                      (c) => c.unicode === char
                    )?.width;
                    if (!charWidth) break;
                    wordWidth += charWidth;
                  }
                  const newWord: any = {
                    text: splitWords[index],
                    height: wordHeight * scale,
                    fontName: content.styles[item.fontName],
                    width: wordWidth * scale,
                    x: wordX * scale,
                    y: wordY,
                  };
                  words.push(newWord);
                  //Update x-coordinate for the next word.
                  wordX = splitWords[Number(index) + 1]
                    ? wordX + wordWidth + spaceWidth
                    : wordX + wordWidth;
                  wordWidth = 0;
                }
              } else {
                // No split. Use word with no manipulation.
                const word: any = {
                  text: item.str,
                  height: item.height * scale,
                  fontName: content.styles[item.fontName],
                  width: item.width * scale,
                  x: item.transform[4] * scale,
                  y: (height - Number(item.transform[5]) - item.height) * scale,
                };
                words.push(word);
              }
            }
            return words;
          })
        )
      )
    );
  }

  ngAfterViewInit(): void {
    // Register the PDF component with the service.
    try {
      assertExists(this.pdfComponent);
      this.pdfViewerService.registerPdfComponent(this.pdfComponent);
    } catch {
      assertExists(this.pdfJsComponent);
      this.fixPdfJsIframeRendering();
      this.pdfJsComponent.onPageChange
        .pipe(debounceTime(1))
        .subscribe((newPage) => (this.pageNumber = newPage));
    }
  }

  ngOnDestroy(): void {
    // Close the partial load snackbar if it is still open.
    if (this.partialLoadSnackbarReference) {
      this.partialLoadSnackbarReference.dismiss();
    }
  }

  /** PDF options. */
  @Input('pdf-options')
  set pdfOptions(value: PdfOptions) {
    // Set the annotations first, so they can be checked during the URL pipeline.
    this.documentAnnotations = value.annotations;
    this.pdfViewerService.url = value.url;
  }

  /**
   * Gets the content window for the PDF JS document.
   *
   * @returns The content window.
   * @throws {AssertionError} If the page element does not exist or if you are not using the ng2-pdfjs-viewer module.
   */
  get contentWindow(): Window {
    assert(
      this.usePdfJs,
      'Only the ng2-pdfjs-viewer module is supported at this time.'
    );
    const contentWindow = this.pdfJsComponent.iframe.nativeElement
      .contentWindow as Window | undefined;
    assertExists(contentWindow, 'Content window must exist.');
    return contentWindow;
  }

  /**
   * Gets the page element for the current page.
   *
   * @returns An HTMLDivElement for the current page.
   * @throws {AssertionError} If the page element does not exist or if you are not using the ng2-pdfjs-viewer module.
   */
  get pageElement(): HTMLDivElement {
    assert(
      this.usePdfJs,
      'Only the ng2-pdfjs-viewer module is supported at this time.'
    );
    const pageElement =
      this.pdfJsComponent.iframe.nativeElement.contentWindow.document.querySelector(
        '.pdfViewer [data-page-number="' + this.pageNumber + '"]'
      ) as HTMLDivElement | undefined;
    assertExists(pageElement, 'Page element must exist.');
    return pageElement;
  }

  /**
   * Get the current page page number, tracked in service.
   *
   * @returns Page number.
   */
  get pageNumber() {
    return this.pdfViewerService.pageNumber;
  }

  /**
   * Update the current page number, tracked in service.
   *
   * @param value New page number.
   */
  set pageNumber(value: number) {
    this.pdfViewerService.pageNumber = value;
    this.pageChange.emit(value);
  }

  /** Get the current user requested zoom scale.
   *
   * @returns Zoom scale value.
   */
  get zoomScale() {
    return this.pdfViewerService.zoomScale;
  }

  /**
   * Event handler for click of Load Complete Document button.
   */
  onClickLoadCompleteDocument(): void {
    this.partialDocumentDisplayed = false;
    this.reloadingCompleteDocument = true;
    // Invoke URL setter to reload.
    this.pdfViewerService.url = this.pdfViewerService.url;
  }

  /**
   * Event handler for completetion of PDF load.
   *
   * Only used by basic viewer mode, Advanced viewer has its own, @see onPdfJsDocumentLoad.
   *
   * @param pdf Loaded PDF document.
   */
  onLoadComplete(pdf: PDFDocumentProxy): void {
    this.reloadingCompleteDocument = false;
    this.pdfViewerService.pageCount = pdf.numPages;
    this.setIsDocumentLoading(false);
    this.loadingProgressPercentage = 100; // Ensure completed.
    if (this.goToFirstPageOnLoad) {
      this.pageNumber = 1;
    }
    this.logger.debug('PDF preview loaded.', pdf);
  }

  /**
   * Event handler for after PdfJs loads.
   */
  onPdfJsDocumentLoad(): void {
    this.reloadingCompleteDocument = false;
    this.fixPdfJsApplicationRendering();
    this.setIsDocumentLoading(false);
    this.changeDetectorReference.detectChanges();
    if (this.partialDocumentDisplayed) {
      this.partialLoadSnackbarReference = this.snackBar.openFromComponent(
        PartialLoadSnackbarComponent,
        {
          data: this.partialLoadState,
          horizontalPosition: 'end',
        }
      );
      this.partialLoadSnackbarReference
        .onAction()
        .subscribe(() => this.onClickLoadCompleteDocument());
    }
  }

  /**
   * Event handler for loading progress of PDF.
   *
   * @param progressData PDF progress data.
   */
  onProgress(progressData: PDFProgressData): void {
    this.loadingProgressPercentage = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    this.logger.debug('PDF load progress.', progressData);
  }

  /**
   * Add a new page to a PDF indicating the document was partially converted.
   *
   * @param pdf The PDF to add the page to.
   * @param partialLoadData The state of partial load.
   * @returns The observable `PDFDocument` with appended page.
   */
  private addPartialDocumentPage(
    pdf: PDFDocument,
    partialLoadData: PartialLoadData
  ): Observable<PDFDocument> {
    const page = pdf.addPage();
    const translatedMessage$ = this.translate.selectTranslate(
      'PARTIALLY_CONVERTED_PDF_PAGE_MSG',
      partialLoadData
    );
    const pdfWithNewPage$ = translatedMessage$.pipe(
      map((text: string) => {
        page.setSize(600, 90);
        page.drawText(text, {
          size: 20,
          x: 30,
          y: 48,
          maxWidth: 540,
        });
        return pdf;
      })
    );
    return pdfWithNewPage$;
  }

  /**
   * Apply all of the context's Document Annotations to the workload.
   *
   * @param workload Workload to apply annotations to.
   * @returns Workload with annotations applied.
   */
  private applyAllAnnotations(
    workload: PdfDocumentWorkload
  ): PdfDocumentWorkload {
    // Get the documents pages.
    const pages = workload.document.getPages();
    // Loop and apply each annotation.
    for (const annotation of this.documentAnnotations) {
      // If in a partial load (conversion) state:
      if (this.partialDocumentDisplayed) {
        assertExists(this.partialLoadState);
        // Skip any annotations on pages greater than those included.
        if (annotation.page >= this.partialLoadState.loaded) continue;
      }
      // Get the page the annotation should be applied to.
      let page: PDFPage;
      try {
        page = pages[annotation.page];
        assertExists(page, 'Page does not exist.');
      } catch {
        // If we can't obtain the index that corresponds to the annotations
        // page, then something is wrong with that annotation or worse,
        // report an error and continue to the next annoation.
        this.logger.error(
          'Annotation page could not be obtained in PDF document.'
        );
        continue;
      }

      // Associate the embedded font if required.
      if (annotation.hasFont) {
        assert(annotation instanceof TextAnnotation);
        annotation.embedFont(workload.embeddedFonts[annotation.font]);
      }

      // Set the scale factor for annotation cooridanate adjustment.
      const pageScale =
        typeof workload.annotationScale === 'number'
          ? workload.annotationScale
          : workload.annotationScale[annotation.page];
      if (pageScale) annotation.scaleFactor = pageScale;

      // Draw the annotation on the page.
      annotation.drawOnPage(page, true, true);
    }

    return workload;
  }

  /**
   * Show annotations loading progress bar, and toast if applicable.
   * This will hide the viewer itself while loading as well.
   *
   * @returns Function to dismiss loading toast.
   */
  private beginLoadingProgress() {
    this.isAnnotationsLoading = true;
    this.setIsDocumentLoading(true);
    this.changeDetectorReference.detectChanges();
    // Reset progress bar.
    this.loadingProgressPercentage = 0;
    // Reset partial document state to fresh.
    this.partialDocumentDisplayed = false;
    this.partialLoadState = undefined;
    if (this.partialLoadSnackbarReference instanceof MatSnackBarRef) {
      this.partialLoadSnackbarReference.dismiss();
    }

    // Show loading annotations toast if required.
    const dismissLoadingAnnotationsToast =
      this.documentAnnotations.length > 0
        ? this.notify.info('LOADING_ANNOTATIONS')
        : () => {};
    return dismissLoadingAnnotationsToast;
  }

  /**
   * Complete the loading progress state.
   *
   * @param dismissLoadingAnnotationsToast Annotation loading toast callback function.
   */
  private completeLoadingProgress(dismissLoadingAnnotationsToast: () => void) {
    this.logger.debug('Document annotation modificaton complete.');
    dismissLoadingAnnotationsToast();
    // Switch from showing progress bar to showing then pdf viewer.
    this.isAnnotationsLoading = false;
  }

  /**
   * Convert a TIFF to embedded PDF pages (as PNG).
   *
   * Warning: It could cause a problem here for memory consumption
   * if there are a lot of pages.
   * We may need to add limitations here to force a bailout.
   *
   * @todo Add a TIFF specific progress bar, it can be determinate since we know.
   *
   * @param tiffBytes Tiff byte buffer to convert and embed.
   * @param pdfDocument PDFDocument to embed images in.
   * @param limitPagesLoaded Limit the pages loaded to speed up load time. (0 is unlimited, default 5).
   * @returns PDF document with images embedded.
   */
  private convertTiffToEmbeddedPdfPages(
    tiffBytes: ArrayBuffer,
    pdfDocument: PDFDocument,
    limitPagesLoaded: number = 5
  ) {
    const scales: number[] = [];
    // Decode TIFF container data.
    const ifds = utif.decode(tiffBytes);
    this.logger.debug(`Decoded TIFF container with ${ifds.length} pages.`);
    // For each frame, decode, rescale, and convert to data URL with canvas.
    const framesToDecode$ = limitPagesLoaded
      ? from(ifds).pipe(take(limitPagesLoaded))
      : from(ifds);
    return framesToDecode$.pipe(
      map((frame, index) => {
        // Decode the target frame.
        utif.decodeImage(tiffBytes, frame);
        // Note. The t282 property appears to contain a DPI.
        // If we need to use this to offset against relative scale etc..
        // const dpi: number = frame.t282 ? (frame.t282 as any)[0][0] : 72;

        this.logger.debug(`Decoded TIFF page ${index + 1} of ${ifds.length}.`);
        // Get pixel byte array.
        const rbga = utif.toRGBA8(frame);
        // Create and configure a new canvas.
        let canvas = document.createElement('canvas');
        canvas.width = frame.width;
        canvas.height = frame.height;
        const context = canvas.getContext('2d');
        assertExists(context);
        // Create a new ImageData object.
        const imageData = context.createImageData(frame.width, frame.height);
        // Fill the image data with the decoded frame bytes.
        imageData.data.set(rbga);
        // Add the image to the canvas.
        context.putImageData(imageData, 0, 0);
        // If the image is larger than our "max size box", scale it.
        const { scale, scaledCanvas } = this.restrictCanvasToMaxSize(
          frame,
          canvas,
          1200
        );
        // Apply canvas.
        canvas = scaledCanvas;
        // Track the scale applied to the page.
        scales[index] = scale;
        // Collect the data URL from the rendered ImageData.
        const data = canvas.toDataURL();
        // Add the PNG to the array to be embedded, and scale.
        return data;
      }),
      tap(() => {
        // Determine if we are displaying a partial document.
        this.partialDocumentDisplayed =
          limitPagesLoaded !== 0 && limitPagesLoaded < ifds.length;
        // Update partial load state, clear it if load was complete.
        this.partialLoadState = this.partialDocumentDisplayed
          ? {
              loaded: limitPagesLoaded,
              total: ifds.length,
            }
          : undefined;
      }),
      // Embed each (possibly scaled) image as a new page.
      concatMap((data) => {
        return PdfViewerComponent.addPageOfImage(pdfDocument, data, 'png');
      }),
      // Once all data URL are added, just emit the final PDF.
      last(),
      switchMap(() => {
        // If this was a partial load, add a page with explanation before returing the PDF.
        if (this.partialDocumentDisplayed) {
          assertExists(this.partialLoadState);
          return this.addPartialDocumentPage(
            pdfDocument,
            this.partialLoadState
          );
        }
        // Otherwise just return the PDF as is.
        return of(pdfDocument);
      }),
      tap(() => {
        this.logger.debug(
          'Completed processing all TIFF frames to PNG, embedded.'
        );
      }),
      // Include any scales in the return.
      map(() => ({ pdfDocument, scales }))
    );
  }

  /**
   * Create a PDF from an image byte array.
   *
   * @param arrayBuffer$ Observable `ArrayBuffer` of support image.
   * @param type Image type for conversion.
   * @returns Observable `PDFDocument` of the image.
   */
  private createNewPdfFromConvertable(
    arrayBuffer$: Observable<ArrayBuffer>,
    type: ConvertableFileType
  ): Observable<PdfDocumentWorkload> {
    return arrayBuffer$.pipe(
      // Create a PDF document object from the fetched byte data.
      switchMap((pdfConvertableBytes: ArrayBuffer) => {
        assertExists(type);
        this.logger.debug(
          `Converting ${type.toUpperCase()} to PDF.`,
          pdfConvertableBytes
        );
        const pdf$ = from(PDFDocument.create()).pipe(
          switchMap((pdf) => {
            let pdfDocumentWorkload$: Observable<PdfDocumentWorkload>;
            // Process the conversion and embed according to file type.
            switch (type) {
              case 'png':
                // Embed the PNG to a new PDF page.
                pdfDocumentWorkload$ = PdfViewerComponent.addPageOfImage(
                  pdf,
                  pdfConvertableBytes,
                  'png'
                ).pipe(
                  map((pdfDocument) => new PdfDocumentWorkload(pdfDocument))
                );
                break;
              case 'jpg':
              case 'jpeg':
                // Embed the JPEG to a new PDF page.
                pdfDocumentWorkload$ = PdfViewerComponent.addPageOfImage(
                  pdf,
                  pdfConvertableBytes,
                  'jpg'
                ).pipe(
                  map((pdfDocument) => new PdfDocumentWorkload(pdfDocument))
                );
                break;
              case 'tif':
              case 'tiff':
                // Decode TIFF image data.
                pdfDocumentWorkload$ = this.convertTiffToEmbeddedPdfPages(
                  pdfConvertableBytes,
                  pdf,
                  // If the document is being reloaded on a request for complete, remove page load limit.
                  this.reloadingCompleteDocument ? 0 : undefined
                ).pipe(
                  map(
                    ({ pdfDocument, scales }) =>
                      new PdfDocumentWorkload(pdfDocument, undefined, scales)
                  )
                );
                break;
              default:
                // TODO: may need to throw for error handling here instead of empty?
                this.logger.error('Unsupported converstion to PDF.');
                return EMPTY;
            }
            return pdfDocumentWorkload$;
          })
        );
        return pdf$;
      })
    );
  }

  /**
   * Embed any required fonts in a `PDFDocumentWorkload`.
   *
   * @param workload Workload.
   * @returns Workload, with any required fonts embedded.
   */
  private embedAllRequiredFonts(
    workload: PdfDocumentWorkload
  ): Observable<PdfDocumentWorkload> {
    // Scan the list of annotations to determine what unqiue fonts we need.
    const allRequiredFonts = new Set(
      this.documentAnnotations
        .filter((a) => a.hasFont)
        .flatMap((a) => (a as TextAnnotation).requiredFonts)
    );
    // If there are annotations that require fonts.
    if (allRequiredFonts.size > 0) {
      // Embed fonts.
      return from(allRequiredFonts).pipe(
        mergeMap((font) => this.embedFontInWorkflow(workload, font)),
        takeLast(1)
      );
    } else {
      // No fonts needed, return the workload observable.
      return of(workload);
    }
  }

  /**
   * Embed any required images in a `PDFDocumentWorkload`.
   *
   * @param workload Workload.
   * @returns Workload, with any required images embedded.
   */
  private embedAllRequiredImages(
    workload: PdfDocumentWorkload
  ): Observable<PdfDocumentWorkload> {
    // Scan the list of annotations to determine if images need to be embedded.
    const annotationsWithImages = this.documentAnnotations.filter(
      (a) =>
        a instanceof ImageAnnotation ||
        a instanceof StampAnnotation ||
        a instanceof PathAnnotation
    ) as (ImageAnnotation | StampAnnotation)[];
    // If there are annotations that require images.
    if (annotationsWithImages.length > 0) {
      // Embed images in PDF.
      return from(annotationsWithImages).pipe(
        // Embed each image.
        mergeMap((annotationWithImage) =>
          this.embedImageInWorkflow(workload, annotationWithImage)
        ),
        // Emit the workload when done.
        takeLast(1)
      );
    } else {
      // No images needed, return the workload observable.
      return of(workload);
    }
  }

  /**
   * Embed a font for an annotation into the PDF document.
   *
   * @param workload PDF document workload.
   * @param font Font to embed.
   * @returns The observable PDF document workload.
   */
  private embedFontInWorkflow(
    workload: PdfDocumentWorkload,
    font: StandardFonts
  ) {
    return from(workload.document.embedFont(font)).pipe(
      map((pdfFont) => {
        workload.embeddedFonts[font] = pdfFont;
        return workload;
      })
    );
  }

  /**
   * Embed a PNG image for an annotation into the PDF document.
   *
   * @param workload PDF document workload.
   * @param imageAnnotation Annotation with image.
   * @returns The observable PDF document workload.
   */
  private embedImageInWorkflow(
    workload: PdfDocumentWorkload,
    imageAnnotation: ImageAnnotation | StampAnnotation | PathAnnotation
  ) {
    const pngDataUri = imageAnnotation.toPng();
    const embedPromise = workload.document.embedPng(pngDataUri);
    return from(embedPromise).pipe(
      map((pdfImage) => {
        this.logger.debug('Embedding image in PDF.');
        imageAnnotation.embedImage(pdfImage);
        return workload;
      }),
      catchError((error) => {
        this.logger.error('Error while embedding image.', error);
        return EMPTY;
      })
    );
  }

  /**
   * Fixes for how the PdfJs wrapped application renders.
   */
  private fixPdfJsApplicationRendering() {
    const viewerConfig = this.pdfJsComponent.PDFViewerApplication.appConfig;
    // Remove horizontal scrollbar from thumbnailer.
    viewerConfig.sidebar.thumbnailView.style.overflowX = 'hidden';
  }

  /** Fixes for how the `<iframe>` element is presented from PdfJs library. */
  private fixPdfJsIframeRendering() {
    const iframeElement = this.pdfJsComponent.iframe.nativeElement;
    // Hide the frame border.
    iframeElement.frameBorder = 0;
    // Correctly fill the container.
    iframeElement.style.position = 'absolute';
    iframeElement.style.height = '100%';
    iframeElement.style.width = '100%';
  }

  /**
   * Gets the size of a space in from the passed item.
   *
   * @param item - Text content item.
   * @returns - Space size.
   */
  private getSpaceWidth(item: any) {
    let charTotal = 0;
    for (const charI in item.chars) {
      charTotal += item.chars[charI].width;
    }
    const difference = item.width - charTotal;
    /**
     * If the width is 0 then that means spaces are included in the chars array,
     * if the width is less than 1 the spaces are probably included however commas may exist
     * on the line, and we can get the width of spaces from there.
     */
    const spaceWidth =
      difference > 1
        ? difference / (item.str.split(' ').length - 1)
        : item.chars.find((c: any) => c.unicode === ' ')?.width;
    return spaceWidth;
  }

  /**
   * Load a `PDFDocument` by bytes from a provided URL.
   *
   * @param url URL to load.
   * @returns An observable PDF document.
   */
  private loadDocumentFromUrl(url: string) {
    const arrayBuffer$ = this.httpClient.get(url, {
      responseType: 'arraybuffer',
    });
    // If document is not a PDF, but is a PDF convertable type, create a PDF.
    if (PdfViewerComponent.isUrlPdfConvertable(url)) {
      const fileType = PdfViewerComponent.getUrlPdfConvertableType(url);
      assertExists(fileType);
      return this.createNewPdfFromConvertable(arrayBuffer$, fileType);
    }

    // Return the retrieved PDF document.
    const pdfDocument$ = arrayBuffer$.pipe(
      catchError((error) => {
        this.logger.error(error);
        this.loadFailure.emit({ error });
        throw error;
      }),
      // Create a PDF document object from the fetched byte data.
      switchMap((originalPdfBytes) => {
        // Confirm the byte array contains a PDF.
        if (!PdfViewerComponent.isPdfArrayBuffer(originalPdfBytes)) {
          // Document is not supported.
          this.logger.error(
            'Document buffer was not convertable client-side and does not contain a PDF.'
          );
        }
        return from(PDFDocument.load(originalPdfBytes)).pipe(
          catchError((error) => {
            // Notify the user so there is no question about whether or not the document is going to load.
            this.logger.error('Failed to load document.', error);
            this.notify.error({
              i18n: 'FAILED_TO_LOAD_DOCUMENT',
              error,
              description: 'Failed to load document.',
            });

            /*
            Return a new PDF with an error message in it so that the rest of the load process can complete.
            This allows the viewer to display other documents if the user clicks next/previous.
            */
            return from(PDFDocument.create()).pipe(
              tap((pdf) => {
                const page = pdf.addPage([600, 90]);
                // This PDF can not be displayed, it may be encrypted or corrupted.
                const translationKey = this.translate.translate(
                  'ERROR_ENCRYPTED_OR_CORRUPTED'
                );
                page.drawText(translationKey, {
                  size: 20,
                  x: 30,
                  y: 48,
                  maxWidth: 540,
                });
              })
            );
          })
        );
      })
    );
    // Return a new PDF document workload.
    return pdfDocument$.pipe(
      map((document) => new PdfDocumentWorkload(document))
    );
  }

  /**
   * Attempts to reset the viewer library from seeing a document as modified.
   *
   * This prevents errors/popups when changing URL in states like modified
   * AcroForm data etc.
   *
   * @returns {void} No return value.
   */
  private resetViewerModifiedState(): void {
    try {
      // Call into the iFrame's pdfdocument annotation storage to reset the modified state, if it exists.
      this.pdfJsComponent?.iframe?.nativeElement?.contentWindow?.PDFViewerApplication?.pdfDocument?.annotationStorage?.resetModified();
    } catch {
      // Do nothing.
    }
  }

  /**
   * Restrict the size of TIFF output using a scanvas to the max bitmap size.
   *
   * @param tiffFrame TIFF frame.
   * @param canvas Source canvas.
   * @param maxSize Size in pixels to restrict the square to.
   * @returns A new canvas scaled if required, otherwise the original canvas, and the scale applied.
   */
  private restrictCanvasToMaxSize(
    tiffFrame: utif.Ifd,
    canvas: HTMLCanvasElement,
    maxSize: number = 1200
  ): {
    /** Scale factor. */
    scale: number;
    /** Scaled canvas. */
    scaledCanvas: HTMLCanvasElement;
  } {
    let scale = 1;
    if (tiffFrame.width > maxSize || tiffFrame.height > maxSize) {
      const scaledCanvas = document.createElement('canvas');
      // Scale canvas with aspect ratio.
      scaledCanvas.width =
        tiffFrame.width > maxSize && tiffFrame.width > tiffFrame.height
          ? maxSize
          : tiffFrame.width * (maxSize / tiffFrame.height);
      scaledCanvas.height =
        tiffFrame.height > maxSize && tiffFrame.height > tiffFrame.width
          ? maxSize
          : tiffFrame.height * (maxSize / tiffFrame.width);
      this.logger.debug(
        `Large TIFF image, scaling output size to w:${scaledCanvas.width} x h:${scaledCanvas.height}.`
      );
      const scaledContext = scaledCanvas.getContext('2d');
      // Add the source canvas as an image at the new scale to the new canvas.
      scaledContext?.drawImage(
        canvas,
        0,
        0,
        scaledCanvas.width,
        scaledCanvas.height
      );
      scale = scaledCanvas.height / canvas.height;
      canvas = scaledCanvas;
      this.logger.debug('Image scaled to fit size restriction, factor:', scale);
    }
    return { scaledCanvas: canvas, scale };
  }

  /**
   * Save the PDF document workload to a byte array.
   *
   * @param workload Workload.
   * @returns Byte array.
   */
  private saveWorkloadToBytes(
    workload: PdfDocumentWorkload
  ): Observable<Uint8Array> {
    // Serialize the PDFDocument to data URL.
    return from(workload.document.save()).pipe(
      tap((pdfBytes) => {
        this.pdfBytes = pdfBytes;
      })
    );
  }

  private setIsDocumentLoading(isLoading: boolean) {
    this.isDocumentLoading = isLoading;
    this.documentLoadingChange.emit(isLoading);
  }
}
