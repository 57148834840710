import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { HotkeysService } from '@ngneat/hotkeys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  AgGridEvent,
  CellValueChangedEvent,
  GridApi,
  GridOptions,
  NewColumnsLoadedEvent,
  RowDoubleClickedEvent,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  RowValueChangedEvent,
} from 'ag-grid-community';
import { NGXLogger } from 'ngx-logger';
import {
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  debounceTime,
  finalize,
  first,
  interval,
  map,
} from 'rxjs';

import { assertExists } from 'common';
import {
  ArchiveSessionDocument,
  DocumentUpdateProvider,
  FieldDataType,
  FieldValues,
  SearchOptions,
  SearchPrompt,
  SearchResult,
  SearchResults,
  UserFriendlyError,
  createApiSearchPromptString,
  createArchiveSessionDocumentFromSearchResult,
} from 'models';
import { DOCUMENT_UPDATE_PROVIDER } from 'src/app/common/tokens';
import { isSameMoment } from 'src/app/common/utility';
import {
  ExportConfigDialogComponent,
  ExportConfigResult,
} from 'src/app/components/export-config-dialog/export-config-dialog.component';
import {
  ArchiveGridData,
  DirtyComponent,
  DocumentUpdateSession,
  PageChangeEvent,
  SearchResultDocumentOpenRequest,
  ViewTabItem,
} from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GridHelperService } from 'src/app/services/grid-helper.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchUIService } from 'src/app/services/search-ui.service';
import { TableFieldUIService } from 'src/app/services/table-field-ui.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { FieldsQuery } from 'src/app/state/fields/fields.query';
import { GridSettingsService } from 'src/app/state/grid/grid-states.service';
import { SearchesQuery } from 'src/app/state/searches/searches.query';
import { SearchesService } from 'src/app/state/searches/searches.service';

import { getPageSizes } from '../../common/page-sizes';
import { ArchiveActionsMenuComponent } from '../archive-actions-menu/archive-actions-menu.component';
import { ArchiveActionsToolbarComponent } from '../archive-actions-toolbar/archive-actions-toolbar.component';
import { FieldCellEditorBaseComponent } from '../grid-cell-components/field-cell-editor-base.component';
import { TableFieldGridComponent } from '../table-field-grid/table-field-grid.component';

/**
 * Archive View.
 */
@UntilDestroy()
@Component({
  selector: 'app-archive-view',
  templateUrl: './archive-view.component.html',
  styleUrls: ['./archive-view.component.scss'],
})
export class ArchiveViewComponent implements OnInit, DirtyComponent {
  /** Archive actions menu instance for context menu. */
  @ViewChild(ArchiveActionsMenuComponent)
  archiveActionsMenu: ArchiveActionsMenuComponent;
  /** Archive actions toolbar instance. */
  @ViewChild(ArchiveActionsToolbarComponent)
  archiveActionsToolbar: ArchiveActionsToolbarComponent;
  /** AG Grid instance. */
  @ViewChild('archiveGrid')
  archiveGrid: AgGridAngular<SearchResult>;
  /** Table field grid reference. */
  @ViewChild(TableFieldGridComponent)
  tableFieldGrid: TableFieldGridComponent;

  /** Observable value of the active archive object. */
  archive$ = this.archivesQuery.selectActive();
  /** Observable value of the active archive ID. */
  databaseId$ = this.databasesQuery.selectActiveId();
  /** Determines if the grid columns are currently editable. */
  editModeEnabled = false;
  /** Archive search results grid configuration. */
  gridOptions: GridOptions;
  /** True if the archive has view tabs that should be displayed. */
  hasViewTabs$ = combineLatest([
    this.archivesQuery.activeHasViewTabs$,
    this.searchesQuery.selectActive(),
  ]).pipe(
    map(
      ([activeArchiveHasTabs, activeSearch]) =>
        activeArchiveHasTabs && activeSearch?.settings.displayViewTabs
    )
  );
  /** Check was made for a default search, but none exists. */
  noDefaultSearch = false;
  /** Page size options. */
  pageSizes = getPageSizes(this.appQuery);
  /** Current number of records per page in the grid. */
  recordsPerPage = this.appQuery.archiveResultsPerPage;
  /** Search result count. */
  resultCount: number;
  /** Observable value of the list of search results. */
  results$: Observable<SearchResults>;
  /** Observable of right sidebar visibility. */
  rightSidebarOpen$ = this.applicationQuery.rightSidebarOpen$;
  /** Observable value of the active search object. */
  search$ = this.searchesQuery.selectActive();
  /** Whether to show the table field grid. */
  showTableFieldGrid = false;
  /** Observable of whether a compact layout should be used. */
  useCompactLayout$ = this.layout.useCompactLayout$;

  /** A form group of the row currently being editted. This should **ONLY** be used to track the dirty state. */
  private activeEditorFormGroup = new UntypedFormGroup({});
  /** Active grid data. */
  private activeGridData: ArchiveGridData;
  /** Active view tab. */
  private activeTab: ViewTabItem;
  /**
   * Subscription that listens to the active search observable and determines if the
   * hits column should be added or removed from the grid.
   */
  private archiveHitsColumnSubscription: Subscription | undefined;
  private editedFieldValues: FieldValues = [];
  private refreshSubscription: Subscription;
  private resultsSource = new ReplaySubject<SearchResults>(1);

  constructor(
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private fieldsQuery: FieldsQuery,
    private appQuery: ApplicationQuery,
    private appService: ApplicationService,
    private auth: AuthenticationService,
    private searchesService: SearchesService,
    private applicationQuery: ApplicationQuery,
    private viewerService: ViewerService,
    private logger: NGXLogger,
    private notify: NotificationService,
    private dialog: MatDialog,
    private searchUIService: SearchUIService,
    private tableFieldUIService: TableFieldUIService,
    @Inject(DOCUMENT_UPDATE_PROVIDER)
    private documentUpdateProvider: DocumentUpdateProvider,
    private gridHelper: GridHelperService,
    private gridStatesService: GridSettingsService,
    private layout: LayoutService,
    private router: Router,
    private hotkeys: HotkeysService,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * Get the records per page setting.
   *
   * @returns A number.
   */
  get archiveResultsPerPage(): number {
    return this.appQuery.archiveResultsPerPage;
  }

  /**
   * Gets the current page.
   *
   * @returns A number.
   */
  get currentPage(): number {
    return this.activeGridData?.currentPage ?? 1;
  }

  /**
   * Gets the current grid api instance.
   *
   * @returns An instance of GridApi or undefined if the grid does not exist.
   */
  get gridApi(): GridApi<SearchResult> | undefined {
    return this.archiveGrid?.api;
  }

  /**
   * Grid style attached to the AG grid element.
   *
   * Height will adjust for the presense of view tabs and table field controls.
   *
   * @returns CSS rule for the size of the grid control.
   */
  get gridStyle() {
    let totalControlHeight = this.displayViewTabsForSearchOnArchive ? 221 : 172;
    if (this.breakpointObserver.isMatched('(max-width: 599px)'))
      totalControlHeight -= 16;
    return {
      width: '100%',
      height: `calc(${
        this.showTableFieldGrid ? '50vh' : 'calc(100vh - 12px)'
      } - ${totalControlHeight}px)`,
    };
  }

  /** @inheritdoc */
  get isDirty(): boolean {
    return (
      this.activeEditorFormGroup.dirty ||
      (this.showTableFieldGrid && this.tableFieldGrid.isDirty)
    );
  }

  /**
   * Gets whether the user is a guest.
   *
   * @returns A boolean indicating if the user is a guest.
   */
  get isGuest(): boolean {
    return this.auth.isGuest;
  }

  /**
   * Selected SearchResults.
   *
   * @returns An array of selected SearchResults.
   */
  get selectedSearchResults(): SearchResults {
    return (this.activeGridData?.selectedRowNodes ?? []).map((row) => row.data);
  }

  /**
   * Check if the search should be performed in a tabbed context.
   *
   * @returns True when tab context.
   */
  private get displayViewTabsForSearchOnArchive(): boolean {
    return (
      // Display view tabes enabled on search.
      this.searchesQuery.active.settings.displayViewTabs &&
      // Archive has view tabs defined.
      this.archivesQuery.activeHasViewTabs
    );
  }

  /**
   * Opens the history in a modal.
   */
  clickHistory(): void {
    this.appService.toggleRightSidebarOpen();
  }

  ngOnInit(): void {
    this.results$ = this.resultsSource.asObservable();
    // ensure that the searches are finished loading before attempting a search
    this.searchesQuery
      .selectLoading()
      .pipe(
        untilDestroyed(this),
        first((isLoading) => !isLoading)
      )
      .subscribe(() => this.runSearchOrRedirectOnRouteChange());

    this.configureGrid();
  }

  /**
   * Event handler for a change in the active tab.
   *
   * @param value Current value.
   */
  onActiveTabChanged(value: ViewTabItem): void {
    if (typeof this.activeGridData !== 'undefined') {
      this.activeGridData.currentPage = 1;
    } else {
      this.logger.warn(
        'activeGridData object not ready, initial load. Current page will assume value of 1.'
      );
    }
    this.runTabRefresh(value);
  }

  /**
   * Right click event for the grid right click.
   *
   * @param event Mouse event.
   */
  onContextMenuClick(event: MouseEvent): void {
    if (this.isGuest) return;
    this.gridHelper.openContextMenu(
      event,
      this.activeGridData.selectedRowNodes,
      (rowId) => this.archiveGrid.api?.getRowNode(rowId),
      this.archiveActionsMenu
    );
  }

  /**
   * Handler for export as CSV event.
   *
   * @param advancedConfig Determines if advanced configuration options should be shown.
   */
  onDownloadSelectedRowsAsCsv(advancedConfig: boolean): void {
    // https://www.ag-grid.com/javascript-data-grid/csv-export/#csvexportparams getCustomContentBelowRow
    if (advancedConfig) {
      const dialogReference = this.dialog.open(ExportConfigDialogComponent);
      dialogReference.afterClosed().subscribe((result: ExportConfigResult) => {
        if (!result) {
          this.logger.debug('Advanced export dialog was cancelled.');
          return;
        }
        this.logger.debug(
          'Advanced export dialog was closed with values',
          result
        );
        this.archiveGrid.api.exportDataAsCsv({
          onlySelected: true,
          skipColumnHeaders: !result.includeHeaders,
          columnSeparator: result.fieldDelimiter,
        });
      });
    } else {
      this.archiveGrid.api.exportDataAsCsv({
        onlySelected: true,
        skipColumnHeaders: false,
      });
    }
  }

  /**
   * Handler for open documents event.
   *
   * @param openRequest Optional array of SearchResults to open. Otherwise it uses the selected rows.
   */
  onOpenDocumentSelectedDocuments(
    openRequest?: SearchResultDocumentOpenRequest
  ): void {
    this.logger.debug('Opening selected documents.');
    this.openDocuments(
      openRequest?.searchResults ??
        this.activeGridData.selectedRowNodes.map((row) => row.data),
      this.auth.isGuest,
      !!openRequest?.forceExternalViewer
    );
  }

  /**
   * Handler for the page change event.
   *
   * @param event Page change event.
   */
  onPageChange(event: PageChangeEvent): void {
    this.logger.debug('Page changed', event);
    this.activeGridData.currentPage = event.page;
    this.recordsPerPage = event.pageSize;
    this.archiveGrid.api?.updateGridOptions({
      paginationPageSize: event.pageSize,
    });
    this.refreshSearch();
  }

  /** Handler for refine search event. */
  onRefineSearch(): void {
    this.logger.debug('Refining search.');
    this.searchesQuery.searchRouteParams$
      .pipe(first())
      .subscribe(([databaseId, archiveId, searchId]) => {
        assertExists(archiveId);
        assertExists(databaseId);
        this.searchUIService.runWithPrompt(
          this.searchesQuery.getSearch(searchId),
          databaseId,
          archiveId
        );
      });
  }

  /** Handler for refresh search event. */
  onRefreshSearch(): void {
    this.refreshSearch();
  }

  /**
   * Handler for toggle edit event.
   *
   * @param enabled Whether edit is enabled.
   */
  onToggleEdit(enabled: boolean): void {
    this.logger.debug('Toggling edit mode.');
    this.editModeEnabled = enabled;
    this.archiveGrid.api?.stopEditing();
  }

  private addKeyboardshortcuts(): void {
    // Remove here ensures duplicate shortcuts don't get created.
    this.hotkeys.removeShortcuts('control.shift.f');
    this.hotkeys
      .addShortcut({
        group: 'Search',
        keys: 'control.shift.f',
        description: 'Show the search prompt',
      })
      .subscribe(() => {
        this.logger.debug('Refine search keyboard shortcut pressed.');
        this.onRefineSearch();
      });
  }

  private applyStoredColumnState(): void {
    if (this.activeGridData.columnStates) {
      const columnStateUpdateSuccessful =
        this.archiveGrid.api?.applyColumnState({
          state: this.activeGridData.columnStates,
          applyOrder: true,
        });
      if (!columnStateUpdateSuccessful) {
        this.logger.error(
          'Column state update failed. This most likely occurred because one or more of the columns did not exist.'
        );
      }
    }
  }

  /** Clears any active table field if it is not dirty. */
  private clearCleanTableField(): void {
    if (this.tableFieldGrid && !this.tableFieldGrid.isDirty) {
      this.logger.debug(
        'There is an open table field grid pending changes. Closing it.'
      );
      this.tableFieldUIService.clear();
    }
  }

  private configureGrid() {
    this.gridOptions = this.gridHelper.createArchiveGridOptions({
      onRowDoubleClicked: (event) => this.onRowDoubleClicked(event),
      onGridReady: () => {
        // Show grid as loading while search store is loading
        this.listentToSearchLoading();
        this.listenToTableFieldEvent();
        // Update columns
        this.listenToArchiveForColumns();
        // Update rows
        this.listenToResultsForRows();
        this.listenForResultsPerPage();
        this.addKeyboardshortcuts();
      },
      onNewColumnsLoaded: (params) => this.onColumnsAdded(params),
      onFirstDataRendered: (params) => this.resetStateIfNoneSaved(params),
      onRowDataUpdated: (params) => this.resetStateIfNoneSaved(params),
      onColumnResized: (params) => {
        if (params.finished) {
          this.logger.debug('Grid column resized.', params);
          this.saveColumnState(params);
        }
      },
      onSortChanged: (params) => {
        this.logger.debug('Grid column sort changed.', params);
        this.saveColumnState(params);
        this.refreshSearch();
      },
      onColumnVisible: (params) => this.saveColumnState(params),
      onDragStopped: (params) => this.saveColumnState(params),
      onColumnPinned: (params) => this.saveColumnState(params),
      onRowValueChanged: (event) => this.onRowValueChanged(event),
      onRowEditingStarted: (event) => this.onRowEdittingStarted(event),
      onRowEditingStopped: (event) => this.onRowEdittingStopped(event),
      onCellValueChanged: (params) => this.onCellValueChanged(params),
      onRowSelected: (event) => this.activeGridData.onRowSelected(event),
      tabToNextCell: (event) => {
        this.logger.debug(event);
        // Prevent the rest of the function from running if there was never a next cell.
        if (!event.nextCellPosition) {
          return null;
        }

        const nextCellPosition = event.nextCellPosition;
        let nextColumn = nextCellPosition.column;
        let nextRowIndex = nextCellPosition.rowIndex;
        const columns = event.api.getAllDisplayedColumns();
        assertExists(columns, 'Columns must exist.');

        const rowNode = event.api.getDisplayedRowAtIndex(nextRowIndex);
        assertExists(rowNode, 'Row node must exist.');

        // Find the next cell
        let columnIndex = columns.findIndex(
          (column) => column.getColId() === nextColumn.getColId()
        );
        // Loop through the columns to find the next editable cell.
        while (!nextColumn.isCellEditable(rowNode)) {
          // Modulus is used to wrap around to the beginning of the columns array.
          columnIndex =
            (event.backwards ? columnIndex - 1 : columnIndex + 1) %
            columns.length;
          // Wrap to the last column if we were previously at the first one.
          if (columnIndex === -1) {
            columnIndex = columns.length - 1;
            if (event.backwards) {
              nextRowIndex--;
            }
          } else if (columnIndex === 0 && !event.backwards) {
            nextRowIndex++;
          }

          // Do not try to go to the next row if there are no more rows.
          if (
            nextRowIndex < 0 ||
            nextRowIndex >= event.api.getDisplayedRowCount()
          ) {
            return null;
          }

          nextColumn = columns[columnIndex];
        }

        const nextRowNode = event.api.getDisplayedRowAtIndex(nextRowIndex);
        assertExists(nextRowNode, 'Next row node must exist.');

        if (event.previousCellPosition.rowIndex !== nextRowIndex) {
          event.api.deselectAll();
          event.api.setNodesSelected({ nodes: [nextRowNode], newValue: true });
        }

        // Allow focus to move to the next editable cell
        return {
          rowIndex: nextRowIndex,
          rowPinned: event.nextCellPosition.rowPinned,
          column: nextColumn,
        };
      },
    });

    this.appQuery.doubleClickToOpenDocuments$
      .pipe(untilDestroyed(this))
      .subscribe((doubleClickToOpen) => {
        this.gridOptions.onRowDoubleClicked = doubleClickToOpen
          ? (dblClickEvent) => this.onRowDoubleClicked(dblClickEvent)
          : undefined;
      });
  }

  private createOpenDocumentSession(
    archiveId: number,
    searchId: number,
    searchResults: SearchResults
  ): void {
    this.viewerService
      .openSelectedArchiveDocuments(
        this.databasesQuery.activeId,
        archiveId,
        searchId,
        this.searchesQuery.currentSearchPrompts,
        searchResults,
        this.appQuery.alwaysOpenNewTab
      )
      .subscribe({
        next: () => {
          this.logger.debug('Viewer closed.');
          this.refreshSearch();
        },
        error: (error: UserFriendlyError) => {
          error.i18n = 'ERROR_CREATE_SESSION_MSG';
          this.notify.error(error);
        },
      });
  }

  private listenForResultsPerPage() {
    this.appQuery.archiveResultsPerPage$.subscribe((resultsPerPage) => {
      if (this.recordsPerPage !== resultsPerPage) {
        // Only refresh if the new records per page actually changed.
        this.archiveGrid.api?.updateGridOptions({
          paginationPageSize: resultsPerPage,
        });
        this.activeGridData.currentPage = 1;
        this.recordsPerPage = resultsPerPage;
        this.refreshSearch();
      }
    });
  }

  private listenToArchiveForColumns() {
    this.archive$
      .pipe(untilDestroyed(this), filterNilValue(), debounceTime(1))
      .subscribe((archive) => {
        const newCols = this.gridHelper.getArchiveColumnDefinitions(
          archive.id,
          () => this.editModeEnabled
        );

        this.activeGridData = this.gridStatesService.getOrCreateArchiveGridData(
          this.databasesQuery.activeId,
          archive.id
        );

        this.activeGridData.currentPage = 1;

        // Ensure we unsubscribe from previous refresh event first.
        this.refreshSubscription?.unsubscribe();

        this.refreshSubscription = this.activeGridData.gridRefresh
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.onRefreshSearch();
          });

        // Ensure there are no previously selected rows.
        this.activeGridData.selectedRowNodes.length = 0;
        this.archiveGrid.api?.deselectAll();
        this.clearCleanTableField();

        this.archiveGrid.api?.updateGridOptions({ columnDefs: newCols });
      });
  }

  private listenToResultsForRows() {
    this.results$.pipe(untilDestroyed(this)).subscribe((results) => {
      this.archiveGrid.rowData = results;
      this.archiveGrid.api.updateGridOptions({ rowData: results });
      // Changes may not be detected in nested components
      // (i.e., table-field-button) for new rowdata, so refresh the rows.
      this.archiveGrid.api?.redrawRows();
    });
  }

  private listenToTableFieldEvent(): void {
    this.tableFieldUIService.activeTableField$.subscribe((activeTableField) => {
      this.logger.debug('Active table field or row changed.', activeTableField);
      this.showTableFieldGrid = !!activeTableField;
    });
  }

  private listentToSearchLoading() {
    this.searchesQuery
      .selectLoading()
      .pipe(untilDestroyed(this))
      .subscribe((isLoading) => {
        if (isLoading) {
          this.archiveGrid.api?.showLoadingOverlay();
        } else {
          this.archiveGrid.api?.hideOverlay();
        }
      });
  }

  private navigateToDefaultSearch(databaseId: number, archiveId: number): void {
    const defaultSearch = this.searchesQuery.getDefault();
    if (defaultSearch) {
      this.noDefaultSearch = false;
      this.logger.debug('Default search is defined, navigating.');
      this.router.navigate([
        'db',
        databaseId,
        'archive',
        archiveId,
        'search',
        defaultSearch.id,
      ]);
    } else {
      this.noDefaultSearch = true;
      this.notify.warning('Archive has no default search.');
    }
  }

  private navigateToRootSearch(databaseId: number, searchId: number): void {
    const search = this.searchesQuery.getSearch(searchId);
    const targetArchiveId = search.archives[0];
    this.router.navigate([
      'db',
      databaseId,
      'archive',
      targetArchiveId,
      'search',
      searchId,
    ]);
  }

  private onCellValueChanged(params: CellValueChangedEvent): void {
    this.logger.debug('Cell editing stopped', params);
    const newValue = params.newValue === undefined ? '' : params.newValue;
    const oldValue = params.oldValue === undefined ? '' : params.oldValue;
    const fieldId = Number(params.column.getColId().split('_')[1]);
    const field = this.fieldsQuery.getField(fieldId);
    // Determine if change was made.
    const isChanged =
      field.type === FieldDataType.date
        ? !isSameMoment(params.oldValue, params.newValue)
        : newValue !== oldValue;
    if (isChanged) {
      this.logger.debug(
        `Cell data was updated. Old value: ${params.oldValue} New Value: ${params.newValue}`
      );
      // Parse the field Id.
      const columnFieldName = params.colDef.field;
      const fieldIdString = columnFieldName?.split('_');
      if (typeof fieldIdString == 'undefined' || fieldIdString.length < 2) {
        throw new TypeError(
          'Cell was changed, but the field Id could not be determined from changed cell value.'
        );
      }
      // Store the new value.
      this.editedFieldValues.push({
        id: Number(fieldIdString[1]),
        value: field.multiValue ? '' : newValue,
        multiValue: field.multiValue ? newValue.split(',') : [],
      });
    }
  }

  onColumnsAdded(event: NewColumnsLoadedEvent): void {
    // Ensure unsubscribe the listener from the previous archive.
    this.archiveHitsColumnSubscription?.unsubscribe();

    this.archiveHitsColumnSubscription = this.searchesQuery.active$
      .pipe(filterNilValue())
      .subscribe({
        next: (activeSearch) => {
          // This is to squash errors seen about trying to get column defs on a destroyed grid.
          // It doesn't seem to have an impact on functionality but log to the console just in case.
          if (this.archiveGrid.api.isDestroyed()) {
            this.logger.debug(
              'Grid is marked as destroyed. Hits column cannot be added or removed.'
            );
            return;
          }
          let columnDefinitions = this.archiveGrid.api.getColumnDefs();
          if (!columnDefinitions) {
            this.logger.debug(
              'Column definitions are not yet set. Cannot check for hits column.'
            );
            return;
          }
          const hitsColumnIndex = columnDefinitions.findIndex(
            (c) => c.headerName === 'Hits'
          );
          if (
            activeSearch.settings.contentSearch.enabled &&
            hitsColumnIndex === -1
          ) {
            this.logger.debug(
              'Search is ContentSearch enabled but the hits column does not exist. Creating it...'
            );
            columnDefinitions.push({
              field: 'contentSearch.hits',
              headerName: 'Hits',
              sortable: true,
              filter: true,
              editable: false,
              resizable: true,
              headerCheckboxSelection: false,
              checkboxSelection: false,
            });

            this.archiveGrid.api.updateGridOptions({
              columnDefs: columnDefinitions,
            });
          } else if (
            !activeSearch.settings.contentSearch.enabled &&
            hitsColumnIndex > -1
          ) {
            this.logger.debug(
              'Search is not ContentSearch enabled and the hits column exists. Removing it...'
            );
            columnDefinitions.splice(hitsColumnIndex, 1);
            this.archiveGrid.api.updateGridOptions({
              columnDefs: columnDefinitions,
            });
          }
          if (this.activeGridData) {
            this.applyStoredColumnState();
          }
        },
      });
  }

  private onRowDoubleClicked(
    rowDblClickEvent: RowDoubleClickedEvent<SearchResult>
  ): void {
    this.logger.debug('Row double clicked.', rowDblClickEvent);
    if (this.editModeEnabled) {
      this.logger.debug(
        'Edit mode is enabled. Double click to open is disabled.'
      );
      return;
    }
    this.logger.debug('Opening double clicked documents.');
    const mouseEvent = rowDblClickEvent.event as MouseEvent;
    const rowsToOpen =
      rowDblClickEvent.node.isSelected() &&
      (mouseEvent.ctrlKey || mouseEvent.shiftKey)
        ? this.activeGridData.selectedRowNodes.map(
            (row) => row.data as SearchResult
          )
        : [rowDblClickEvent.node.data as SearchResult];
    this.openDocuments(rowsToOpen, this.auth.isGuest);
  }

  private onRowEdittingStarted(
    event: RowEditingStartedEvent<SearchResult>
  ): void {
    this.logger.debug('Row editting started.', event);
    this.editedFieldValues = [];
    const editors = event.api.getCellEditorInstances();
    const fieldEditors = editors.map(
      (editor) => editor as FieldCellEditorBaseComponent
    );
    this.logger.debug('Editors', fieldEditors);
    const fieldFormControls = fieldEditors.map(
      (fieldEditor) => fieldEditor.formControl
    );
    for (const control of fieldFormControls) {
      const name = `field_${fieldFormControls.indexOf(control)}`;
      this.activeEditorFormGroup.addControl(name, control);
    }
  }

  private onRowEdittingStopped(
    event: RowEditingStoppedEvent<SearchResult>
  ): void {
    this.logger.debug(
      'Row editting stopped. Clearing active editor form group.',
      event
    );
    this.activeEditorFormGroup = new UntypedFormGroup({});
  }

  private onRowValueChanged(event: RowValueChangedEvent<SearchResult>): void {
    assertExists(event.data, 'Row must have search result data.');
    this.logger.debug('Row value changed', event);
    if (this.editedFieldValues.length === 0) {
      this.logger.debug('No row data was modified so no save will occur.');
      return;
    }

    // check if there are required fields and
    const requiredFields = this.archivesQuery
      .getFields()
      .filter((field) => field.required);
    // Ensure there are values provided
    for (const field of requiredFields) {
      const fieldValue = event.data.fields.find((f) => f.id === field.id);
      const hasData = field.multiValue
        ? (fieldValue?.multiValue.length ?? 0) > 0
        : !!fieldValue?.value;
      // Abort save if a value is not provided
      if (!hasData) {
        const error: UserFriendlyError = {
          description:
            'Unable to save row changes. Value was missing for the required field' +
            field.name,
          error: undefined,
          i18n: 'REQUIRED_FIELD_VALUE_MISSING',
          i18nParameters: { fieldName: field.name },
        };
        this.notify.error(error);
        this.editedFieldValues = [];
        return;
      }
    }

    const documentId = event.data.id as number;
    const documentHash = event.data.secureId;

    this.logger.debug(
      'Field data will be updated.',
      documentId,
      documentHash,
      this.editedFieldValues
    );

    const updateSession = new DocumentUpdateSession(false, false, true, false);

    this.documentUpdateProvider
      .updateFieldData(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        documentId,
        documentHash,
        this.editedFieldValues,
        updateSession
      )
      .pipe(finalize(() => (this.editedFieldValues = [])))
      .subscribe({
        next: () => {
          this.notify.success('FIELD_DATA_UPDATED_SUCCESSFULLY_MESSAGE');
        },
        error: (error) => this.notify.error(error),
      });
  }

  private openDocuments(
    searchResults: SearchResults,
    forceInternalDocumentViewer = false,
    forceExternalDocumentViewer = false
  ): void {
    if (!forceExternalDocumentViewer && !forceInternalDocumentViewer) {
      this.logger.warn(
        'Both internal and external viewer can not be forced for use here, external viewer will be selected.'
      );
    }
    const useInternalDocumentViewer =
      (this.appQuery.viewerUseInternal || forceInternalDocumentViewer) &&
      !forceExternalDocumentViewer;
    this.searchesQuery.searchRouteParams$
      .pipe(first())
      .subscribe(([databaseId, archiveId, searchId]) => {
        assertExists(archiveId);
        assertExists(searchId);
        if (useInternalDocumentViewer) {
          this.openInDocumentView(
            searchResults,
            archiveId,
            databaseId,
            searchId
          );
        } else {
          this.createOpenDocumentSession(archiveId, searchId, searchResults);
        }
      });
  }

  private openInDocumentView(
    searchResults: SearchResults,
    archiveId: number | undefined,
    databaseId: number | undefined,
    searchId: number
  ) {
    // Map the grid data row nodes to archive session document objects.
    assertExists(archiveId);
    const selectedDocuments = searchResults.map((searchResult) =>
      createArchiveSessionDocumentFromSearchResult(searchResult, archiveId)
    );

    // Indexer and table field controls are currently not shown for guests.
    // Ensure the table field is cleared.
    if (this.isGuest) {
      this.tableFieldUIService.clear();
    }

    // Support for "multiple" archives in a single open:
    // Combine archive and document Ids into a string.
    // We do not need to pass the secure Id etc, as each document is
    // retrieved by a single document search, this ensures our hash will match
    // the users current token etc.
    //
    // This will currently be unused as we only support selection in one archive.
    //
    // For documents that match the current archive, require only the document Id.
    const documentKey = (document: ArchiveSessionDocument) =>
      document.archiveId !== archiveId
        ? `${document.archiveId}.${document.id}`
        : document.id;

    // Open documents.
    this.logger.debug('Navigate to document.');
    if (this.appQuery.alwaysOpenNewTab) {
      const searchPrompts = this.searchesQuery.currentSearchPrompts;
      const navigationExtras: NavigationExtras | undefined =
        searchPrompts.length > 0
          ? {
              queryParams: { prompts: btoa(JSON.stringify(searchPrompts)) },
              queryParamsHandling: 'merge',
            }
          : undefined;
      const urlTree = this.router.createUrlTree(
        [
          'db',
          databaseId,
          'archive',
          archiveId,
          'search',
          searchId,
          'document',
          // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
          selectedDocuments
            .map(documentKey)
            .join(),
        ],
        navigationExtras
      );
      const viewerWindow = window.open(urlTree.toString(), '_blank');
      assertExists(
        viewerWindow,
        'Internal viewer window must exist to listen for close event.'
      );

      if (this.appQuery.refreshResultsWhenClosingDocumentTabs) {
        const intervalSubscription = interval(1000)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (viewerWindow.closed) {
              intervalSubscription.unsubscribe();
              this.refreshSearch();
            }
          });
      }
    } else {
      this.router.navigate(
        [
          'db',
          databaseId,
          'archive',
          archiveId,
          'search',
          searchId,
          'document',
          // Send multiple document results together, archive and document paired by `.` and each seperated by `,`.
          selectedDocuments
            .map(documentKey)
            .join(),
        ],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  private refreshSearch(): void {
    // Deselect the documents.
    this.archiveGrid.api?.deselectAll();
    this.clearCleanTableField();
    this.logger.debug('Refreshing search.');
    if (this.displayViewTabsForSearchOnArchive) {
      this.runTabRefresh(this.activeTab);
    } else {
      this.runSearchOrRedirectOnRouteChange();
    }

    this.archiveActionsToolbar.reloadArchiveSearchSelectorCounts();
  }

  private resetStateIfNoneSaved(params: AgGridEvent) {
    if (!this.activeGridData.hasStoredColumnStates) {
      params.api.resetColumnState();
    }
  }

  /**
   * Run the specified search and load the result data.
   *
   * @param searchId Search Id.
   * @param searchPrompts Search parameter data.
   * @param tabId Tab Id to restrict the search to. 0 will not filter.
   */
  private runSearch(
    searchId: number,
    searchPrompts: SearchPrompt[] = this.searchesQuery.currentSearchPrompts,
    tabId: number = 0
  ): void {
    const defaultSearchOptions: SearchOptions = {
      page: this.currentPage,
      countOnly: false,
      recordsPerPage: this.recordsPerPage,
      searchCriteria: searchPrompts
        ? createApiSearchPromptString(searchPrompts)
        : '',
      sort: '',
      tabId,
      targetArchiveId: this.archivesQuery.activeId,
      includeExtendedData: true,
    };

    // See if there are sorted columns and provide the first one to the API call
    const columnStates = this.archiveGrid.api.getColumnState();
    const sortedColumns = columnStates.filter(
      (c) => c.sort === 'asc' || c.sort === 'desc'
    );
    if (sortedColumns.length > 0) {
      const sortedColumn = sortedColumns[0];
      const fieldId = sortedColumn.colId.split('_')[1];
      // if we get here sort has to be either asc or desc.
      defaultSearchOptions.sort = `${sortedColumn.sort === 'desc' ? '-' : ''}${fieldId}`;
    }

    // Run the search.
    this.searchesService
      .run(this.searchesQuery.getSearch(searchId), defaultSearchOptions)
      .pipe(untilDestroyed(this))
      .subscribe((resultResponse) => {
        // Load the results.
        this.resultsSource.next(resultResponse.searchResults);
        this.resultCount = resultResponse.count;
        // If there are tabs, update the active tab result count and update the grid view area.
        if (
          this.searchesQuery.getSearch(searchId).settings.displayViewTabs &&
          this.archivesQuery.activeHasViewTabs
        ) {
          this.activeTab.count = resultResponse.count;
        }
      });
  }

  private runSearchOrRedirectOnRouteChange(): void {
    this.searchesQuery.searchRouteParams$
      .pipe(untilDestroyed(this))
      .subscribe(([databaseId, archiveId, searchId, searchPrompts]) => {
        archiveId = archiveId ?? 0;
        assertExists(databaseId);
        if (archiveId === 0 && searchId !== undefined) {
          this.navigateToRootSearch(databaseId, searchId);
        } else if (searchId !== undefined) {
          // If there are no tabs, run the search. Otherwise let the `runTabRefresh()` handle search run.
          if (
            !this.searchesQuery.getSearch(searchId).settings.displayViewTabs ||
            !this.archivesQuery.activeHasViewTabs
          ) {
            this.runSearch(searchId, searchPrompts);
          }
        } else {
          this.navigateToDefaultSearch(databaseId, archiveId);
        }
      });
  }

  private runTabRefresh(value: ViewTabItem): void {
    this.activeTab = value;
    // Run search for newly active tab.
    this.logger.debug('Running search for active tab.');
    this.searchesQuery.searchRouteParams$
      .pipe(first())
      .subscribe(([databaseId, archiveId, searchId, searchPrompts]) => {
        assertExists(searchId);
        assertExists(archiveId);
        assertExists(databaseId);
        this.logger.debug('Executing search for active tab.');
        this.runSearch(searchId, searchPrompts, this.activeTab.description.id);
      });
  }

  private saveColumnState(params: AgGridEvent) {
    this.activeGridData.columnStates = params.api.getColumnState();
  }
}
