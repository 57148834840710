import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, mergeMap, of } from 'rxjs';

import { GUEST_USERNAME } from '../common/constants';
import { AppConfigService } from '../modules/app-config';
import { LOGIN_PROVIDER, LoginProvider } from '../modules/login';
import { AuthenticationService } from '../services/authentication.service';
import { ApplicationQuery } from '../state/application/application.query';
import { ApplicationService } from '../state/application/application.service';

/**
 * Guard functions for router.
 *
 * Preventing unauthorized access to {@link https://angular.io/guide/router#preventing-unauthorized-access Angular Router}.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private logger: NGXLogger,
    @Inject(LOGIN_PROVIDER)
    private loginProvider: LoginProvider,
    private appQuery: ApplicationQuery,
    private appConfig: AppConfigService,
    private appService: ApplicationService
  ) {}

  /**
   * @inheritdoc
   * @see CanActivate
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // Check for instance in URL.
    const newInstanceFromUrl = route.queryParamMap.get('instance');

    // If no instance is provided in URL, proceed with auth checks.
    if (newInstanceFromUrl === null) {
      return this.checkForAuthenticatedUserOrRedirect(route, state);
    }

    // If URL contained an instance URL, get the current stored URL.
    return this.appQuery.instanceUrl$.pipe(
      mergeMap((currentInstanceUrl) => {
        // If a URL instance is provided, and not the same as the current one, use it.
        if (newInstanceFromUrl !== currentInstanceUrl) {
          this.logger.debug(
            'URL contained a different instance, requesting update.',
            'New instance:',
            newInstanceFromUrl,
            'Previous instance:',
            currentInstanceUrl
          );
          // Check for new configuration, and validity of URL.
          return this.appConfig.get(newInstanceFromUrl).pipe(
            mergeMap((config) => {
              // If the config is valid, then update the URL.
              this.appService.setInstanceUrl(newInstanceFromUrl);
              this.logger.debug(
                'Successfully updated instance from URL, continue auth guard checks.',
                config
              );
              // Resume activation check.
              return this.canActivate(route, state);
            }),
            catchError((error) => {
              this.logger.error(
                'Unable to update instance from URL parameter.',
                error
              );
              // App config fetch failed, proceed with login page redirect check.
              return of(this.gotoLoginIfNotLoggedIn(state));
            })
          );
        }

        // Proceed with auth or redirect.
        return this.checkForAuthenticatedUserOrRedirect(route, state);
      })
    );
  }

  /**
   * @inheritdoc
   * @see CanActivateChild
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.gotoLoginIfNotLoggedIn(state);
  }

  /**
   * Attempt to authenticate the user as guest.
   *
   * @param route Route snapshot.
   * @param state Router state snapshot.
   * @returns Observable to allow True to allow auth or init redirect.
   */
  private attemptGuestLogin(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.logger.debug(
      'URL indicates guest login should be used, requesting guest authentication.'
    );
    return this.auth.basicLogin(GUEST_USERNAME, '').pipe(
      mergeMap(() => this.canActivate(route, state)),
      catchError((error) => {
        this.logger.warn(
          'Guest login attempted failed, redirect to login screen.',
          error
        );
        // If guest login fails, hand off to redirect handler.
        return of(this.gotoLoginIfNotLoggedIn(state));
      })
    );
  }

  /**
   * Check for authenticated user, or proceed with login redirection.
   *
   * @param route Route snapshot.
   * @param state Router state snapshot.
   * @returns Observable to allow True to allow auth or init redirect.
   */
  private checkForAuthenticatedUserOrRedirect(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    // Check for guest credentials in URL.
    const isGuestUrl =
      route.queryParamMap.get('user')?.toLowerCase() === GUEST_USERNAME;
    // Actually test our ability to use the current authentication.
    if (this.auth.isLoggedIn) {
      return this.auth.validate().pipe(
        // Login is not valid, either login as guest and continue, or redirect to login.
        mergeMap((valid) => {
          // If the login is valid, continue.
          if (valid) return of(valid);
          // Otherwise...
          this.logger.error(
            'The current authentication credentials are invalid.'
          );
          // Try a new guest login if the URL is guest routed.
          if (isGuestUrl) {
            return this.attemptGuestLogin(route, state);
          }
          // Otherwise, clear invalid login and return to login screen.
          this.appService.clearUser();
          return of(this.gotoLoginIfNotLoggedIn(state));
        })
      );
    }

    // If the user is not already logged in, and URL indicates a guest login, auth as guest.
    if (isGuestUrl) {
      return this.attemptGuestLogin(route, state);
    }

    // Go to the login screen if the user is otherwise not logged in.
    return of(this.gotoLoginIfNotLoggedIn(state));
  }

  private gotoLoginIfNotLoggedIn(state: RouterStateSnapshot) {
    if (this.auth.isLoggedIn) return true;
    const url = this.router.parseUrl('/login');
    url.queryParams.returnUrl = state.url;
    return this.auth.isLoggedIn ? true : url;
  }
}
