<mat-form-field>
  <mat-label>{{ field.name }}</mat-label>
  <input
    #input
    matInput
    type="number"
    (keypress)="onKeyPress($event)"
    [formControl]="control"
    [appMultiValueHotkeys]="mvFieldMenu"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <button
    *ngIf="field.multiValue"
    tabIndex="-1"
    mat-icon-button
    type="button"
    matSuffix
    [matMenuTriggerFor]="mvFieldMenu.matMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-error
    *ngIf="control.invalid"
    class="ellipsis"
    [matTooltip]="errorMessage"
    >{{ errorMessage }}</mat-error
  >
</mat-form-field>
