import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, of, tap } from 'rxjs';

import { Field, FieldValues, Permissions } from 'models';
import { DirtyComponent } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ApplicationService } from 'src/app/state/application/application.service';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';

import { IndexerComponent } from '../indexer/indexer.component';
import { UserActionExecutedEvent } from '../user-actions-panel/user-actions-panel.component';

/** Minimum left side width for resizable panel before invoking overlay mode. */
const MIN_LEFT_RESIZE_OVERLAY_PX = 330;
/** Minimum right side width for resizable panel. */
const MIN_RIGHT_RESIZE_PX = 120;
/** Minimum left side width for resizable panel. */
const MIN_LEFT_RESIZE_PX = 64;

/** Document View Sidebar. */
@Component({
  selector: 'app-document-view-sidebar',
  templateUrl: './document-view-sidebar.component.html',
  styleUrls: ['./document-view-sidebar.component.scss'],
})
export class DocumentViewSidebarComponent implements DirtyComponent {
  /**
   * Archive Id.
   */
  @Input()
  archiveId: number;
  /** Document id. */
  @Input()
  documentId: number;
  /** Document secure id. */
  @Input()
  documentSecureId: string;
  /** Draw container reference. */
  @ViewChild('drawer') drawer: MatDrawer;
  /** Field values. */
  @Input()
  fieldValues: FieldValues = [];
  /** Indexer component reference. */
  @ViewChild('indexer')
  indexer: IndexerComponent;
  /** Emits when a field in the indexer is blurred. Contains the field. */
  @Output()
  indexerFieldBlurred = new EventEmitter<Field>();
  /** Emits when a field in the indexer is focused. Contains the field. */
  @Output()
  indexerFieldFocused = new EventEmitter<Field>();
  /** Permissions which can be either archive or search result permissions. */
  @Input()
  permissions: Permissions;
  /** Drawer position. */
  @Input('drawer-position')
  position: 'start' | 'end' = 'end';
  /** Tab controls. */
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;
  /**
   * Unique id for a document used for the indexer.
   */
  @Input()
  uniqueId: string;
  /** Emits when a user action is executed. */
  @Output()
  userActionExecuted = new EventEmitter<UserActionExecutedEvent>();
  /** Viewer page number. */
  @Input()
  viewerPageNumber: number;
  /**
   * Workflow process id.
   *
   * Defaults to 0 meaning there is no process for the document.
   */
  @Input()
  workflowProcessId = 0;

  /** Observable of whether the indexer sidebar should be open. */
  indexerSidebarOpen$ = this.auth.isGuest
    ? of(false) // Never show the sidebar if the user is a guest.
    : this.appQuery.indexerSidebarOpen$;
  /** Is the guest user currently logged in. */
  isGuest = this.auth.isGuest;
  /** Observable state of if we are on a mobile handset sized output. */
  isHandset$ = this.layout.isHandset$.pipe(
    // Disable resize when handset, it just gets complicated.
    tap((isHandset) => (this.isResizeDisabled = isHandset))
  );
  /** If the ability to resize should be disabled. */
  isResizeDisabled = false;
  /** A resize event is in progress. */
  resizeInProgress: boolean;
  /** A CSS object container style adjustments for resize. */
  resizeStyle: { /** Width. */ width?: string } = {};

  /** Should the drawer overlay mode be forced on. */
  private isDrawerOverlayForced = false;
  /** Last known width of the window. */
  private lastWindowWidth: number;

  /**
   *  Get drawer mode.
   *
   * @returns Observable mat-drawer mode.
   */
  get drawerMode$(): Observable<MatDrawerMode> {
    if (this.isDrawerOverlayForced) {
      return of('over');
    }
    return this.isHandset$.pipe(
      map((isHandset) => (isHandset ? 'over' : 'side'))
    );
  }

  /** @inheritdoc */
  get isDirty(): boolean {
    return typeof this.indexer !== 'undefined' && this.indexer.isDirty;
  }

  constructor(
    private logger: NGXLogger,
    private archivesQuery: ArchivesQuery,
    private appQuery: ApplicationQuery,
    private application: ApplicationService,
    private auth: AuthenticationService,
    private layout: LayoutService,
    private changeDector: ChangeDetectorRef
  ) {}

  /** Handler for the close drawer event. */
  onClose(): void {
    this.application.setIndexerSidebarOpen(false);
  }

  /**
   * Handler for indexer field blurred event.
   *
   * @param field Field that was blurred.
   */
  onIndexerFieldBlurred(field: Field): void {
    this.indexerFieldBlurred.emit(field);
  }

  /**
   * Handler for indexer field focused event.
   *
   * @param field Field that was focused.
   */
  onIndexerFieldFocused(field: Field): void {
    this.indexerFieldFocused.emit(field);
  }

  //TODO resize is not working correctly.

  /**
   * Handler for resize events.
   *
   * @param event Event.
   */
  onResize(event: any): void {
    // Set the style to render the pending size.
    this.resizeStyle = {
      width: `${event.rectangle.width}px`,
    };

    // Force the drawer to overlay mode if it would block controls.
    this.isDrawerOverlayForced =
      event.rectangle.width >= window.innerWidth - MIN_LEFT_RESIZE_OVERLAY_PX;

    // Update the stored width for layout service.
    this.storeDrawerWidth();
  }

  /**
   * Handler for end of resize event.
   *
   * @param event Event.
   */
  onResizeEnd(event: any): void {
    this.resizeInProgress = false;
    // If the resize event ends at minimum, they get a close, because thats an
    // unusable size.
    if (event.rectangle.width <= MIN_RIGHT_RESIZE_PX) {
      this.application.setIndexerSidebarOpen(false);
    }

    // Update the known window width.
    this.lastWindowWidth = window.innerWidth;

    // Fix the tabs ink highlight position.
    this.tabs.realignInkBar();
  }

  /**
   * Handler for double click of resize handle.
   *
   * Resets size and state.
   */
  onResizeHandleDoubleClick(): void {
    this.resetPanelSize();
  }

  /**
   * Handler for resize event start.
   */
  onResizeStart(): void {
    // We set this so we can track the state of the resize event.
    // Specically this is required to change our "handle" to cover the
    // entire right panel while we are resizing. This prevents events from
    // falling through to the iFrame of a viewer if active, which breaks events.
    this.resizeInProgress = true;
  }

  /**
   * Handler for resize event validation.
   *
   * @param event Event.
   * @returns If the resize event is valid.
   */
  onResizeValidate(event: any): boolean {
    // Do nothing if resizing is disabled.
    if (this.isResizeDisabled) return false;
    // Test the horizontal size of the event rectangle to see if it fits within
    // our resizing bounds.
    const outOfBounds =
      // More than the left side minimum allows.
      event.rectangle.width > window.innerWidth - MIN_LEFT_RESIZE_PX ||
      // Less than the right side minimum.
      event.rectangle.width < MIN_RIGHT_RESIZE_PX;
    // If it is out of bounds, ignore it.
    return !outOfBounds;
  }

  /**
   * Handler for change of selected tab.
   *
   * @param value Selected tab value, from event.
   */
  onTabChange(value: number): void {
    // assert(typeof value == 'number');
    // if (value !== this.selectedTabIndexSource.value) {
    //   this.selectedTabIndexSource.next(value);
    // }
    // this.logger.debug('Tab changed to index', value);
    // this.storeDrawerWidth();
  }

  /**
   * Handler for the user action executed event.
   *
   * @param event Event.
   */
  onUserActionExecuted(event: UserActionExecutedEvent): void {
    this.userActionExecuted.emit(event);
  }

  /**
   * Handler for resize of the window.
   *
   * Resets size and state, if the width is reduced.
   *
   * @param event Event.
   */
  onWindowResize(event: any): void {
    if (event.target.innerWidth < this.lastWindowWidth) this.resetPanelSize();
    this.lastWindowWidth = event.target.innerWidth;
  }

  /**
   * Restore size of panel.
   */
  private resetPanelSize() {
    this.resizeStyle = { width: undefined };
    this.isDrawerOverlayForced = false;
    this.storeDrawerWidth();
  }

  /**
   * Update the stored drawer with use by layout.
   */
  private storeDrawerWidth(): void {
    // const width = this.drawer._getWidth();
    // if (this.layout.rightSidebarWidth === width) return;
    // this.logger.debug('Store drawer width called', width);
    // this.layout.rightSidebarWidth = width;
    // // Prevent expression change errors when we know we are doing this.
    // this.changeDector.detectChanges();
  }
}
