import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  concat,
  finalize,
  interval,
  map,
  switchMap,
  tap,
} from 'rxjs';

import {
  AdvancedLink,
  AdvancedLinks,
  AppendType,
  ArchiveEmailDocument,
  ArchiveMergeDocument,
  ArchiveProvider,
  BatchPrint,
  DocumentProvider,
  EmailArchiveRequest,
  Fields,
  InboxMergeDocument,
  InboxProvider,
  MergeResult,
  MergeTarget,
  MergeTargetType,
  PrintProvider,
  SearchResults,
  UserFriendlyError,
} from 'models';
import {
  ARCHIVE_PROVIDER,
  DOCUMENT_PROVIDER,
  INBOX_PROVIDER,
  PRINT_PROVIDER,
} from 'src/app/common/tokens';
import { ActionsMenu, SearchResultDocumentOpenRequest } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentTransferService } from 'src/app/services/document-transfer.service';
import { GseService } from 'src/app/services/gse.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProgressDialogService } from 'src/app/services/progress-dialog.service';
import { WindowService } from 'src/app/services/window.service';
import { AdvancedLinkQuery } from 'src/app/state/advanced-links/advanced-links.query';
import { ApplicationQuery } from 'src/app/state/application/application.query';
import { ArchivesQuery } from 'src/app/state/archives/archives.query';
import { DatabasesQuery } from 'src/app/state/databases/databases.query';
import { FieldsQuery } from 'src/app/state/fields/fields.query';
import { SearchesQuery } from 'src/app/state/searches/searches.query';

import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../confirmation-dialog/confirmation-dialog.component';
import {
  EnhancedExportDialogComponent,
  EnhancedExportDialogData,
} from '../enhanced-export-dialog/enhanced-export-dialog.component';
import {
  InsertFromInboxDialogComponent,
  InsertFromInboxDialogResult,
} from '../insert-from-inbox-dialog/insert-from-inbox-dialog.component';
import {
  InsertFromSearchDialogComponent,
  InsertFromSearchDialogResult,
} from '../insert-from-search-dialog/insert-from-search-dialog.component';
import { MenuBaseComponent } from '../menu/menu-base.component';

/** Archive actions menu. */
@UntilDestroy()
@Component({
  selector: 'app-archive-actions-menu',
  templateUrl: './archive-actions-menu.component.html',
  styleUrls: ['./archive-actions-menu.component.scss'],
})
export class ArchiveActionsMenuComponent
  extends MenuBaseComponent
  implements OnInit, ActionsMenu
{
  /** Event raised when selected rows should be downloaded as a CSV. */
  @Output()
  downloadSelectedRowsAsCsv = new EventEmitter<boolean>();
  /** If elements related to specifically multiple document contexts should be hidden. */
  @Input()
  hideMultiple: boolean;
  /** If the open option should be hidden. */
  @Input()
  hideOpen: boolean;
  /** Event raised when selected rows should be opened. */
  @Output()
  openSelectedDocuments = new EventEmitter<SearchResultDocumentOpenRequest>();
  /** Event triggered when the search should be refreshed. */
  @Output()
  refreshSearch: EventEmitter<any> = new EventEmitter();
  /**
   * Selected search results.
   *
   * This value is normally provided by the openMenu function; however, it can
   * be set by this Input attribute binding as well.
   */
  @Input()
  searchResults: SearchResults = [];
  /** Emits selected search results on change. */
  @Output()
  searchResultsChange = new EventEmitter<SearchResults>();

  /** Observable collection of advanced links. */
  advancedLinks$: Observable<AdvancedLinks> = this.advancedLinkQuery
    .selectAll()
    .pipe(
      map((advancedLinks) =>
        advancedLinks.filter((advancedLink) =>
          this.shouldShowAdvancedLink(advancedLink)
        )
      )
    );
  /** Observable valie fo the active archive object. */
  field$ = this.fieldsQuery.selectAll();
  /** Observable connection state of GSE. */
  gseIsConnected$ = this.gse.isConnected$;
  /** Observable connection state for launch document. */
  gseIsLaunchAvailable$ = this.gse.isLaunchAvailable$;
  /** Observable connectionstate for Quickbooks. */
  gseIsQuickbooksAvailable$ = this.gse.isQuickbooksAvailable$;
  /** Observable determining if there are advanced links to be shown. */
  hasAdvancedLinks$ = this.advancedLinks$.pipe(
    map((advancedLinks) => advancedLinks.length > 0)
  );
  /** Observable boolean determining if there are favorite searches. */
  hasFavoriteSearches$ = this.searchesQuery.favoritedSearches$.pipe(
    map((favoriteSearches) => favoriteSearches.length > 0)
  );
  /** If the open in the external full viewer option should be hidden. */
  hideOpenFullViewer = true;

  private useCompact: boolean;

  constructor(
    private logger: NGXLogger,
    private databasesQuery: DatabasesQuery,
    private archivesQuery: ArchivesQuery,
    private searchesQuery: SearchesQuery,
    private appQuery: ApplicationQuery,
    private advancedLinkQuery: AdvancedLinkQuery,
    private fieldsQuery: FieldsQuery,
    private auth: AuthenticationService,
    @Inject(DOCUMENT_PROVIDER)
    private documentProvider: DocumentProvider,
    @Inject(PRINT_PROVIDER)
    private printProvider: PrintProvider,
    @Inject(ARCHIVE_PROVIDER)
    private archiveProvider: ArchiveProvider,
    @Inject(INBOX_PROVIDER)
    private inboxProvider: InboxProvider,
    private windowService: WindowService,
    private translate: TranslocoService,
    private progressDialogService: ProgressDialogService,
    private notifications: NotificationService,
    private documentTransferService: DocumentTransferService,
    private layout: LayoutService,
    private gse: GseService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    // Hide the full viewer option when internal has not been enabled for use, or the user is a guest.
    this.appQuery.viewerUseInternal$
      .pipe(untilDestroyed(this))
      .subscribe((viewerUseInternal) => {
        this.hideOpenFullViewer = !viewerUseInternal || this.auth.isGuest;
      });
    this.layout.useCompactLayout$
      .pipe(untilDestroyed(this))
      .subscribe((useCompact) => (this.useCompact = useCompact));
  }

  /**
   * Determines if the append button should be disabled.
   *
   * @returns A boolean indicating if append should be disabled.
   */
  get appendDisabled(): boolean {
    if (this.searchResults.length !== 1) {
      return true;
    }

    const searchResult = this.searchResults[0];
    return !searchResult.permissions.modifyDocuments;
  }

  /**
   * Array of archive fields. This does not include fields belonging to a table field.
   *
   * @returns An array of fields.
   */
  get archiveFields(): Fields {
    const tableFields = this.archivesQuery.getTableFields();
    return this.archivesQuery
      .getFields()
      .filter(
        (field) => !tableFields.some((tf) => tf.fieldIds.includes(field.id))
      );
  }

  /**
   * Determines if the copy button should be disabled.
   *
   * @returns A boolean indicating if copy should be disabled.
   */
  get copyDisabled(): boolean {
    return this.appQuery.isReadOnlyLicense;
  }

  /**
   * Determines if the delete button should be disabled.
   *
   * @returns A boolean indicating if delete should be disabled.
   */
  get deleteDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.deleteDocuments
    );
  }

  /**
   * Determines if the GSE email button should be disabled.
   *
   * @returns True if GSE email should be disabled.
   */
  get emailDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.emailDocuments
    );
  }

  /**
   * Determines if the export button should be disabled.
   *
   * @returns A boolean indicating if export should be disabled.
   */
  get exportDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.exportDocuments
    );
  }

  /**
   * Determines if the download as CSV button should be disabled.
   *
   * @returns A boolean indicating if download as CSV should be disabled.
   */
  get downloadAsCsvDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.exportData
    );
  }

  /**
   * Determines if the 'no annotations' option should be shown.
   *
   * @returns True if the 'no annotations' option should be shown.
   */
  get showLaunchEmailNoAnnotations(): boolean {
    return this.searchResults.every(
      (searchResult) => searchResult.permissions.modifyAnnotations
    );
  }

  /**
   * Determines if the download as CSV button should be shown.
   *
   * @returns True if the download as CSV button should be shown.
   */
  get showDownloadAsCsv(): boolean {
    // Check if the control is attached by testing if it is observed in a template (results grid is present).
    return this.downloadSelectedRowsAsCsv.observed;
  }

  /**
   * Determines if the launch button should be disabled.
   *
   * @returns True if launch should be disabled.
   */
  get launchDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) =>
        searchResult.permissions.launchDocument &&
        searchResult.permissions.exportDocuments &&
        searchResult.permissions.modifyAnnotations &&
        searchResult.permissions.modifyDocumentPages &&
        searchResult.permissions.modifyDocuments
    );
  }

  /**
   * Determines if the merge button should be disabled.
   *
   * @returns A boolean indicating if merge should be disabled.
   */
  get mergeDisabled(): boolean {
    return !!(
      this.searchResults.length < 2 ||
      !this.archivesQuery.active.permissions.addNewDocuments
    );
  }

  /**
   * Determines if the move button should be disabled.
   *
   * @returns A boolean indicating if move should be disabled.
   */
  get moveDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.moveDocuments
    );
  }

  /**
   * Determines if the print button should be disabled.
   *
   * @returns A boolean indicating if print should be disabled.
   */
  get printDisabled(): boolean {
    return !this.searchResults.every(
      (searchResult) => searchResult.permissions.printDocuments
    );
  }

  /**
   * Click event for launching advanced link.
   *
   * @param advancedLink Advanced Link.
   */
  onClickAdvancedLink(advancedLink: AdvancedLink): void {
    this.logger.debug('Advanced Link clicked.', advancedLink);
    let url = advancedLink.urlPattern;
    for (const row of this.searchResults) {
      url = url.replace(new RegExp('{{Document ID}}', 'i'), row.id.toString());
      url = url.replace(
        new RegExp('{{Archive ID}}', 'i'),
        this.archivesQuery.activeId.toString()
      );
      this.field$.subscribe((fields) => {
        for (const field of fields) {
          const matchedField = row.fields.find((f) => f.id === field.id);
          const fieldData =
            matchedField?.value ?? matchedField?.multiValue.join(',') ?? '';
          if (fieldData) {
            url = url.replace('{{' + field.name + '}}', encodeURI(fieldData));
            url = url.replace('{{!' + field.name + '}}', fieldData);
          }
        }
      });
      window.open(url, '_blank');
    }
  }

  /** Click event for the append button. */
  onClickAppend(): void {
    this.logger.debug('Append button clicked.');
    const dialog = this.dialog.open(InsertFromInboxDialogComponent, {
      maxWidth: this.useCompact ? '95vw' : '80vw',
    });
    dialog
      .afterClosed()
      .subscribe((result: InsertFromInboxDialogResult) =>
        this.onInsertDialogClosed(result)
      );
  }

  /** Click event for the copy button. */
  onClickCopy(): void {
    this.logger.debug('Copy clicked.');
    this.transferDocument(false);
  }

  /** Click event for the delete button. */
  onClickDelete(): void {
    this.logger.debug('Delete button was clicked.');
    const data: ConfirmationDialogData = {
      cancelActionText: 'CANCEL',
      confirmActionText: 'DELETE',
      contents: 'CONFIRM_DELETE',
      title: 'DELETE_DOCUMENTS',
    };

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data,
    });
    dialog.afterClosed().subscribe((confirmed) => {
      this.logger.debug('Confirm dialog closed.', confirmed);
      if (!confirmed) return;

      this.deleteSelectedDocuments();
    });
  }

  /**
   * Click event for the download CSV button.
   *
   * @param advancedConfig Determines the user should be shown advanced configuration options.
   */
  onClickDownloadCsv(advancedConfig: boolean): void {
    this.logger.debug('Download CSV clicked.', advancedConfig);
    this.downloadSelectedRowsAsCsv.emit(advancedConfig);
  }

  /**
   * Click event for the email button.
   *
   * @param includeAnnotations Whether to include annotations.
   * @param fieldId Field ID for filename.
   */
  onClickEmail(includeAnnotations: boolean, fieldId: number): void {
    this.logger.debug('Email button clicked.', includeAnnotations, fieldId);
    const searchPrompts = this.searchesQuery.currentSearchPrompts;
    const request: EmailArchiveRequest = {
      documents: this.searchResults.map(
        (row): ArchiveEmailDocument => ({
          archiveId: this.archivesQuery.activeId,
          docId: row.id,
        })
      ),
      searchCriteria: {},
    };
    for (const prompts of searchPrompts) {
      request.searchCriteria[prompts.id] = prompts.value;
    }
    this.gse.launchApi
      .emailArchiveDocument(
        this.databasesQuery.activeId,
        this.searchesQuery.activeId,
        fieldId,
        includeAnnotations,
        request
      )
      .subscribe({
        error: (error: UserFriendlyError) => this.notifications.error(error),
      });
  }

  /** Click event for the enhanced export button. */
  onClickEnhancedExport(): void {
    this.logger.debug('Enhanced export button clicked.');
    const exportDialogData: EnhancedExportDialogData = {
      databaseId: this.databasesQuery.activeId,
      exportDocuments: {
        [this.archivesQuery.activeId]: this.searchResults.map((row) => row.id),
      },
    };
    const dialog = this.dialog.open(EnhancedExportDialogComponent, {
      data: exportDialogData,
    });
    dialog.afterClosed().subscribe(() => {
      this.logger.debug('Enhanced export dialog closed.');
    });
  }

  /** Click event for the export button. */
  onClickExport(): void {
    this.logger.debug('Export button clicked.');

    for (const searchResult of this.searchResults) {
      const downloadFileUrl = this.documentProvider.getArchiveDownloadUrl(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        searchResult.id,
        this.auth.user.token,
        searchResult.secureId
      );

      this.windowService
        .fetchAndDownloadUrl(
          downloadFileUrl,
          `ExportedFile${searchResult.fileType}`
        )
        .subscribe();
    }
  }

  /** Click event for the insert from search button. */
  onClickInsertFromSearch(): void {
    this.logger.debug('Insert from search clicked.');
    const dialog = this.dialog.open(InsertFromSearchDialogComponent, {
      data: this.searchResults[0],
      maxWidth: this.useCompact ? '95vw' : '80vw',
    });
    dialog.afterClosed().subscribe((result: InsertFromSearchDialogResult) => {
      this.logger.debug('Insert from search dialog closed.', result);
      this.onInsertSearchDialogClosed(result);
    });
  }

  /**
   * Click event for the launch document button.
   */
  onClickLaunchDocument(): void {
    this.logger.debug('Launch document button clicked.', this.searchResults);
    this.searchResults.map((row) => {
      this.gse.launchApi
        .launchDocument(
          this.databasesQuery.activeId,
          this.archivesQuery.activeId,
          row.id,
          this.searchesQuery.activeId,
          0
        )
        .subscribe({
          next: (launchId) => {
            // We need to poll the gse api for the status every second until it is 'closed'.
            const launchStatusInterval = interval(1000)
              .pipe(
                untilDestroyed(this), // Ensure this interval is unsubscribed when this component is destroyed.
                switchMap(() =>
                  this.gse.launchApi.getStatus(launchId).pipe(
                    tap((status) => {
                      if (status === 'closed') {
                        launchStatusInterval.unsubscribe();
                        this.refreshSearch.emit({
                          forceReload: true,
                        });
                      }
                    })
                  )
                )
              )
              .subscribe();
          },
          error: (error: UserFriendlyError) => this.notifications.error(error),
        });
    });
  }

  /** Click event for the merge button. */
  onClickMerge(): void {
    this.logger.debug('Merge button clicked.', this.searchResults);
    const mergeDocuments = this.searchResults.map(
      (row) => new ArchiveMergeDocument(this.archivesQuery.activeId, row.id)
    );
    const target: MergeTarget = {
      appendType: AppendType.toEnd,
      id: this.archivesQuery.activeId,
      mergeTargetType: MergeTargetType.archive,
    };
    this.progressDialogService.openProgressDialog('MERGING_DOCUMENTS');
    this.archiveProvider
      .mergeDocuments(
        this.databasesQuery.activeId,
        target,
        mergeDocuments[0],
        mergeDocuments.slice(1)
      )
      .pipe(finalize(() => this.progressDialogService.closeProgressDialog()))
      .subscribe({
        next: (mergeResult) => {
          this.logger.debug('Merge created a new document.', mergeResult);
          this.notifications.success('MERGE_SUCCESSFUL');
          this.refreshSearch.emit();
        },
        error: (error: UserFriendlyError) => {
          this.notifications.error(error);
        },
      });
  }

  /** Click event for the move button. */
  onClickMove(): void {
    this.logger.debug('Move button clicked.');
    this.transferDocument(true);
  }

  /**
   * Click event for the open documents menu button.
   *
   * @param forceExternalViewer If the documents should be opened only with the external viewer.
   */
  onClickOpenDocuments(forceExternalViewer = false): void {
    this.logger.debug('Open documents button clicked.');
    this.openSelectedDocuments.emit({
      forceExternalViewer,
      searchResults: this.searchResults,
    });
  }

  /**
   * Click event for the print documents button.
   *
   * @param annotations Include annotations.
   */
  onClickPrintDocuments(annotations: boolean): void {
    this.logger.debug('Print documents button clicked.');
    const data: ConfirmationDialogData = {
      cancelActionText: 'CANCEL',
      confirmActionText: 'PRINT',
      contents: this.translate.translate('CONFIRM_PRINT', {
        documentNumber: this.searchResults.length,
      }),
      title: 'PRINT_DOCUMENTS',
    };

    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      data,
    });
    dialog.afterClosed().subscribe((confirmed) => {
      this.logger.debug('Confirm dialog closed.', confirmed);
      if (!confirmed) return;

      this.printDocuments(annotations);
    });
  }

  /** Handler for the send to Quickbooks click event. */
  onClickSendToQuickbooks(): void {
    this.logger.debug('Send to Quickbooks clicked.');
    this.notifications.info('PUSHING_DATA_TO_QUICKBOOKS');
    const pushObservables: Observable<void>[] = [];
    let erroredDocumentCount = 0;
    for (const searchResult of this.searchResults) {
      const quickbooksPush$ = this.gse.quickbooksApi
        .startPush(
          this.databasesQuery.activeId,
          this.archivesQuery.activeId,
          searchResult.id
        )
        .pipe(
          catchError((error: UserFriendlyError) => {
            this.logger.error(
              `Failed to push document with id ${searchResult.id} to Quickbooks`,
              error
            );
            erroredDocumentCount++;
            return EMPTY;
          })
        );
      pushObservables.push(quickbooksPush$);
    }

    concat(...pushObservables)
      .pipe(
        finalize(() => {
          const jobFinishedTranslation = 'QUICKBOOKS_PUSH_JOB_COMPLETED';
          if (erroredDocumentCount > 0) {
            this.notifications.info(jobFinishedTranslation);
            const error: UserFriendlyError = {
              description: '',
              error: undefined,
              i18n: 'QUICKBOOKS_PUSH_ERROR',
              i18nParameters: {
                erroredDocumentCount: `${erroredDocumentCount}`,
              },
            };
            this.notifications.error(error);
          } else {
            this.notifications.success(jobFinishedTranslation);
          }
        })
      )
      .subscribe();
  }

  /**
   * Opens the menu.
   *
   * @param mouseEvent Mouse event.
   * @param searchResults Row nodes.
   */
  openMenu(mouseEvent: MouseEvent, searchResults: SearchResults): void {
    // Set the search results to act on
    if (searchResults) this.searchResults = searchResults;
    this.open(mouseEvent);
  }

  private deleteSelectedDocuments(): void {
    const deletes$: Observable<void>[] = [];
    let erroredDeletesCount = 0;
    for (const row of this.searchResults) {
      const delete$ = this.archiveProvider
        .deleteDocument(
          this.databasesQuery.activeId,
          this.archivesQuery.activeId,
          row.id,
          row.secureId
        )
        .pipe(
          catchError((error: UserFriendlyError) => {
            this.logger.error(
              `Failed to delete document with id ${row.id}`,
              error
            );
            erroredDeletesCount++;
            return EMPTY;
          })
        );
      deletes$.push(delete$);
    }

    combineLatest(deletes$)
      .pipe(finalize(() => this.onDeleteDocumentsFinalize(erroredDeletesCount)))
      .subscribe();
  }

  private onDeleteDocumentsFinalize(erroredDeletesCount: number): void {
    const translatedMessage = this.translate.translate(
      'DOCUMENT_DELETE_FINISHED'
    );
    if (erroredDeletesCount === 0) {
      this.notifications.success(translatedMessage);
    } else {
      this.notifications.info(translatedMessage);
      const error: UserFriendlyError = {
        description: '',
        error: undefined,
        i18n: 'DOCUMENTS_FAILED_TO_DELETE_ERROR',
        i18nParameters: {
          errorCount: `${erroredDeletesCount}`,
        },
      };
      this.notifications.error(error);
    }
    this.refreshSearch.emit({
      ids: this.searchResults.map((searchResult) => searchResult.id),
    });
  }

  private onInsertDialogClosed(result: InsertFromInboxDialogResult): void {
    if (!result) {
      this.logger.debug('Insert from inbox dialog cancelled.');
      return;
    }

    this.logger.debug('Insert from inbox will occur.', result);
    const target: MergeTarget = {
      appendType: result.appendType,
      id: this.archivesQuery.activeId,
      mergeTargetType: MergeTargetType.archive,
    };
    const baseDocument = new ArchiveMergeDocument(
      this.archivesQuery.activeId,
      this.searchResults[0].id
    );
    const mergeDocuments = result.files.map(
      (file) => new InboxMergeDocument(result.inboxId, file)
    );
    this.progressDialogService.openProgressDialog('APPENDING_DOCUMENTS');
    this.archiveProvider
      .mergeDocuments(
        this.databasesQuery.activeId,
        target,
        baseDocument,
        mergeDocuments
      )
      .pipe(finalize(() => this.progressDialogService.closeProgressDialog()))
      .subscribe({
        next: (mergeResult) =>
          this.onInsertFromInboxSuccess(mergeResult, result),
        error: (error: UserFriendlyError) => {
          this.notifications.error(error);
        },
      });
  }

  private onInsertFromInboxSuccess(
    mergeResult: MergeResult,
    dialogResult: InsertFromInboxDialogResult
  ): void {
    this.logger.debug('Insert from inbox completed.', mergeResult);
    if (dialogResult.deleteFiles) {
      const deleteJobs$ = dialogResult.files.map((file) =>
        this.inboxProvider.deleteDocument(dialogResult.inboxId, file).pipe(
          catchError((error: UserFriendlyError) => {
            this.logger.error(error);
            this.notifications.error({
              ...error,
              i18n: 'ERROR_FAILED_TO_DELETE_FILE_FROM_INBOX',
              i18nParameters: { filename: file },
            });
            return EMPTY;
          })
        )
      );
      combineLatest(deleteJobs$)
        .pipe(
          finalize(() => {
            this.notifications.success('APPEND_SUCCESSFUL');
            this.refreshSearch.emit();
          })
        )
        .subscribe();
    } else {
      this.notifications.success('APPEND_SUCCESSFUL');
      this.refreshSearch.emit({ forceReload: true });
    }
  }

  private onInsertSearchDialogClosed(
    result: InsertFromSearchDialogResult
  ): void {
    if (!result) {
      this.logger.debug('Insert from search dialog cancelled.');
      return;
    }
    const target: MergeTarget = {
      appendType: result.appendType,
      id: this.archivesQuery.activeId,
      mergeTargetType: MergeTargetType.archive,
    };
    const baseDocument = new ArchiveMergeDocument(
      this.archivesQuery.activeId,
      this.searchResults[0].id
    );
    const mergeDocuments: ArchiveMergeDocument[] = [];
    const deleteDocuments$: Observable<void>[] = [];
    for (const [documentIdString, secureId] of Object.entries(
      result.documentIdSecureIdMap
    )) {
      const documentId = Number(documentIdString);
      mergeDocuments.push(
        new ArchiveMergeDocument(result.archiveId, documentId)
      );
      if (result.deleteFiles) {
        deleteDocuments$.push(
          this.archiveProvider
            .deleteDocument(
              this.databasesQuery.activeId,
              result.archiveId,
              documentId,
              secureId
            )
            .pipe(
              catchError((error: UserFriendlyError) => {
                this.notifications.error({
                  ...error,
                  i18n: 'ERROR_FAILED_TO_DELETE_FILE_FROM_SEARCH',
                  i18nParameters: { documentId: documentIdString },
                });
                return EMPTY;
              })
            )
        );
      }
    }

    this.archiveProvider
      .mergeDocuments(
        this.databasesQuery.activeId,
        target,
        baseDocument,
        mergeDocuments
      )
      .subscribe({
        next: (mergeResult) => {
          this.logger.debug('Insert from search merge complete.', mergeResult);
          if (!result.keepBaseFile) {
            // Add a delete for the base document.
            deleteDocuments$.push(
              this.archiveProvider.deleteDocument(
                this.databasesQuery.activeId,
                baseDocument.archiveId,
                baseDocument.docId,
                this.searchResults[0].secureId
              )
            );
          }
          //TODO refresh emit to handle "reload" performing a redirect to the new document when we are in document view.
          if (deleteDocuments$.length > 0) {
            combineLatest(deleteDocuments$)
              .pipe(
                finalize(() => {
                  this.notifications.success('APPEND_SUCCESSFUL');
                  this.refreshSearch.emit();
                })
              )
              .subscribe();
          } else {
            this.notifications.success('APPEND_SUCCESSFUL');
            this.refreshSearch.emit();
          }
        },
        error: (error: UserFriendlyError) => {
          this.notifications.error({
            ...error,
            i18n: 'ERROR_UNABLE_TO_INSERT_FROM_SEARCH',
          });
        },
      });
  }

  /**
   * Merges selected documents and sends to browser as one document for printing.
   *
   * @param annotations Include annotations.
   */
  private printDocuments(annotations: boolean) {
    this.progressDialogService.openProgressDialog(
      'PREPARING_DOCUMENTS_FOR_PRINT'
    );
    let fileURL = '';
    const archiveDocuments: { [key: number]: number[] } = {};
    archiveDocuments[this.archivesQuery.activeId] = this.searchResults.map(
      (row) => row.id
    );
    const batchPrint: BatchPrint = {
      annotations,
      archiveDocuments,
    };
    this.printProvider
      .printDocuments(this.databasesQuery.activeId, batchPrint)
      .subscribe({
        next: (result: any) => {
          fileURL = URL.createObjectURL(
            new Blob([result as BlobPart], { type: 'application/pdf' })
          );
          this.logger.debug('Bulk print successful:', fileURL);
          const printWindow = window.open(fileURL);
          // Attempt to force the new tab/window to print the document.
          printWindow?.print();
        },
        error: (error: UserFriendlyError) => {
          this.notifications.error(error);
        },
        complete: () => {
          URL.revokeObjectURL(fileURL);
          this.progressDialogService.closeProgressDialog();
        },
      });
  }

  /**
   * Determines if the specified Advanced Link be displayed in this archive context.
   *
   * @param advancedLink AdvancedLink.
   * @returns Boolean.
   */
  private shouldShowAdvancedLink(advancedLink: AdvancedLink): boolean {
    return (
      (advancedLink.archiveId === this.archivesQuery.activeId ||
        advancedLink.archiveId === 0) &&
      advancedLink.fieldId === 0
    );
  }

  /**
   * Transfer a document to a new location.
   *
   * @param move Determines if the document should be moved. Otherwise it will be copied.
   */
  private transferDocument(move: boolean): void {
    this.documentTransferService
      .startDocumentTransferFromArchive(
        this.databasesQuery.activeId,
        this.archivesQuery.activeId,
        this.searchResults,
        move
      )
      .subscribe({
        complete: () => {
          this.notifications.info('Document transfer job finished.');
          if (move) {
            this.refreshSearch.emit({
              ids: this.searchResults.map((row) => row.id),
            });
          } else {
            this.refreshSearch.emit();
          }
        },
        error: (error: UserFriendlyError) => {
          if (error.i18n !== 'OPERATION_CANCELLED_MSG')
            this.notifications.error(error);
        },
      });
  }
}
