import { Clipboard } from '@angular/cdk/clipboard';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogReference,
} from '@angular/material/dialog';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NGXLogger } from 'ngx-logger';
import { Observable, switchMap } from 'rxjs';

import { Square9ApiConfig } from 'lib/square9api/src/public-api';
import { ArchiveSession, ShareDocumentProvider } from 'models';
import { SQUARE9_API_CONFIG } from 'square9api';
import { AppConfigQuery } from 'src/app/modules/app-config';
import { ViewerService } from 'src/app/services/viewer.service';

import moment, { Moment } from 'moment';
import { SHARE_DOCUMENT_PROVIDER } from '../../common/tokens';

interface ExpirationFormGroup {
  expiration: FormControl<Moment>;
}

/**
 * Share Promp Component.
 */
@Component({
  selector: 'app-share-prompt',
  templateUrl: './share-prompt.component.html',
  styleUrls: ['./share-prompt.component.scss'],
})
export class SharePromptComponent implements OnInit {
  /** Subscribe to get dbId and archive Id. */
  archiveRouteParams$ = this.routerQuery.selectParams(['dbId', 'archiveId']);
  /** Expiration From Group. */
  expirationFromGroup: FormGroup<ExpirationFormGroup>;
  /** License token. */
  token = '';
  /** Url Form Group. */
  urlFormGroup: UntypedFormGroup;
  private basePath: string;
  constructor(
    public dialogReference: MatDialogReference<SharePromptComponent>,
    private appConfigQuery: AppConfigQuery,
    private clipboard: Clipboard,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private routerQuery: RouterQuery,
    @Inject(SHARE_DOCUMENT_PROVIDER)
    private shareDocumentProvider: ShareDocumentProvider,
    private viewerService: ViewerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(SQUARE9_API_CONFIG) private config: Square9ApiConfig
  ) {
    this.config.apiUrl$.subscribe((apiUrl) => (this.basePath = `${apiUrl}`));
  }

  /**
   * Generate share link.
   *
   */
  clickCreateUrl(): void {
    this.logger.debug('Click Create Url clicked.');
    const expiration = this.expirationFromGroup.controls.expiration.value
      .utc()
      .toISOString();
    this.shareDocumentProvider
      .getToken(
        true,
        this.data.databaseId,
        this.data.archiveId,
        this.data.documentId,
        expiration
      )
      .pipe(
        switchMap((license) => {
          this.token = license.Token;
          return this.shareDocumentProvider
            .getSecureId(
              this.data.databaseId,
              this.data.archiveId,
              this.data.documentId,
              license.Token
            )
            .pipe(
              switchMap((secureId) => {
                return this.populateURL(license.Token, secureId);
              })
            );
        })
      )
      .subscribe();
  }
  /**
   * Closes the dialog window.
   *
   */
  closeDialog() {
    this.dialogReference.close();
  }
  /**
   * Copy input text to clipboard.
   *
   * @param url - Url to be copied to the clipboard.
   */
  copyUrl(url: string): void {
    this.clipboard.copy(url);
  }
  /**
   * Deletes token from the license table.
   *
   */
  deleteToken(): void {
    this.logger.debug('Deleting token: ' + this.token);
    this.shareDocumentProvider.deleteToken(this.token, false, true).subscribe();
  }

  /**
   * Get login form controls.
   *
   * @returns Login form controls.
   */
  get f() {
    return this.expirationFromGroup.controls;
  }

  /**
   * Get the expiration error message.
   *
   * @returns A translation key for the expiration error message or an empty string if there are no handled errors.
   */
  get expirationFormControlError(): string {
    if (this.f.expiration.getError('required')) {
      return `EXPIRATION_REQUIRED`;
    }

    return '';
  }

  /**
   * Get the current time.
   *
   * @returns Time.
   */
  getTimePlusDays = () => {
    const defaultExpirationMoment = moment().add(
      this.appConfigQuery.defaultShareDocumentExpirationDays,
      'days'
    );

    return defaultExpirationMoment;
  };

  ngOnInit(): void {
    this.expirationFromGroup = this.formBuilder.group<ExpirationFormGroup>({
      expiration: new FormControl<Moment>(this.getTimePlusDays(), {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
    this.urlFormGroup = this.formBuilder.group({
      url: '',
    });
  }
  /**
   * Populates the URL input with the correct URL.
   *
   * @param token - User token.
   * @param secureId - Secure Id.
   * @returns - Observable.
   */
  populateURL(token: string, secureId: string): Observable<any> {
    const session: ArchiveSession = {
      database: this.data.databaseId,
      searchId: 0,
      documents: [
        {
          archiveId: this.data.archiveId,
          id: this.data.documentId,
          hash: secureId,
        },
      ],
    };
    return this.viewerService.createSession(session).pipe(
      switchMap((sessionId) => {
        const link = `${this.appConfigQuery.appConfig.viewerUrl}/viewer/#/globalSearch?session=${sessionId}&token=${token}&logoutOnClose=true`;
        this.urlFormGroup.patchValue({
          url: link,
        });
        this.releaseToken();
        return sessionId;
      })
    );
  }
  /**
   * Releases token making it inactive.
   *
   */
  releaseToken(): void {
    this.logger.debug('Releasing token: ' + this.token);
    this.shareDocumentProvider.deleteToken(this.token).subscribe();
  }
  /**
   * Step Event when clicking header navigation.
   *
   * @param event - Event.
   */
  stepEvent(event: StepperSelectionEvent): void {
    if (event.selectedIndex === 0) {
      this.logger.debug(event.selectedIndex);
      this.deleteToken();
    } else if (event.selectedIndex === 1) {
      this.clickCreateUrl();
    }
  }
}
